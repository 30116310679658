import Aside from "../components/MyPage/Aside";
import {useEffect, useState} from "react";
import {Slide} from "react-awesome-reveal";
import MyParcels from "../components/MyPage/MyParcels";
import ArchivedParcels from "../components/MyPage/ArchivedParcels";
import Delivery from '../components/MyPage/Delivery';
import PreliminaryDeclared from "../components/MyPage/PreliminaryDeclared";
import Profile from "../components/MyPage/Profile";
import {Route} from "react-router-dom";
import Addresses from "../components/MyPage/Addresses";
import {useSelector} from "react-redux";
import language from "../store/language";
import MenuIcon from "@mui/icons-material/Menu";
import Messages from "../components/MyPage/Messages";
import Payments from "../components/MyPage/Payments";
const MyPage = ({setIsMyPage, isMyPage}) => {
    const screenWidth = window.innerWidth;
    const lang = useSelector((state) => state.user.language);
    const conversation_id = useSelector((state) => state.user.conversation_id);
    const [currentShownSection, setCurrentShownSection] = useState("all");
    const [selectedParcels, setSelectedParcels] = useState([]);
    const [currentContentTitle, setCurrentContentTitle] = useState("My Parcels");
    const [hasPayedParcel, setHasPayedParcel] = useState([]);
    const [user, setUser] = useState({});
    const userType = useSelector((state) => state.user.userType);
    const [selectedDelivery, setSelectedDelivery] = useState([]);
    const [payedSelectedDelivery, setPayedSelectedDelivery] = useState([]);

    useEffect(() => {
        setIsMyPage(true);
    }, []);

    return(
        <div className={"my-page-container"}>
            {!isMyPage && <MenuIcon onClick={() => setIsMyPage(true)} style={{top: "75px", left: "5px", position: "absolute", fontSize: screenWidth < 1024 ? "30px" : 40, cursor: "pointer"}}/>}
            {isMyPage && <div className={"my-page-aside-container"}>
                <Slide direction={"left"}><Aside userType={userType} isMyPage={isMyPage} setIsMyPage={setIsMyPage} language={language} lang={lang} setData={setUser}
                                                 currentContent={currentContentTitle}
                                                 setCurrentContentTitle={setCurrentContentTitle}
                                                 selectedParcels={selectedParcels}
                                                 hasPayedParcel={hasPayedParcel}
                                                 selectedDelivery={selectedDelivery}
                                                 payedSelectedDelivery={payedSelectedDelivery}
                                                 conversation_id={conversation_id}/></Slide>
            </div>}
            <div className={`my-page-content ${!isMyPage ? "fill-space" : ""}`}>
                <Route path={"/my-page/addresses"}>
                    <h3 style={{marginBottom: "30px"}}>{language[lang].myPage.aside.addresses}</h3>
                    <Addresses user={user} setCurrentContentTitle={setCurrentContentTitle}/>
                </Route>
                <Route path={"/my-page/my-parcels"}>
                <h3 style={{marginBottom: "30px"}}>{language[lang].myPage.aside.parcels}</h3>
                    <MyParcels
                        lang={lang}
                        language={language}
                        selectedParcels={selectedParcels}
                        setSelectedParcels={setSelectedParcels}
                        active={currentShownSection}
                        setActive={setCurrentShownSection}
                        hasPayedParcel={hasPayedParcel}
                        setHasPayedParcel={setHasPayedParcel}
                        setCurrentContentTitle={setCurrentContentTitle}
                    />
                </Route>
                <Route path={"/my-page/archived-parcels"}>
                    <h3 style={{marginBottom: "30px"}}>{language[lang].myPage.aside.archived}</h3>
                    <ArchivedParcels setCurrentContentTitle={setCurrentContentTitle} lang={lang} language={language}/>
                </Route>
                <Route path={"/my-page/delivery"}>
                    <h3 style={{marginBottom: "30px"}}>{language[lang].myPage.aside.delivery}</h3>
                    <Delivery setCurrentContentTitle={setCurrentContentTitle} lang={lang} language={language} setSelectedDelivery={setSelectedDelivery} selectedDelivery={selectedDelivery} payedSelectedDelivery={payedSelectedDelivery} setPayedSelectedDelivery={setPayedSelectedDelivery} />
                </Route>
                <Route path={"/my-page/preliminary-declared"}>
                    <h3 style={{marginBottom: "30px"}}>{language[lang].myPage.aside.preDeclared}</h3>
                    <PreliminaryDeclared setCurrentContentTitle={setCurrentContentTitle} lang={lang} language={language}/>
                </Route>
                <Route path={"/my-page/inbox"}>
                    <h5 style={{marginBottom: "20px"}}>{language[lang].myPage.aside.inbox}</h5>
                    <Messages setCurrentContentTitle={setCurrentContentTitle} conversation_id={conversation_id} lang={lang} language={language}/>
                </Route>
                <Route path={"/my-page/profile"}>
                    <h3 style={{marginBottom: "30px"}}>{language[lang].myPage.aside.profile}</h3>
                    <Profile setCurrentContentTitle={setCurrentContentTitle} lang={lang} language={language}/>
                </Route>
                <Route path={"/my-page/payments-history"}>
                    <h3 style={{marginBottom: "30px"}}>{language[lang].myPage.aside.payments}</h3>
                    <Payments setCurrentContentTitle={setCurrentContentTitle} lang={lang} language={language}/>
                </Route>
            </div>
        </div>
    )
}

export default MyPage