import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useDispatch } from 'react-redux';
import { notificationActions } from '../../store/notification';
import { useEffect } from 'react';

const Addresses = ({user, setCurrentContentTitle}) => {
    const dispatch = useDispatch();
    useEffect(() => setCurrentContentTitle("Addresses"), []);
    function copyText(text) {
        // Use the Clipboard API to copy the text
        navigator.clipboard.writeText(text).then(function() {
            dispatch(notificationActions.setNotification({show: true, message: "Text copied to clipboard", type: "success"}));
            setTimeout(() => {
                dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
            }, 4000)
        }).catch(function(error) {
            console.error('Error copying text: ', error);
        });
    }

    return(
        <div className={"addresses-container"}>
            <div className={"addresses-box"}>
                <div className={"row"}>
                    <div className={"col-md-6 address-column"}>
                        <ul>
                            <li>
                                <p className={"address-label"}>First name:</p>
                                <div>
                                    <p className={"address-value"}>{user.name_en}</p>
                                    <ContentCopyIcon onClick={() => copyText(user.name_en)} style={{cursor: "pointer"}}/>
                                </div>
                            </li>
                            <li>
                                <p className={"address-label"}>Last name:</p>
                                <div>
                                    <p className={"address-value"}>{user.surname_en}</p>
                                    <ContentCopyIcon onClick={() => copyText(user.surname_en)} style={{cursor: "pointer"}}/>
                                </div>
                            </li>
                            <li>
                                <p className={"address-label"}>Address 1:</p>
                                <div>
                                    <p className={"address-value"}>广州市荔湾区广雅路76-78号 首层A2仓111V/{user.user_id}库房</p>
                                    <ContentCopyIcon onClick={() => copyText(`广州市荔湾区广雅路76-78号 首层A2仓111V/${user.user_id}库房`)} style={{cursor: "pointer"}}/>
                                </div>
                            </li>
                            <li>
                                <p className={"address-label"}>Address 2:</p>
                                <div>
                                    <p className={"address-value"}>{user.user_id}</p>
                                    <ContentCopyIcon onClick={() => copyText(user.user_id)} style={{cursor: "pointer"}}/>
                                </div>
                            </li>
                            <li>
                                <p className={"address-label"}>Town / City:</p>
                                <div>
                                    <p className={"address-value"}>Guangzhou City</p>
                                    <ContentCopyIcon onClick={() => copyText("Guangzhou City")} style={{cursor: "pointer"}}/>
                                </div>
                            </li>
                            <li>
                                <p className={"address-label"}>District:</p>
                                <div>
                                    <p className={"address-value"}>Liwan District</p>
                                    <ContentCopyIcon onClick={() => copyText("Liwan District")} style={{cursor: "pointer"}}/>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className={"col-md-6 address-column"}>
                        <ul>
                            <li>
                                <p className={"address-label"}>State:</p>
                                <div>
                                    <p className={"address-value"}>Guangdong</p>
                                    <ContentCopyIcon onClick={() => copyText("Guangdong")} style={{cursor: "pointer"}}/>
                                </div>
                            </li>
                            <li>
                                <p className={"address-label"}>Country:</p>
                                <div>
                                    <p className={"address-value"}>China</p>
                                    <ContentCopyIcon onClick={() => copyText("China")} style={{cursor: "pointer"}}/>
                                </div>
                            </li>
                            <li>
                                <p className={"address-label"}>Post Code (Zip):</p>
                                <div>
                                    <p className={"address-value"}>510160</p>
                                    <ContentCopyIcon onClick={() => copyText("510160")} style={{cursor: "pointer"}}/>
                                </div>
                            </li>
                            <li>
                                <p className={"address-label"}>Phone Number 1:</p>
                                <div>
                                    <p className={"address-value"}>18588552668</p>
                                    <ContentCopyIcon onClick={() => copyText("18588552668")} style={{cursor: "pointer"}}/>
                                </div>
                            </li>
                            <li>
                                <p className={"address-label"}>Phone Number 2:</p>
                                <div>
                                    <p className={"address-value"}>13822281207</p>
                                    <ContentCopyIcon onClick={() => copyText("13822281207")} style={{cursor: "pointer"}}/>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Addresses