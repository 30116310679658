import { createSlice } from "@reduxjs/toolkit";

const initialNotification = {
    show: false,
    message: "",
    type: ""
}

const notification = createSlice({
    name: 'notification',
    initialState: initialNotification,
    reducers: {
        setNotification(state, action){
            state.show = action.payload.show;
            state.message = action.payload.message;
            state.type = action.payload.type;
        }
    }
});

export const notificationActions = notification.actions;

export default notification.reducer;