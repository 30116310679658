import {Modal} from "react-bootstrap";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import core from '../../../core';

const ViewUsers = ({data, show, setShow}) => {
    const registrationDate = core.formatDate(data.registration_date);

    return (
        <Modal show={show} onHide={() => setShow(false)} centered size={"lg"}>
            <Modal.Header><h4>View user: {data.user_id}</h4></Modal.Header>
            <Modal.Body>
                <div className={"view-parcel-details-modal"}>
                    <ul className={"parcels-details-list"}>
                        <li><p>ოთახის ნომერი: </p><p>{data.user_id}</p></li>
                        <li><p>ელ. ფოსტა: </p><p>{data.email}</p></li>
                        <li><p>მომხ. სახელი: </p><p>{data.username}</p></li>
                        <li><p>დავალიანება: </p><p>{data.debt}</p></li>
                        <li><p>რეგისტრაციის თარიღი: </p><p>{registrationDate}</p></li>
                        <li><p>მიწოდების დავალიანება: </p><p>{data.delivery_debt}</p></li>
                        <li><p>რეგისტრაციის ტიპი: </p><p>{data.user_type}</p></li>
                        {data.user_type === "legal" && <>
                            <li><p>კომპანიის სახელი: </p><p>{data.company_name_geo}</p></li>
                            <li><p>კომპანიის სახელი (ინგ.): </p><p>{data.company_name_en}</p>
                            </li>
                            <li><p>საიდენთიფიკაციო: </p><p>{data.identification_code}</p></li>
                            <li><p>წარმომადგენლის სახელი: </p><p>{data.name_of_representative}</p></li>
                            <li><p>წარმომადგენლის გვარი: </p><p>{data.surname_of_representative}</p></li>
                            <li><p>წარმომადგენლის პ.ნ.: </p><p>{data.id_number_of_representative}</p></li>
                            <li><p>წარმომადგენლის პოზიცია: </p><p>{data.position_of_representative}</p></li>
                        </>
                        }
                        {data.user_type === "physical" && <li><p>რეზიდენტი: </p><p>{data.is_resident === 0 ? "არა" : "კი"}</p></li>}
                        <li><p>ტელ. ნომერი: </p><p>{data.phone}</p></li>
                        {data.user_type === "physical" && <>
                            <li><p>სახელი: </p><p>{data.name_geo}</p></li>
                            <li><p>გვარი: </p><p>{data.surname_geo}</p>
                            </li>
                        </>
                        }
                        <li><p>მისამართი: </p><p>{data.address}</p></li>
                        <li><p>ქალაქი: </p><p>{data.city}</p></li>
                        {data.user_type === "physical" && <li><p>პირადი ნომერი: </p><p>{data.number_of_id}</p></li>}
                    </ul>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ViewUsers