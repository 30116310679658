import {useRef, useEffect, useState} from "react";
import axios from "axios";
import {Button} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {userAction} from "../../store/user";
import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import MessageCard from "./MessageCard";
import FilePresentIcon from '@mui/icons-material/FilePresent';
import CancelIcon from '@mui/icons-material/Cancel';
import language from "../../store/language";
import { notificationActions } from "../../store/notification";

const Messages = ({conversation_id, lang, setCurrentContentTitle}) => {
    const [messages, setMessages] = useState([])
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(15);
    const [messageText, setMessageText] = useState("");
    const dispatch = useDispatch();
    const [file_url, set_file_url] = useState(null);
    const [readMessageIndex,setReadMessageIndex] = useState(0);
    const scrollableDivRef = useRef(null);

    const getMessages = () => {
        if(conversation_id !== ""){
            axios(`/api/messages/${conversation_id}?limit=${limit}&page=1`)
                .then((res) => {
                    const data = res.data.data.reverse();
                    setMessages((prev) => ([...data]));
                    if (scrollableDivRef.current) {
                        scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight;
                    }
                })
                .catch((err) => {
                    dispatch(notificationActions.setNotification({show: true, message: err.response.data.message[lang], type: "error"}));
                    setTimeout(() => {
                        dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                    }, 4000)
                })
        }
    }
    useEffect(getMessages, [limit, conversation_id]);
    useEffect(() => setCurrentContentTitle("Messages"), []);

    const handlers = {
        createConversation(){
            axios.post('/api/chat')
                .then((res) => {
                    const conversation_id = res.data.conversation_id;
                    dispatch(userAction.setConversationId(conversation_id));
                })
                .catch((err) => {
                    dispatch(notificationActions.setNotification({show: true, message: err.response.data.message[lang], type: "error"}));
                    setTimeout(() => {
                        dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                    }, 4000)
                })
        },
        sendMessage(e){
            e.preventDefault();
            const formData = new FormData();
            formData.append("conversation_id", conversation_id);
            formData.append("message", messageText);
            formData.append("file_url", file_url);
            set_file_url(null);

            axios.post('/api/messages', formData)
                .then(() => {
                    getMessages();
                    setMessageText("");
                    if (scrollableDivRef.current) {
                        scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight;
                    }
                })
                .catch((err) => {
                    dispatch(notificationActions.setNotification({show: true, message: err.response.data.message[lang], type: "error"}));
                    setTimeout(() => {
                        dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                    }, 4000)
                })
        }
    }

    return(
        <div className={"messages-container"}>
            {
                conversation_id === "" ? (
                    <div className={"no-conversation-created"}>
                        <Button onClick={handlers.createConversation} variant={"outlined"}>{language[lang].myPage.myParcels.createConversation}</Button>
                    </div>
                ) : (
                    <div className={"conversation-box-container"}>
                        <div>
                            <div ref={scrollableDivRef} className={"chat-box-messaging-container"}>
                                {
                                    messages.length === 0 ? (
                                        <div className={"empty-conversation"}>
                                            <p>{language[lang].myPage.myParcels.emptyChat}</p>
                                        </div>
                                    ) : (
                                        <div>
                                            {
                                                messages.map((m) => <MessageCard setReadMessageIndex={setReadMessageIndex} readMessageIndex={readMessageIndex} key={m.id} data={m}/>)
                                            }
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <form onSubmit={handlers.sendMessage}>
                            <div className={"message-content-input"}>
                            <textarea
                                style={{bottom: 0}}
                                className={"text-area-form"}
                                placeholder={language[lang].myPage.myParcels.messagePlaceholder}
                                onChange={(e) => setMessageText(e.target.value)}
                                value={messageText}
                                required={file_url == null}
                            />
                                <div className={"send-message-action-buttons"}>
                                    <Button variant={"contained"} type={"submit"}><SendIcon style={{color: "white"}} /></Button>
                                    <input id={"attachment"} hidden type={"file"} onChange={(e) => {
                                        set_file_url(e.target.files[0]);
                                    }}/>
                                    <div className={"file-attachment-on-inbox"}>
                                        <label title={language[lang].myPage.myParcels.attachFile} style={{cursor: "pointer"}} htmlFor={"attachment"}><AttachFileIcon style={{fontSize: "32px"}}/></label>
                                        {
                                            file_url !== null && (
                                                <div>
                                                    <FilePresentIcon style={{fontSize: "32px"}}/>
                                                    <CancelIcon title={language[lang].myPage.myParcels.removeSelected} onClick={() => set_file_url(null)} style={{
                                                        color: "red",
                                                        fontSize: "20px",
                                                        position: "absolute",
                                                        marginLeft: "-15px",
                                                        marginTop: "-8px",
                                                        cursor: "pointer"
                                                    }}/>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                )
            }
        </div>
    )
}

export default Messages