import Banner from "../components/MainPage/Banner";
import Services from "../components/MainPage/Services";
import Flights from "../components/MainPage/Flights";
import {useEffect} from "react";

const MainPage = ({setIsMyPage}) => {
    useEffect(() => {
        setIsMyPage(false)
    }, []);

    return(
        <>
            <Banner/>
            <Services/>
            <Flights/>
        </>
    )
}

export default MainPage