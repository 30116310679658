import {useEffect, useState} from "react";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import OutsideClickHandler from "react-outside-click-handler";
import {Pagination, Stack} from "@mui/material";
import ViewParcel from "./ViewParcel";
import language from "../../store/language";
import {Table} from "react-bootstrap";

const ArchivedParcels = ({lang, language, setCurrentContentTitle}) => {
    const [keyword, setKeyword] = useState("");
    const [optionsDropdown, setOptionsDropdown] = useState({show: false, id: 0});
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const [searchFieldValue, setSearchFieldValue] = useState("");
    const [showViewModal, setShowViewModal] = useState(false);
    const [active, setActive] = useState({});
    useEffect(() => setCurrentContentTitle("Archived Parcels"), []);

    useEffect(() => {
        axios(`/api/parcels?status=taken&keyword=${keyword}&page=${page}&limit=${limit}`)
            .then((res) => {
                setData(res.data.data);
                setTotal(res.data.total);
            })
            .catch((err) => {

            })
    }, [keyword, limit, page]);

    return <div className={"my-parcels-container"}>
        <div className={"parcels-data-table"}>
            <div className={"data-table-header"}>
                <div className={"search-field-container"}>
                    <input type={"text"}
                           onKeyUp={(e) => {
                               if (e.keyCode === 13) {
                                   setKeyword(searchFieldValue);
                               }
                           }}
                           value={searchFieldValue}
                           onChange={(e) => setSearchFieldValue(e.target.value)} placeholder={language[lang].myPage.myParcels.search}
                           className={"search-field"}/>
                    <button><SearchIcon onClick={() => setKeyword(searchFieldValue)} style={{color: "white"}}/></button>
                </div>
            </div>
            <Table responsive className={"data-table"}>
                <thead>
                <tr>
                    <th>{language[lang].myPage.myParcels.tracking}</th>
                    <th>{language[lang].myPage.myParcels.description}</th>
                    <th>{language[lang].myPage.myParcels.flightId}</th>
                    <th>{language[lang].myPage.myParcels.price}</th>
                    <th>{language[lang].myPage.myParcels.status}</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {
                    data.map((p, i) => {
                        return (
                            <tr className={`${i % 2 === 1 ? 'odd-row' : 'even-row'} table-rows`}
                                key={p.tracking}>
                                <td>{p.tracking}</td>
                                <td>{p.description}</td>
                                <td>{p.flight_id}</td>
                                <td>{p.price}</td>
                                <td>{language[lang].myPage.myParcels[p.status.replace(" ", "_")]}</td>
                                <td>
                                    <div className={"table-actions-section"}>
                                        <button className={"table-actions-button"} onClick={() => {
                                            setOptionsDropdown({show: true, id: p.tracking});
                                        }}><MoreVertIcon/></button>
                                        {(optionsDropdown.show && optionsDropdown.id === p.tracking) &&
                                            <div className={"options-dropdown-box"}>
                                                <OutsideClickHandler onOutsideClick={() => {
                                                    setOptionsDropdown({show: false, id: 0})
                                                }}>
                                                    <ul className={"table-options-list"}>
                                                        <li onClick={() => {
                                                            setShowViewModal(true);
                                                            setActive({...p});
                                                            setOptionsDropdown({show: false, id: 0})
                                                        }}>View</li>
                                                    </ul>
                                                </OutsideClickHandler>
                                            </div>}

                                    </div>
                                </td>
                            </tr>
                        )
                    })
                }
                {
                    data.length === 0 && (
                        <tr>
                            <td colSpan={9} style={{padding: "12px 0"}}>
                                <p style={{width: "100%", textAlign: "center"}}>{language[lang].myPage.myParcels.recordsNotFound}</p>
                            </td>
                        </tr>
                    )
                }
                </tbody>
            </Table>
            <div className={"pagination-container"}>
                <p>Total: {total}</p>
                <Stack spacing={2}>
                    <Pagination count={Math.ceil(total / limit)} size="large" page={page} onChange={(e, v) => setPage(v)}/>
                </Stack>
            </div>
        </div>
        <ViewParcel lang={lang} role="user" language={language} data={active} show={showViewModal} setShow={setShowViewModal}/>
    </div>
}

export default ArchivedParcels