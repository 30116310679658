import { configureStore } from '@reduxjs/toolkit';
import user from './user';
import admin from './admin'
import notification from './notification';

const store = configureStore({
    reducer: {
        user: user,
        admin: admin,
        notification: notification
    }
});

export default store;