import { createSlice } from "@reduxjs/toolkit";

const initialUser = {
    isLoggedIn: null,
    language: "geo",
    name: "",
    surname: "",
    initials: "",
    userId: "",
    role: "",
    phone: "",
    address: "",
    email: "",
    officeAddress: "",
    country: "",
    userType: "",
    conversation_id: "",
    notification: {
        show: false,
        message: "",
        type: ""
    }
}

const user = createSlice({
    name: 'user',
    initialState: initialUser,
    reducers: {
        changeLanguage(state, action){
            state.language = action.payload;
        },
        setIsLoggedIn(state, action){
            state.isLoggedIn = action.payload;
        },
        setUserData(state, action){
            state.name = action.payload.name;
            state.surname = action.payload.surname;
            state.userId = action.payload.userId;
            state.role = action.payload.role;
            state.phone = action.payload.phone;
            state.address = action.payload.address;
            state.email = action.payload.email;
            state.officeAddress = action.payload.officeAddress;
            state.country = action.payload.country;
            state.userType = action.payload.userType;
            state.conversation_id = action.payload.conversation_id;
        },
        setConversationId(state, action){
            state.conversation_id = action.payload;
        }
    }
});

export const userAction = user.actions;

export default user.reducer;