const language = {
    en: {
        mainPage: {
            header: {
                home: "Home",
                myPage: "My Page",
                flights: "Flights",
                info: "Info",
                contactUs: "Contact Us",
                careers: "Careers",
                services: "Services",
                about: "About Us",
                blog: "Blog",
                login: "Login",
                signUp: "Sign Up",
                usefulInformation: "Useful Information",
                onlineParcels: "Online Parcels",
                deliveryService: "Delivery Service",
                howToBuy: "How To Buy",
                customsProcedure: "Customs Procedure",
                prohibitedItems: "Prohibited Items",
                trustees: "Trustees",
                faq: "FAQ"
            },
            banner: {
                title: "Save time, money and energy, everything is easy with us!",
                prices: ["China (Air) - 11$", "China (terrestrial) - 5$"]
            },
            services: {
                title: "Need to know about our services?",
                description: `Air shipping from China! Short delivery time, actual weight, etc The main thing is the best rate! The cost of transportation to us is 1 kg. – It is 11$, and the shipping time is 7-10 days after the flight is sent.`,
                service: [
                    {
                        title: "Online parcels",
                        description: "Simple Post allows you to subscribe to any desired items From an online store"
                    }, {
                        title: "Parcel Insurance",
                        description: "Protect your shipments with Simple Post. Comprehensive insurance for peace of mind on every delivery."
                    }, {
                        title: "Delivery service",
                        description: "Simple Post delivers your packages with care and speed. From doorstep to destination."
                    }
                ]
            },
            flights: {
                title: "Flights:",
                viewAll: "View all",
                flightId: "Flight ID",
                status: "Status",
                country: "Country",
                warehouse: "Warehouse",
                onWay: "On way",
                china: "China",
                defined: "Warehouse"
            },
            footer: {
                allRights: "All Rights Reserved.",
                info: "Information",
                about: "About Us",
                blogs: "Blogs",
                services: "Services",
                terms: "Terms & Conditions",
                helpful: "Helpful Links",
                contact: "Contact Us",
                faq: "FAQ",
                careers: "Careers",
                subscribeNews: "Subscribe to our newsletter",
                placeholder: "Enter your email address",
                button: "Subscribe",
                privacy: "Privacy policy"
            }
        },
        auth: {
            login: {
                login: "Log In",
                username: "Email / Username",
                userPlaceholder: "Insert email or username",
                password: "Password",
                passwordPlaceholder: "Insert your password",
                resetPassword: "Reset Password",
                signIn: "Sign In",
                noAccount: "Don't have an account?",
                signUp: "Sign Up"
            },
            signUp: {
                accountType: {
                    title: "Please choose your account type",
                    physical: "Physical",
                    legal: "Legal",
                    payerNumber: "Payer number",
                    haveAnAccount: "Already have an account?",
                    signIn: "Sign In",
                    companyNameEn: "Company name (En.)",
                    companyNameGeo: "Company name (Geo.)",
                    identification_code: "Identification code",
                    name_of_representative: "Representative name",
                    surname_of_representative: "Representative surname",
                    position_of_representative: "Representative position",
                    id_number_of_representative: "Representative ID number",
                    companyNameEnPlaceholder: "Insert company name (En.)",
                    companyNameGeoPlaceholder: "Insert company name (Geo.)",
                    identification_codePlaceholder: "Insert identification code",
                    name_of_representativePlaceholder: "Insert representative name",
                    surname_of_representativePlaceholder: "Insert representative surname",
                    position_of_representativePlaceholder: "Insert representative position",
                    id_number_of_representativePlaceholder: "Insert representative ID number",
                    username: "Username",
                    usernamePlaceholder: "Insert your username",
                    email: "Email",
                    emailPlaceholder: "Insert your email address",
                    password: "Password",
                    passwordPlaceholder: "Insert your password",
                    resetPassword: "Repeat password",
                    repeatPasswordPlaceholder: "Repeat your password",
                    name_en: "Name (En)",
                    name_geo: "Name (Geo)",
                    surname_en: "Surname (En)",
                    surname_geo: "Surname (Geo)",
                    name_enPlaceholder: "Insert name (En)",
                    name_geoPlaceholder: "Insert name (Geo)",
                    surname_enPlaceholder: "Insert surname (En)",
                    surname_geoPlaceholder: "Insert surname (Geo)",
                    idNumber: "ID number",
                    resident: "Are you resident?",
                    city: "City",
                    selectCity: "Select your city",
                    selectDistrict: "Select district",
                    address: "Address",
                    addressPlaceholder: "Insert your address",
                    phone: "Phone",
                    phonePlaceholder: "Insert your phone number",
                    verify: "Verify",
                    confirm: "Confirm",
                    verifyPhone: "Please verify phone number!",
                    finish: "Finish registration",
                    district: "District",
                    errors: {
                        identification_code: "Your code is not valid",
                        verifyEmail: "Please verify email address!",
                        verifyPhone: "Please verify phone number!",
                        usernameAvailable: "Username not available!",
                        emailAvailable: "Email not available!",
                        easyPassword: "Password too easy",
                        phoneAvailable: "Phone not available!",
                        match: "Passwords don't match",
                        required: "Field is required!",
                        idNumber: "ID number is not valid!",
                        accept: "Please accept to terms and conditions"
                    }
                }
            }
        },
        flights: {
            flightId: "Flight ID",
            country: "Country",
            estArrive: "Estimate arrival date",
            arrivalDate: "Arrival date",
            status: "Status",
            comment: "Comment",
            searchPlaceholder: "Enter flight ID",
            statusFilters: ["Select status", "Arrived", "On way", "Warehouse"],
            China: "China",
            on_way: "On way",
            arrived: "Arrived",
            warehouse: "Warehouse"
        },
        contacts: {
            address: "Address",
            addressValue: ["Georgia, Tbilisi", "Richard Holbruk st. N4"],
            phone: "Phone",
            support: "Support",
            contact: "Contact Us",
            name: "Name",
            surname: "Surname",
            email: "Email",
            message: "Message",
            namePlaceholder: "Insert your name",
            surnamePlaceholder: "Insert your surname",
            emailPlaceholder: "Insert your email",
            phonePlaceholder: "Insert your phone",
            messagePlaceholder: "Enter your message",
            sent: "Your message sent to our team",
            send: "Send Message"
        },
        myPage: {
            aside: {
                title: "My Page",
                parcels: "My parcels",
                preDeclared: "Preliminary Declared",
                archived: "Archived Parcels",
                Addresses: "Addresses",
                payments: "Payments",
                delivery: "Delivery Service",
                inbox: "Inbox",
                profile: "Profile",
                myDebt: "My Debt",
                debt: "Debt:",
                deliveryDebt: "Delivery Debt:",
                paySelected: "Pay Selected",
                payAll: "Pay All",
            },
            myParcels: {
                insurance: "Insurance",
                comment: "Comment",
                clean: "Clean (Green)",
                need_clearance: "Need clearance (Red)",
                stopped_on_custom: "Stopped on custom (Yellow)",
                undeclared: "Undeclared (Light blue)",
                id_number_problem: "ID number problem (Blue)",
                custom_status: "Custom process status",
                shelf_id: "Shelf number",
                submit: "Submit",
                cancel: "Cancel",
                name: "Name",
                surname: "Surname",
                id_number: "ID number",
                parcelTakerError: "Please select parcel taker",
                selectFileError: "Please attach invoice file",
                website: "Wrong website format",
                noTracking: "Tracking not available",
                currency: "Currency",
                selectCurrency: "Select Currency",
                paymentMethod: "Payment method",
                noFile: "File not found",
                deliveryStatus: ["Select status", "Pending", "On way", "Delivered"],
                addDelivery: "Add delivery address",
                addressPlaceholder: "Insert delivery address",
                title: "My Parcels",
                all: "All Parcels",
                inWarehouse: "In The Warehouse",
                onWay: "On The Way",
                received: "Received",
                search: "Enter tracking number",
                delivery: "Delivery",
                selectToProcess: "Please select parcels to process",
                tracking: "Tracking",
                receiver: "Receiver",
                description: "description",
                flightId: "Flight ID",
                declared: "Is Declared",
                payed: "Is Payed",
                status: "Status",
                arrived: "Arrived",
                warehouse: "Warehouse",
                on_way: "On way",
                view: "View",
                declaration: "Declaration",
                userId: "User ID",
                website: "Website",
                warehouseReceiveDate: "Warehouse receive date",
                price: "Price",
                weight: "Weight",
                debtGel: "Debt (GEL):",
                debtUsd: "Debt (USD)",
                danger: "Danger",
                invoice: "Invoice",
                country: "Country",
                receiverIdNumber: "Receiver ID number",
                receiverPhone: "Receiver phone",
                trustee: "Trustee",
                trusteePhone: "Trustee phone",
                trusteeIdNumber: "Trustee ID number",
                China: "China",
                no: "No",
                yes: "Yes",
                declarationTitle: "Declaration of the parcel",
                upload: "Upload file",
                select: "Select file",
                pickup: "You want someone else to pick up the parcel?",
                preliminaryTitle: "Preliminary Declared",
                addNew: "Add New",
                edit: "Edit",
                delete: "Delete",
                total: "Total",
                deleteParcel: "Delete Parcel",
                deleteAccept: "Do you want to delete",
                archivedTitle: "Archived Parcels",
                taken: "Taken",
                addresses: "Addresses",
                deliveryTitle: "Delivery Service",
                paymentDate: "Payment Date",
                method: "Payment Method",
                address: "Address",
                recordsNotFound: "Records not found",
                pending: "Pending",
                delivered: "Delivered",
                payDelivery: "Pay Delivery",
                profile: "Profile",
                selectImg: "Select Image",
                uploadImg: "Upload",
                changePass: "Change Password",
                username: "Username",
                phone: "Phone",
                email: "Email",
                city: "City",
                revert: "Revert",
                save: "Save",
                phonePlaceHolder: "Insert you Phone",
                emailPlaceHolder: "Insert you Email",
                cityPlaceHolder: "Insert you City",
                oldPassword: "Old password",
                newPassword: 'New password',
                repeatPassword: "Repeat password",
                oldPasswordPlaceholder: "Insert your old password",
                newPasswordPlaceholder: 'Insert your new password',
                repeatPasswordPlaceholder: "Repeat your new password",
                messagePlaceholder: "Enter message here",
                createConversation: "Create Conversation",
                emptyChat: "Conversation is empty",
                attachFile: "Attach file",
                removeSelected: "Remove selected",
                invoiceNumber: "Invoice #",
                trackings: "Trackings",
                paymentDate: "Payment Date",
                userId: "User ID",
                amount: "Amount",
                paymentId: "Payment ID",
                status: "Status",
                transactionId: "Transaction #",
                paymentMethod: "Payment method"
            }
        },
        profileDropdown: {
            inbox: "Inbox",
            payments: "Payments",
            setting: "Settings",
            logout: "Logout"
        },
        services: {
            onlineParcels: {
                title: "Online Parcels",
                content: [`Simple Post allows you to subscribe to any desired items
                     From an online store. We will bring it to you in the shortest possible time and at a low price
                     in Georgia.`, `When you register on our site, you are given a personal mailing address in China,
                     in which the room number is indicated. This address is used in online stores for products
                     To specify in the address field during subscription.`]
            },
            delivery: {
                title: "Delivery Service",
                content: [
                    {
                        title: "How to request courier service?",
                        content: [
                            `In order for the courier to bring you the parcel, it must be on a specific parcel
                        Press the courier service button (in case of several parcels
                        Mark the courier service of the package).`, `The courier service is marked as when declaring the parcel,
                             So after the declaration, along with the tracking of the parcel
                             "Courier service" button.`
                        ]
                    },
                    {
                        title: "Damaged packaging:",
                        content: [
                            `The customer must check the packaging of the parcel in the presence of the courier.
                                In case of any kind of external damage, fix it with him
                        Claim.`, "Price: 8 GEL*"
                        ]
                    },
                    {
                        title: "Several parcels per 1 person:",
                        content: [
                            `In the event that 2 or
                            More parcels and you want all parcels to be brought to you by the courier
                            Only the amount of courier service for 1 parcel, the rest of the parcels
                        The courier will bring it to you for free. However, it is mandatory for all parcels
                        Mark the courier service.`,
                            `For this, you need a list of packages that have arrived or arrived in your room
                             In the parcel list, mark the courier on all desired parcels
                             service.`,
                            `* Okrokana, Lilo, Tsenubani, Tskneti, Tsavkis, Fonichala, Zemo Fonichala
                             5 GEL is added to the standard price`
                        ]
                    },
                    {
                        title: "Additional information:",
                        content: [
                            `Parcels will be delivered to the address after the arrival status has been assigned
                            and the next two business days after choosing/paying for the courier service
                        during.`,
                            `* When using the courier service, the courier delivers the package
                        near the building. His obligation does not include carrying the parcel to the floor.`
                        ]
                    }
                ]
            }
        },
        usefulInformation: {
            howToBuy: {
                title: "How do I subscribe to a package?",
                content: [
                    "Register on our site", "Register on the site from which you subscribe to the product", `After authentication with us, in the section "Addresses"
                You will see the China address assigned to you to "copy" from; In the fields for adding an address on the online store site`,
                    `A few days after subscription, the courier will pick up your package from the store to bring it to our office (address)`, `Domestic transportation (Shipping) may take
        A few days`, `After the delivery of the parcel to the address, you will receive a message from both the store and us.`, `After the parcel is reflected in your account, proceed
                     Log in to our site and declare the parcel`, `During the declaration you need to fill in several fields`, `The value of the item (how much you paid for the said
                     in the item and in what currency)`, `Select currency (CNY; USD)`, `Enter the address of the store site (www.taobao.com, www.alibaba.com, etc.)`, `Enter the item description (what item is in the parcel)`,
                    `Upload the invoice (if the parcel does not exceed 300 GEL and is not subject to customs clearance, uploading the invoice is not mandatory)`, `After declaring the parcel, wait
For messages regarding shipping and arrival, see the invoice for the shipping service in your room.`
                ]
            },
            customsProcedure: {
                title: "Customs rules, conditions and procedures",
                content: []
            },
            danger: {
                title: "აკრძალული ნივთები",
                content: [
                    `According to the legislation of Georgia, it is prohibited to send the following items by mail:`, `real money, coins or banknotes;`,
                    `bank and credit cards and other securities;`, `food products;`, `explosive substances;`, `soil and seedlings with soil;`, `pornographic products;`,
                    `live animals;`, `any kind of drug, products containing narcotic substances;`, `plants containing narcotic or psychotropic substances or their seeds;`,
                    `Tobacco and items containing tobacco;`, `Psychotropic substances;`, `Plant seeds;`, `Prohibited items from China:`,
                    `Guns, sights, gun parts, magnets, candles, lighters (including electric lighters), powder, compressed powder, toothpastes, eye
                    Shades, knives, scissors and similar sharp objects, toy guns, electronic cigarettes, power banks, batteries, liquids, paint, felt-tip pens,
                    Plants, medical cargo, items (it is allowed to bring an item only if it comes with the item. For example -
                    mobile phone) and cosmetics such as: nail polish, acetone, creams and others. Products with explosive and flammable packaging. them
                    Between: spray, pulverizer, deodorizer, aerosol and perfume`
                ]
            },
            trustedPersons: {
                title: "Trustees",
                content: [
                    `Who is the trusted person?`, `A trusted person is someone who will pick up your package on your behalf. The proxy is added in advance, from your profile,
                     By marking a specific parcel.`, `What is required for the package to be picked up by a trusted person?`,
                    `From your account, on a particular parcel, it is necessary to indicate that the parcel will be delivered by an authorized person.`, `The authorized person must bring his/her identity card, passport or
                     Original driver's license confirmation document.`, `Can a trusted person take out a parcel for customs clearance?`, `No. You cannot use the function of a trusted person to clear the parcel
                     in case Removal of the customs-cleared parcel by another person is done only on the basis of a power of attorney certified by a notary.`
                ]
            },
            faq: {
                title: "Frequently Asked Questions",
                content: [
                    `What does the billing and shipping address mean?`, `Billing address (billing address) is the address that you specified in the bank when receiving a plastic card.`,
                    `Shipping Address, means the address where you want your purchase to be delivered, i.e. China from where we will already transport it to you.`, `In which foreign online stores can I shop?`,
                    `You can buy items at any online store across China that accepts our China address, provides delivery to our warehouse address, and accepts a Georgian card.`,
                    `How do I find out how much an item weighs before I buy it?`, `Most stores indicate the weight of all products, you can always calculate the approximate weight using the data provided by the store.
                     However, you will receive detailed information after the parcels have entered our warehouse in China.`, `My parcel arrived at the warehouse, but it was not reflected in the system, what's wrong?`,
                    `The parcel will be reflected in the system within 2-3 working days after arrival. When the parcel is not reflected in the system, there are several reasons:`, `The user/seller has entered an incorrect personal mailbox number`,
                    `The package did not reach us at all because the address was wrong`, `The recipient's data is written incorrectly/incompletely on the parcel (in this case, the recipient will be searched by Inex Group, which will take several days)`,
                    `The courier delivered the package to the wrong address`, `My package didn't show up in my room, it's been 2-3 working days since it arrived, what should I do?`, `Send the following information to info@simplepost.ge:`,
                    `parcel tracking number;`, `Parcel invoice (must be recorded on one page: user name and surname, inex room number and parcel tracking number);`,
                    `Delivery confirmation screen`, `We will contact you if additional documentation is required.`, `The parcel did not arrive at the warehouse, who should I contact?`,
                    `First of all, you should contact the sending online store and find out the exact date of shipment by means of the parcel code (Tracking Number).`, `Until the package arrives at our warehouse, we cannot control it and therefore do not
                     We are responsible for its loss. In such a case, you should contact the sending online store in order to compensate for the loss.`, `Is the package being sent checked?`,
                    `The sent parcel goes through the necessary scanning. If the product found in the parcel, the transportation of which is prohibited, it is sent back to the seller or subject to destruction.`,
                    `Where can I pay for parcel transportation?`, `With a plastic card through our site - commission 0%`, `When paying with a plastic card, our site will give you the opportunity to choose one
                     The bank's platform with which card you intend to pay.`, `If you have a Bank of Georgia card, then select Bank of Georgia. If you have a TBC Bank card, then select TBC Bank.`,
                    `with a plastic card when picking up a parcel at our office`
                ]
            }
        }
    },
    geo: {
        mainPage: {
            header: {
                home: "მთავარი",
                myPage: "ჩემი გვერდი",
                flights: "რეისები",
                info: "ინფორმაცია",
                contactUs: "კონტაქტი",
                careers: "კარიერა",
                services: "სერვისები",
                about: "ჩვენს შესახებ",
                blog: "ბლოგი",
                login: "შესვლა",
                signUp: "რეგისტრაცია",
                usefulInformation: "სასარგებლო ინფორმაცია",
                onlineParcels: "ონლაინ ამანათები",
                deliveryService: "მიწოდების სერვისი",
                howToBuy: "როგორ გამოვიწერო",
                customsProcedure: "საბაჟო პროცედურები",
                prohibitedItems: "აკრძალული ნივთები",
                trustees: "მინდობილი პირები",
                faq: "ხშირად დასმული კითხვები"
            },
            banner: {
                title: "დაზოგე დრო, ფული და ენერგია, ჩვენთან ერთად ყველაფერი მარტივია!",
                prices: ["ჩინეთი (საჰაერო) - 11$", "ჩინეთი (სახმელეთო) - 5$"]
            },
            services: {
                title: "გსურთ მიიღოთ ინფორმაცია ჩვენს სერვისებზე?",
                description: `საჰაერო გადაზიდვა ჩინეთიდან! ჩამოტანის მოკლე ვადა, რეალური წონა და რაც მთავარია საუკეთესო ტარიფი! ჩვენთან ტრანსპორტირების ღირებულება 1 კგ. – 11$-ია, ტრანსპორტირების ვადა კი, რეისის გამოგზავნიდან 7-10 დღე.`,
                service: [
                    {
                        title: "ონლაინ ამანათები",
                        description: "Simple Post გაძლევთ საშუალებას გამოიწეროთ სასურველი ნივთი ონლაინ მაღაზიიდან"
                    }, {
                        title: "ამანათის დაზღვევა",
                        description: "დაიცავით თქვენი გადაზიდვები Simple Post-ით. დაზღვევა სიმშვიდისთვის, ყოველ მიწოდებაზე."
                    }, {title: "მიწოდების სერვისი", description: "Simple Post მოგაწვდით თქვენს ამანათს მინიმალურ დროში. ჩვენი საწყობიდან დანიშნულების ადგილამდე."}
                ]
            },
            flights: {
                title: "რეისები:",
                viewAll: "ყველას ნახვა",
                flightId: "რეისი",
                status: "სტატუსი",
                country: "ქვეყანა",
                warehouse: "საწყობში",
                on_way: "გზაში",
                China: "ჩინეთი",
                defined: "საწყობში"
            },
            footer: {
                allRights: "ყველა უფლება დაცულია.",
                info: "ინფორმაცია",
                about: "ჩვენს შესახებ",
                blogs: "ბლოგი",
                services: "სერვისები",
                terms: "წესები & პირობები",
                helpful: "სასარგებლო ინფორმაცია",
                contact: "დაგვიკავშირდით",
                faq: "FAQ",
                careers: "კარიერა",
                subscribeNews: "გამოიწერეთ სიახლეები",
                placeholder: "ჩაწერეთ თქვენი ელ.ფოსტა",
                button: "გამოწერა",
                privacy: "კონფიდენციალურობის პოლიტიკა"
            }
        },
        auth: {
            login: {
                login: "შესვლა",
                username: "ელ.ფოსტა / მომხ. სახელი",
                userPlaceholder: "ჩაწერეთ ელ. ფოსტა ან მომხ. სახელი",
                password: "პაროლი",
                passwordPlaceholder: "ჩაწერეთ თქვენი პაროლი",
                resetPassword: "პაროლის აღდგენა",
                signIn: "ავტორიზაცია",
                noAccount: "არ გაქვთ ექაუნთი?",
                signUp: "რეგისტრაცია"
            },
            signUp: {
                accountType: {
                    title: "აირჩიეთ თქვენი ექაუნთის ტიპი",
                    physical: "ფიზიკური",
                    legal: "იურიდიული",
                    district: "უბანი",
                    companyNameEn: "კომპანიის დასახელება (ინგ.)",
                    companyNameGeo: "კომპანიის დასახელება (ქარ.)",
                    identification_code: "საიდენთიფიკაციო კოდი",
                    name_of_representative: "წარმომადგენლის სახელი",
                    surname_of_representative: "წარმომადგენლის გვარი",
                    position_of_representative: "წარმომადგენლის პოზიცია",
                    id_number_of_representative: "წარმომადგენლის პირადი ნომერი",
                    companyNameEnPlaceholder: "ჩაწერეთ კომპანიის დასახელება (ინგ.)",
                    companyNameGeoPlaceholder: "ჩაწერეთ კომპანიის დასახელება (ქარ.)",
                    identification_codePlaceholder: "ჩაწერეთ საიდენთიფიკაციო კოდი",
                    name_of_representativePlaceholder: "ჩაწერეთ წარმომადგენლის სახელი",
                    surname_of_representativePlaceholder: "ჩაწერეთ წარმომადგენლის გვარი",
                    position_of_representativePlaceholder: "ჩაწერეთ წარმომადგენლის პოზიცია",
                    id_number_of_representativePlaceholder: "ჩაწერეთ წარმომადგენლის პირადი ნომერი",
                    haveAnAccount: "უკვე გაქვთ ექაუნთი?",
                    signIn: "ავტორიზაცია",
                    payerNumber: "გადამხდელის ნომერი",
                    username: "მომხ. სახელი",
                    usernamePlaceholder: "ჩაწერეთ მომხმარებლის სახელი",
                    email: "ელ. ფოსტა",
                    emailPlaceholder: "ჩაწერეთ თქვენი ელ. ფოსტა",
                    password: "პაროლი",
                    passwordPlaceholder: "ჩაწერეთ პაროლი",
                    resetPassword: "გაიმეორეთ პაროლი",
                    repeatPasswordPlaceholder: "გაიმეორეთ პაროლი",
                    name_en: "სახელი (ინგ.)",
                    name_geo: "სახელი (ქართ.)",
                    surname_en: "გვარი (ინგ.)",
                    surname_geo: "გვარი (ქართ.)",
                    name_enPlaceholder: "ჩაწერეთ სახელი (ინგ.)",
                    name_geoPlaceholder: "ჩაწერეთ სახელი (ქართ.)",
                    surname_enPlaceholder: "ჩაწერეთ გვარი (ინგ.)",
                    surname_geoPlaceholder: "ჩაწერეთ გვარი (ქართ.)",
                    idNumber: "პირადი ნომერი",
                    resident: "ხართ თუ არა რეზიდენტი?",
                    city: "ქალაქი",
                    selectCity: "აირჩიეთ ქალაქი",
                    selectDistrict: "აირჩიეთ უბანი",
                    address: "მისამართი",
                    addressPlaceholder: "ჩაწერეთ თქვენი მისამართი",
                    phone: "ტელეფონი",
                    phonePlaceholder: "ჩაწერეთ თქვენი ნომერი",
                    verify: "შემოწმება",
                    confirm: "დადასტურება",
                    finish: "რეგისტრაციის დასრულება",
                    next: "შემდეგი",
                    prev: "წინა",
                    errors: {
                        identification_code: "კოდი არ არის ვალიდური",
                        verifyPhone: "გთხოვთ დაადასტუროთ თქვენი ნომერი!",
                        verifyEmail: "გთხოვთ დაადასტუროთ თქვენი ელ.ფოსტა!",
                        usernameAvailable: "მომხ. სახელი დაკავებულია!",
                        emailAvailable: "ელ.ფოსტა უკვე რეგისტრირებულია!",
                        phoneAvailable: "ტელ. ნომერი უკვე რეგისტრირებულია",
                        easyPassword: "პაროლი ძალიან მარტივია",
                        match: "პაროლები არ ემთხვევა",
                        required: "ველის შევსება სავალდებულოა!",
                        idNumber: "პირადი ნომერი არ არის ვალიდური!",
                        accepted: "გთხოვთ დაეთანხმოთ წესებს და პირობებს"
                    }
                }
            }
        },
        flights: {
            flightId: "რეისის ID",
            country: "ქვეყანა",
            estArrive: "სავარაუდო ჩამოსვლის დრო",
            arrivalDate: "ჩამოსვლის დრო",
            status: "სტატუსი",
            comment: "კომენტარი",
            searchPlaceholder: "ჩაწერეთ რეისის ID",
            statusFilters: ["მონიშნეთ სტატუსი", "ჩამოსული", "გზაში", "საწყობში"],
            China: "ჩინეთი",
            on_way: "გზაში",
            arrived: "ჩამოსული",
            warehouse: "საწყობში",
            total: "რაოდენობა"
        },
        contacts: {
            address: "მისამართი",
            addressValue: ["საქართველო, თბილისი", "რიჩარდ ჰოლბრუკის ქუჩა N4"],
            phone: "ტელეფონი",
            support: "ელ. ფოსტა",
            contact: "დაგვიკავშირდით",
            name: "სახელი",
            surname: "გვარი",
            email: "ელ. ფოსტა",
            message: "შეტყობინება",
            namePlaceholder: "ჩაწერეთ თქვენი სახელი",
            surnamePlaceholder: "ჩაწერეთ თქვენი გვარი",
            emailPlaceholder: "ჩაწერეთ თქვენი ელ.ფოსტა",
            phonePlaceholder: "ჩაწერეთ თქვენი ნომერი",
            messagePlaceholder: "ჩაწერეთ შეტყობინების ტექსტი",
            sent: "შეტყობინება გაგზავნილია ჩვენს გუნდთან",
            send: "შეტყობინების გაგზავნა"
        },
        myPage: {
            aside: {
                title: "ჩემი გვერდი",
                parcels: "ჩემი გზავნილები",
                preDeclared: "წინასწარი დეკლარირება",
                archived: "დაარქივებული გზავნილები",
                addresses: "მისამართები",
                payments: "გადახდები",
                delivery: "მიწოდების სერვისი",
                inbox: "შეტყობინებები",
                profile: "პროფილი",
                myDebt: "ჩემი დავალიანება",
                debt: "დავალიანება:",
                deliveryDebt: "მიწოდების დავალიანება:",
                paySelected: "მონიშნულის გადახდა",
                payAll: "ყველას გადახდა",
            },
            myParcels: {
                insurance: "დაზღვევა",
                comment: "კომენტარი",
                clean: "სუფთა (მწვანე)",
                need_clearance: "საჭიროა განბაჟება (წითელი)",
                stopped_on_custom: "საბაჟოზე გაჩერებული (ყვითელი)",
                undeclared: "დასადეკლარირებელი (ცისფერი)",
                id_number_problem: "გაჩერებულია პ.ნ.-ის გამო (ლურჯი)",
                custom_status: "საბაჟო პროცედურის სტატუსი",
                shelf_id: "თაროს ნომერი",
                submit: "დამატება",
                cancel: "დახურვა",
                name: "სახელი",
                surname: "გვარი",
                id_number: "პირადი ნომერი",
                deliveryStatus: ["მონიშნე სტატუსი", "მოლოდინში", "გზაში", "დასრულებული"],
                addDelivery: "მიწოდების დამატება",
                addressPlaceholder: "ჩაწერეთ მიწოდების მისამართი",
                title: "ჩემი გზავნილები",
                all: "ყველა",
                inWarehouse: "საწყობში",
                onWay: "გზაში",
                received: "ჩამოსული",
                search: "ჩაწერეთ თრექინგის ნომერი",
                delivery: "მიწოდება",
                selectToProcess: "გასაგრძელებლად მონიშნეთ გზავნილი",
                tracking: "თრექინგი",
                receiver: "მიმღები",
                description: "აღწერა",
                flightId: "რეისის ID",
                declared: "დეკლარირებული",
                payed: "გადახდილი",
                status: "სტატუსი",
                arrived: "ჩამოსული",
                warehouse: "საწყობში",
                on_way: "გზაში",
                view: "ნახვა",
                noFile: "ფაილი არ მოიძებნა",
                declaration: "დეკლარაცია",
                userId: "ოთახის ნომერი",
                website: "საიტი",
                warehouseReceiveDate: "საწყობში მიღების თარიღი",
                price: "ფასი",
                weight: "წონა",
                debtGel: "დავალიანება (GEL):",
                debtUsd: "დავალიანება (USD)",
                danger: "სახიფათო",
                invoice: "ინვოისი",
                country: "ქვეყანა",
                receiverIdNumber: "მიმღების პირადი ნომერი",
                receiverPhone: "მიმღების ტელეფონი",
                trustee: "მინდობილი პირი",
                trusteePhone: "მინდობილის პირის ნომერი",
                trusteeIdNumber: "მინდობილის პირის პ.ნ.",
                China: "ჩინეთი",
                no: "არა",
                yes: "დიახ",
                declarationTitle: "გზავნილის დეკლარირება",
                upload: "ფაილის ატვირთვა",
                select: "ფაილის მონიშვნა",
                pickup: "გსურთ გზავნილი სხვამ გაიტანოს?",
                preliminaryTitle: "წინასწარი დეკლარირება",
                addNew: "ახლის დამატება",
                currency: "ვალუტა",
                selectCurrency: "მონიშნეთ ვალუტა",
                edit: "კორექტირება",
                delete: "წაშლა",
                total: "რაოდენობა",
                deleteParcel: "გზავნილის წაშლა",
                deleteAccept: "ნამდვილად გსურთ გზავნილის წაშლა?",
                archivedTitle: "დაარქივებული გზავნილები",
                taken: "გატანილი",
                paymentMethod: "გადახდის მეთოდი",
                addresses: "მისამართები",
                deliveryTitle: "მიწოდების სერვისი",
                paymentDate: "გადახდის თარიღი",
                method: "გადახდის მეთოდი",
                address: "მისამართი",
                recordsNotFound: "ჩანაწერები არ მოიძებნა",
                pending: "მოლოდინში",
                delivered: "მიწოდებული",
                payDelivery: "მიწოდების გადახდა",
                profile: "პროფილი",
                selectImg: "სურათის მონიშვნა",
                uploadImg: "ატვირთვა",
                changePass: "პაროლის შეცვლა",
                username: "მომხ. სახელი",
                phone: "ტელეფონი",
                email: "ელ. ფოსტა",
                city: "ქალაქი",
                revert: "დაბრუნება",
                save: "შენახვა",
                phonePlaceHolder: "ჩაწერეთ ნომერი",
                emailPlaceHolder: "ჩაწერეთ ელ. ფოსტა",
                cityPlaceHolder: "მონიშნეთ ქალაქი",
                oldPassword: "ძველი პაროლი",
                newPassword: 'ახალი პაროლი',
                repeatPassword: "გაიმეორეთ პაროლი",
                oldPasswordPlaceholder: "ჩაწერეთ ძველი პაროლი",
                newPasswordPlaceholder: 'ჩაწერეთ ახალი პაროლი',
                repeatPasswordPlaceholder: "გაიმეორეთ ახალი პაროლი",
                parcelTakerError: "გთხოვთ მონიშნოთ გზავნილის გამტანი",
                selectFileError: "გთხოვთ ატვირთოთ ინფოისის ფაილი",
                website: "საიტის ფორმატი არასწორია",
                noTracking: "გზავნილი უკვე არსებობს",
                messagePlaceholder: "ჩაწერეთ შეტყობინების ტექსტი",
                createConversation: "ჩატის შექმნა",
                emptyChat: "ჩატი ცარიელია",
                attachFile: "ფაილის ატვირთვა",
                removeSelected: "მონიშნულის წაშლა",
                invoiceNumber: "ინვოისის #",
                trackings: "თრექინგი",
                paymentDate: "გადახდის თარიღი",
                userId: "მომხ. ID",
                amount: "თანხა",
                paymentId: "გადახდის ID",
                status: "სტატუსი",
                transactionId: "ტრანზაქციის #",
                paymentMethod: "გადახდის ტიპი",
            }
        },
        profileDropdown: {
            inbox: "შეტყობინებები",
            payments: "გადახდები",
            setting: "პარამეტრები",
            logout: "გასვლა"
        },
        services: {
            onlineParcels: {
                title: "ონლაინ გზავნილები",
                content: [`Simple Post საშუალებას გაძლევთ გამოიწეროთ სასურველი ნივთები ნებისმიერი
                    ონლაინ მაღაზიიდან, ჩვენ უმოკლეს ვადაში და დაბალ ფასად ჩამოგიტანთ მას
                    საქართველოში.`, `ჩვენს საიტზე რეგისტრაციისას გეძლევათ პირადი საფოსტო მისამართი ჩინეთში,
                    რომელშიც მითითებულია ოთახის ნომერი. ეს მისამართი გამოიყენება ონლაინ მაღაზიებში პროდუქციის
                    გამოწერის დროს, მისამართის ველში მისათითებლად.`]
            },
            delivery: {
                title: "საკურიერო მომსახურება",
                content: [
                    {
                        title: "როგორ მოვითხოვოთ საკურიერო მომსახურება?",
                        content: [
                            `იმისთვის, რომ კურიერმა მოგიტანოთ ამანათი, კონკრეტულ ამანათზე უნდა
                            დააწვეთ საკურიერო მომსახურების ღილაკს (რამდენიმე ამანათის შემთხვევაში
                            მონიშნეთ სასკურველი ამანათების საკურიერო მომსახურება).`, `საკურიერო მომსახურების მონიშვნა ხდება, როგორც ამანათის დეკლარირებისას,
                            ისე დეკლარირების შემდეგ, ამანათის თრექინგის გასწვრივ არსებული
                            "საკურიერო მომსახურების" ღილაკით.`
                        ]
                    },
                    {
                        title: "დაზიანებული შეფუთვა:",
                        content: [
                            `მომხმარებელმა კურიერის თანდასწრებით უნდა შეამოწმოს ამანათის შეფუთვა.
                            რაიმე სახის გარეგანი დაზიანების შემთხვევაში კი მასთან დააფიქსიროს
                            პრეტენზია.`, "ფასი: 5 ლარი*"
                        ]
                    },
                    {
                        title: "რამდენიმე ამანათი 1 პიროვნებაზე:",
                        content: [
                            `იმ შემთხვევაში, თუ ერთი პიროვნების სახელზე ერთ ჩამოსვლაზე ჩამოვა 2 ან
                            მეტი ამანათი და გინდათ, რომ ყველა ამანათი მოგიტანოთ კურიერმა გადაიხდით
                            მხოლოდ 1 ამანათის საკურიერო მომსახურების თანხას , დანარჩენ ამანათებს
                            კურიერი მოგიტანთ უფასოდ. თუმცა, სავალდებულოა, ყველა ამანათზე
                            მონიშნოთ საკურიერო მომსახურება.`,
                            `ამისათვის საჭიროა თქვენს ოთახში წამოსული ამანათების სიაში ან ჩამოსული
                            ამანათების სიაში ყველა სასურველ ამანათზე მონიშნოთ საკურიერო
                            მომსახურება.`,
                            `* ოქროყანა, ლილო, წინუბანი, წყნეთი, წავკისი, ფონიჭალა,ზემო ფონიჭალა
                            სტანდარტულ ღირებულებას ემატება 5 ლარი.`
                        ]
                    },
                    {
                        title: "დამატებითი ინფორმაცია:",
                        content: [
                            `ამანათების მისამართზე მიწოდება მოხდება, ჩამოსულის სტატუსის მინიჭებიდან
                            და საკურიერო სერვისის არჩევიდან/გადახდიდან მომდევნო ორი სამუშაო დღის
                            განმავლობაში.`,
                            `* საკურიერო მომსახურებით სარგებლობისას კურიერს ამანათი მიაქვს
                            შენობასთან. მის ვალდებულებაში არ შედის ამანათის სართულზე ატანა.`
                        ]
                    }
                ]
            }
        },
        usefulInformation: {
            howToBuy: {
                title: "როგორ გამოვიწერო ამანათი?",
                content: [
                    "დარეგისტრდით ჩვენს საიტზე", "დარეგისტრირდით საიტზე, საიდანაც იწერთ პროდუქციას", `ჩვენთან ავტორიზაციის გავლის შემდეგ, სექციაში „მისამართები“
                    გამოჩნდება თქვენთვის მინიჭებული ჩინეთის მისამართი, რომლიდანაც უნდა "ჩააკოპიროთ" ონლაინ მაღაზიის საიტზე მისამართის დამატების ველებში`,
                    `გამოწერიდან რამდენიმე დღეში კურიერი მაღაზიიდან წამოიღებს თქვენს ამანათს ჩვენს ოფისში (მისამართზე) მოსატანად`, `შიდა ტრანსპორტირებას (Shipping-ს) შესაძლოა დასჭირდეს
                    რამდენიმე დღე`, `ამანათის მისამართზე მიტანის შემდგომ, თქვენ მიიღებთ შეტყობინებას, როგორც მაღაზიისგან, ასევე ჩვენგან.`, `ამანათის თქვენს ანგარიშზე ასახვის შემდგომ გაიარეთ
                    ავტორიზაცია ჩვენს საიტზე და დაადეკლარირეთ ამანათი`, `დეკლარირების დროს უნდა შეავსოთ რამდენიმე ველი“`, `ნივთის ღირებულება (რა თანხა გადაიხადეთ აღნიშნულ
                    ნივთში და რა ვალუტაში)`, `აირჩიეთ ვალუტა (CNY; USD)`, `ჩაწერეთ მაღაზიის საიტის მისამართი (www.taobao.com, www.alibaba.com და ა.შ.)`, `ჩაწერეთ ნივთის აღწერა (რა ნივთი დევს ამანათში)`,
                    `ატვირთეთ ინვოისი (თუ ამანათი არ სცდება 300 ლარს და არ ექვემდებარება განბაჟებას, ინვოისის ატვირთვა არ გახლავთ სავალდებულო)`, `ამანათის დეკლარირების შემდგომ დაელოდეთ შემდეგ
                    შეტყობინებებს, გამოგზავნასთან და ჩამოსვლასთან დაკავშირებით`, `სადეკლარანტო მომსახურების ინვოისი იხილეთ თქვენს ოთახში.`
                ]
            },
            customsProcedure: {
                title: "საბაჟო წესები, პირობები და პროცედურები",
                content: [
                    `განბაჟებისთვის საჭირო ყველანაირ პროცედურას და საბაჟოსთან ურთიერთობას ახორციელებენ ჩვენი კომპანიის დეკლარანტები.`, `შემოსავლების სამსახურის განმარტებით, ამანათების
                    დეკლარირება საფოსტო გადაზიდვის მომსახურებისგან დამოუკიდებელ მომსახურებას წარმოადგენს და ექვემდებარება დაბეგვრას. აღნიშნულის გათვალისწინებით, სადეკლარანტო მომსახურება, გახლავთ 
                    ფასიანი და ონლაინ ამანათების დეკლარაციის საფასური განისაზღვრება 5 ლარით.`, `სადეკლარანტო მომსახურების საფასურის გადახდა შესაძლებელია, როგორც ონლაინ, ჩვენი საიტის საშუალებით, 
                    ისე ადგილზე TBC ბანკის ტერმინალით, ამანათის გატანისას.`, `სადეკლარანტო მომსახურების ინვოისი იხილეთ თქვენს ოთახში.`,
                    `2022 წლის 14 მაისიდან კანონში შევიდა ცვლილება. კერძოდ შეიცვალა საქართველოს მთავრობის №201 დადგენილება. ცვლილების მიხედვით საქართველოს მთავრობა
                    განბაჟების თანხის პარალელურად, აწესებს საქართველოს საბაჟოს მომსახურების გადასახადს განსაბაჟებელ საქონელზე.`, `300-დან 3 000 ლარამდე ღირებულების საქონელზე განსაბაჟებელ თანხას დაემატება –
                    20 ლარი;`, `3 000-დან 10 000 ლარამდე ღირებულების საქონელზე კი განსაბაჟებელ თანხას დაემატება – 100 ლარი.`, `აღნიშნული თანხის გადახდა მოხდება საქართველოს ხაზინის ანგარიშზე, დეკლარაციის
                    დაბეჭდვამდე და განბაჟების საფასურის გადახდამდე.`, `რა შემთხვევაში ექვემდებარება ამანათი განბაჟებას?`, `საქართველოს კანონმდებლობით, განბაჟებას ექვემდებარება 300 ლარზე მეტი
                    ღირებულების, 30კგ-ზე მეტი წონის ან ერთგვაროვანი საქონელი.`, `თუ თქვენს მიერ გამოწერილი ამანათები არის ერთი საიტიდან, მოხვდება ერთ რეისზე და მათი ჯამური ღირებულება 300 ლარზე მეტია, ასევე დაექვემდებარება
                    განბაჟებას.`, `მაგალითად: თუ ერთი რეისით ჩამოვა taobao.com-დან თქვენს მიერ გამოწერილი 2 ამანათი, ერთი 200 ლარის და მეორე 101 ლარის ღირებულების, დაექვემდებარება განბაჟებას.`,
                    `თუ თქვენს მიერ სხვადასხვა საიტიდან გამოწერილი ამანათები მოხვდება ერთ რეისზე და მათი ღირებულება 300 ლარზე მეტია, ამანათები არ დაექვემდებარება განბაჟებას.`,
                    `მაგალითად: თუ ერთი რეისით ჩამოვიდა taobao.com-დან და pinduoduo.com-დან თქვენს მიერ გამოწერილი 2 ამანათი, 201 ლარის და 100 ლარის ღირებულების, არ დაექვემდებარება განბაჟებას.`,
                    `როგორია განბაჟების პროცედურა?`, `განსაბაჟებელი ამანათი საბაჟოდან მოდის ჩვენს ოფისში, ჩვენ გიბეჭდავთ დეკლარაციას და მიმღები პირი აწერს ხელს.`, `განბაჟების თანხა, გადახდის ბოლო ვადა და საქართველოს ხაზინის საბანკო
                    რეკვიზიტები მითითებულია დეკლარაციაში და მისი გადახდა შეგიძლიათ ნებისმიერ ბანკში.`, `რა დოკუმენტაციაა საჭირო ამანათის განსაბაჟებლად?`, `იმ შემთხვევაში, თუ ამანათი დაექვემდებარა განბაჟებას, მომხმარებელმა
                    ჩვენს საიტზე უნდა ატვირთოს ინვოისი/შესყიდვის ორდერი, სადაც ფიქსირდება მიმღების სახელი, გვარი, ოთახის ნომერი, ამანათის შიგთავსი და ჯამური ღირებულება.`,
                    `რა ხდება თუ მომხმარებელი დროულად არ გადაიხდის განბაჟების საფასურს?`, `ტვირთის განბაჟებაზე და დღგ-ს დროულად გადახდაზე პასუხისმგებელია მომხმარებელი.`,
                    `თუ დეკლარაციაში მითითებულ ვადაში არ გადაიხდით განბაჟების თანხას, საქართველოს კანონმდებლობით დამატებით დაგერიცხებათ საურავი.`, `რამდენია განბაჟების გადასახადი?`,
                    `საბაჟო გადასახადი შეადგენს პროდუქციის ღირებულების და ტრანსპორტირების ჯამის 18%-ს.`, `რატომ არის საჭირო ნივთის დეკლარირება ჩამოსვლამდე?`,
                    `იმ შემთხვევაში, თუ ამანათი არ იქნება დეკლარირებული მისი ჩამოსვლის მომენტში, იგი დაყოვნდება საბაჟოზე 8 სამუშაო დღის განმავლობაში.`, `იმ შემთხვევაში, თუ აღნიშნული 8 დღის განმავლობაში, თქვენს მიერ არ
                    მოხდება ამანათის დეკლარირება, ამანათი ავტომატურად გადაეცემა სახელმწიფოს შემდგომი განკარგვისთვის. გაითვალისწინეთ, თუ მომხმარებლის სახელზე საზღვარს კვეთს რამდენიმე ამანათი, ხოლო
                    დაუდეკლარირებელია მხოლოდ ნაწილი, საბაჟო დაუდეკლარირებელ ამანათებთან ერთად აჩერებს მომხმარებლის დადეკლარირებულ ამანათებსაც. 8 დღიანი პერიოდის გასვლის შემდეკ კი კანონის მიხედვით ყველა ამანათი
                    ერთად გადადის სახელმწიფოს მფლობელობაში.`, `რეალური მიმღების გარდა კიდევ ვის შეუძლია გაიტანოს განსაბაჟებელი ამანათი?`, `განსაბაჟბელი ამანათის გატანა, რეალური მიმღები გარდა, შეუძლია
                    ნოტარიულად დამოწმებულ მინდობილ პირს, აღნიშნული მინდობილობის ფილიალში/კურიერთან წარდგენის საფუძველზე.`, `*გაითვალისწინეთ:`, `(1) შემოსავლების სამსახურის ახალი წესების მიხედვით დეკლარირებაში ამანათის
                    მიმღების მიერ არასწორად მითითებული ღირებულების შემთხვევაში გათვალისწინებულია საჯარიმო სანქციები`
                ]
            },
            danger: {
                title: "აკრძალული ნივთები",
                content: [
                    `საქართველოს კანონმდებლობით აკრძალულია საფოსტო გზავნილით შემდეგი სახის ნივთების გამოგზავნა:`, `რეალური ფული, მონეტები ან ბანკნოტები;`,
                    `საბანკო და საკრედიტო ბარათები და სხვა ფასიანი ქაღალდები;`, `საკვები პროდუქცია;`, `ფეთქებადი ნივთიერებები;`, `მიწა და ნერგები მიწით;`, `პორნოგრაფიული სახის პროდუქცია;`,
                    `ცოცხალი ცხოველები;`, `ნებისმიერი სახის ნარკოტიკი, ნარკოტიკული ნივთიერებების შემცველი პროდუქცია;`, `ნარკოტიკული ან ფსიქოტროპული ნივთიერებების შემცველი მცენარეები ან მათი თესლი;`,
                    `თამბაქო და თამბაქოს შემცველი ნივთები;`, `ფსიქოტროპული ნივთიერებები;`, `მცენარეების თესლები;`, `აკრძალული ნივთბი ჩინეთიდან:`,
                    `იარაღი, სამიზნე, იარაღის ნაწილები, მაგნიტი, სანთელი, სანთებელა (მათ შორის ელექტრო სანთებელა), ფხვნილი, დაპრესილი ფხვნილი, კბილის პასტები, თვალის
                    ჩრდილები, დანა, მაკრატელი და მსგავსი ტიპის ბასრი საგნები, სათამაშო იარაღი, ელექტრო სიგარეტი, ფავერ ბანკი, აკუმულატორი, სითხეები, საღებავი, ფლომასტერი,
                    მცენარეები, სამედიცინო დანიშნულების ტვირთები, ელემენტები (დასაშვებია ელემენტის ჩამოტანა მხოლოდ იმ შემთხვევაში, თუ იგი მოყვება ნივთს. მაგალითად -
                    მობილურს) და კოსმეტიკური საშუალებები როგორიცაა: ფრჩხილის ლაქი, აცეტონი, კრემები და სხვა. ფეთქებადსაშიში და აალებადი შეფუთვის მქონე პროდუქცია. მათ
                    შორის: სპრეი, პულვერიზატორი, დეზადორი, აეროზოლი და სუნამო`
                ]
            },
            trustedPersons: {
                title: "მინდობილი პირები",
                content: [
                    `ვინ არის მინდობილი პირი?`, `მინდობილი პირი არის ადამიანი, ვინც თქვენს მაგივრად გაიტანს თქვენს ამანათს. მინდობილი პირის დამატება ხდება წინასწარ, თქვენი პროფილიდან,
                    კონკრეტულ ამანათზე მონიშვნით.`, `რა არის საჭირო, რომ ამანათი მინდობილმა პირმა გაიტანოს?`,
                    `თქვენი ანგარიშიდან, კონკრეტულ ამანათზე აუცილებელია მითითებული იყოს, რომ ამანათს გაიტანს მინდობილი პირი.`, `მინდობილმა პირმა თან უნდა იქონიოს საკუთარი პირადობის, პასპორტის ან
                    მართვის მოწმობის ორიგინალი დამადასტურებელი დოკუმენტი.`, `შეიძლება თუ არა მინდობილმა პირმა გაიტანოს განსაბაჟებელი ამანათი?`, `არა. მინდობილი პირის ფუნქციით ვერ ისარგებლებთ განსაბაჟებელი ამანათის
                    შემთხევაში. განსაბაჟებელი ამანათის სხვა პიროვნების მიერ გატანა ხდება მხოლოდ ნოტარიულად დამოწმებული მინდობილობის საფუძველზე.`
                ]
            },
            faq: {
                title: "ხშირად დასმული კითხვები",
                content: [
                    `რას ნიშნავს ბილინგ (Billing) და შიპინგ (Shipping) მისამართი?`, `ბილინგის მისამართი (billing address), არის მისამართი, რომელიც თქვენ გაქვთ მითითებული ბანკში პლასტიკური ბარათის მიღებისას.`,
                    `შიფინგის მისამართი (shipping Address), ნიშნავს მისამართს სადაც გსურთ, რომ მოხდეს თქვენს მიერ ნაყიდი ნივთის მიტანა, ანუ ჩინეთში ჩვენს მიერ გამოყოფილი საწყობის მისამართი, საიდანაც, უკვე ჩვენ მოვახდენთ მის ტრანსპორტირებას თქვენამდე.`, `რომელ უცხოეთის ინტერნეტ მაღაზიებში შემიძლია შოპინგი?`,
                    `თქვენ შეგიძლიათ შეიძინოთ ნივთები ნებისმიერ ინტერნეტ მაღაზიაში ჩინეთის მასშტაბით, რომელიც მიიღებს ჩინეთის ჩვენ მისამართს, უზრუნველყოფს ამანათის მიტანას ჩვენი საწყობის მისამართზე და მიიღებს ქართულ ბარათს.`,
                    `როგორ გავიგო თუ რამდენს იწონის ნივთი მის ყიდვამდე?`, `უმრავლესობა მაღაზიები უთითებს ყველა პროდუქციას მის წონას, მიახლოებით მაღაზიის მიერ მოწოდებული მონაცემებით ყოველთვის შეგიძლიათ წონის გამოთვლა,
                    თუმცა დაზუსტებულ ინფორმაციას მიიღებთ მას შემდეგ რაც ამანათები შევა ჩინეთის ჩვენს საწყობში.`, `ჩემი ამანათი მივიდა საწყობში მაგრამ არ აისახა სისტემაში რისი ბრალია?`,
                    `ამანათი სისტემაში აისახება მისვლიდან მაქსიმუმ 2-3 სამუშაო დღეში. როდესაც ამანათის სისტემაში ასახვა არ ხდება არსებობს რამოდენიმე მიზეზი:`, `მომხმარებელმა/გამყიდველმა არასწორად მიუთითა პირადი საფოსტო ყუთის ნომერი`,
                    `ამანათი საერთოდ არ მისულა ჩვენამდე რადგან არასწორად იყო მითითებული მისამართი`, `ამანათს არასწორად/არასრულად აწერია მიმღების მონაცემები (ასეთ შემთხვევაში მოხდება მიმღების მოძიება ჩვენს მიერ, რასაც დასჭირდება რამდენიმე დღე)`,
                    `კურიერმა ამანათი მიიტანა არასწორ მისამართზე`, `ჩემი ამანათი არ აისახა ჩემს ოთახში, გავიდა მისვლიდან 2-3 სამუშაო დღე, როგორ მოვიქცე?`, `ელ-ფოსტაზე info@simplepost.ge გამოგვიგზავნეთ შემდეგი ინფორმაცია:`,
                    `ამანათის თრექინგ ნომერი;`, `ამანათის ინვოისი (აუცილებელია ერთ გვერდზე ფიქსირდებოდეს: მომხმარებლის სახელი და გვარი, ოთახის ნომერი და ამანათის თრექინგ ნომერი);`,
                    `მიტანის დამადასტურებელი სქრინი`, `დამატებითი დოკუმენტაციის წარმოდგენის საჭიროების შემთხვევაში დაგიკავშირდებით.`, `ამანათი არ მოვიდა საწყობში, ვის უნდა მივმართო?`,
                    `პირველ რიგში უნდა დაუკავშირდეთ გამომგზავნ ინტერნეტ მაღაზიას და ამანათის კოდის (Tracking Number) საშუალებით გაარკვიოთ გამოგზავნის ზუსტი თარიღი.`, `ამანათის ჩვენს საწყობში მოსვლამდე, ჩვენ მას ვერ ვაკონტროლებთ და შესაბამისად არ
                    ვიღებთ პასუხისმგებლობას მის დაკარგვაზე. ასეთ შემთხვევაში უნდა დაუკავშირდეთ გამომგზავნ ინტერნეტ მაღაზიას, რათა მოახდინონ ზარალის ანაზღაურება.`, `მოწმდება თუ არა გამოგზავნილი ამანათი?`,
                    `გამოგზავნილი ამანათი გადის აუცილებელ სკანირებას. იმ შემთხვევაში, თუ ამანათში აღმოჩნდა პროდუქცია, რომლის ტრანსპორტირებაც აკრძალულია ის იგზავნება უკან გამყიდველთან ან დაექვემდებარება განადგურებას.`,
                    `სად შემიძლია ამანათის ტრანსპორტირების თანხის გადახდა?`, `პლასტიკური ბარათით ჩვენი საიტის საშუალებით - საკომისიო 0%`, `პლასტიკური ბარათით გადახდისას ჩვენი საიტი მოგცემთ შესაძლებლობას აირჩიოთ იმ
                    ბანკის პლათფორმა, რომლის ბარათითაც აპირებთ გადახდას.`, `თუ გაქვთ საქართველოს ბანკის ბარათი, მაშინ აირჩიეთ საქართველოს ბანკი. თუ გაქვთ თიბისი ბანკის ბარათი, მაშინ აირჩიეთ თიბისი ბანკი.`,
                    `პლასტიკური ბარათით ჩვენს ოფისში ამანათის გატანისას`
                ]
            }
        }
    }
}

export default language