import {useEffect, useState} from "react";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import {Table} from "react-bootstrap";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import OutsideClickHandler from "react-outside-click-handler";
import {Button, Pagination, Stack} from "@mui/material";
import core from "../../../core";
import EditDelivery from "../Components/EditDelivery";
import {useSelector} from "react-redux";
import language from "../../../store/language";

const Delivery = () => {
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [keyword, setKeyword] = useState("");
    const [position, setPosition] = useState("");
    const [data, setData] = useState([]);
    const [searchFieldValue, setSearchFieldValue] = useState("");
    const [active, setActive] = useState({});
    const [total, setTotal] = useState(0);
    const [optionsDropdown, setOptionsDropdown] = useState({show: false, id: 0});
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showEditDelivery, setShowEditDelivery] = useState(false);
    const lang = useSelector((state) => state.user.language);

    const loadDelivery = () => {
        axios(`/api/admin/delivery?limit=${limit}&page=${page}&keyword=${keyword}&status=${position}`)
            .then((res) => {
                const parcels = res.data.data;
                setData([...parcels]);
                setTotal(res.data.total);
            })
            .catch((err) => {

            })
    }

    useEffect(loadDelivery, [limit, page, keyword, position]);

    return (
        <>
            <h3 style={{marginBottom: "35px"}}>Delivery</h3>
            <div style={{width: "100%"}}>
                <div className={"data-table-header"}>
                    <div className={"search-field-container"}>
                        <input type={"text"}
                               onKeyUp={(e) => {
                                   if (e.keyCode === 13) {
                                       setKeyword(searchFieldValue);
                                   }
                               }}
                               onChange={(e) => setSearchFieldValue(e.target.value)}
                               placeholder={"Enter Tracking number"}
                               className={"search-field"}/>
                        <button><SearchIcon onClick={() => setKeyword(searchFieldValue)} style={{color: "white"}}/>
                        </button>
                    </div>
                    <div style={{display: "flex"}}>
                        <select style={{maxWidth: "130px"}} className={"form-control"} value={position}
                                onChange={(e) => setPosition(e.target.value)}>
                            <option value={""}>Select status</option>
                            <option value={"Pending"}>Pending</option>
                            <option value={"On way"}>On way</option>
                            <option value={"Delivered"}>Delivered</option>
                        </select>
                    </div>
                </div>
                <Table responsive style={{width: "100%"}} className={"data-table"}>
                    <thead>
                    <tr>
                        <th>თრექინგი</th>
                        <th>ოთახის #</th>
                        <th>მისამართი</th>
                        <th>მიმღების პ.ნ.</th>
                        <th>სტატუსი</th>
                        <th>გადახდილია</th>
                        <th>ფასი</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        data.map((p, i) => {
                            return (
                                <tr className={`${i % 2 === 1 ? 'odd-row' : 'even-row'} table-rows`}
                                    key={p.id}>
                                    <td style={{paddingLeft: "5px"}}
                                        title={p.trackings.replace("[", "").replace("]", "").replaceAll('"', "")}>{(p.trackings.replace("[", "").replace("]", "").replaceAll('"', "")).slice(0, 25)}{(p.trackings.replace("[", "").replace("]", "").replaceAll('"', "")).length > 25 ? "..." : ""}</td>
                                    <td>{p.receiver_user_id}</td>
                                    <td>{p.address}</td>
                                    <td>{p.receiver_id_number}</td>
                                    <td>{p.status}</td>
                                    <td>{p.is_payed ? <img style={{width: "20px"}}
                                                           src={"https://usdelivery.ge/static/image/check.png"}
                                                           alt={"yes"}/> : <img style={{width: "20px"}}
                                                                                src={"https://usdelivery.ge/static/image/cross.png"}
                                                                                alt={"yes"}/>}</td>
                                    <td>{p.price}</td>
                                    <td>
                                        <div className={"table-actions-section"}>
                                            <button className={"table-actions-button"} onClick={() => {
                                                setOptionsDropdown({show: true, id: p.id});
                                            }}><MoreVertIcon/></button>
                                            {(optionsDropdown.show && optionsDropdown.id === p.id) &&
                                                <div className={"options-dropdown-box"}>
                                                    <OutsideClickHandler onOutsideClick={() => {
                                                        setOptionsDropdown({show: false, id: 0})
                                                    }}>
                                                        <ul className={"table-options-list"}>
                                                            <li onClick={() => {
                                                                setShowEditDelivery(true);
                                                                setActive({...p});
                                                                setOptionsDropdown({show: false, id: 0});
                                                            }}>რედაქტირება</li>
                                                        </ul>
                                                    </OutsideClickHandler>
                                                </div>}

                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                    }
                    {
                        data.length === 0 && (
                            <tr>
                                <td colSpan={9} style={{padding: "12px 0"}}>
                                    <p style={{width: "100%", textAlign: "center"}}>{language[lang].myPage.myParcels.recordsNotFound}</p>
                                </td>
                            </tr>
                        )
                    }
                    </tbody>
                </Table>
                <div className={"pagination-container"}>
                    <p>Total: {total}</p>
                    <Stack spacing={2}>
                        <Pagination count={Math.ceil(total / limit)} size="large" page={page} onChange={(e, v) => setPage(v)}/>
                    </Stack>
                </div>
            </div>
            <EditDelivery data={active} show={showEditDelivery} setShow={setShowEditDelivery} loadDelivery={loadDelivery}/>
        </>
    )
}

export default Delivery