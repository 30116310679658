import {Zoom} from "react-awesome-reveal";
import language from "../../store/language";
import {useSelector} from "react-redux";

const Services = () => {
    const lang = useSelector((state) => state.user.language);

    return(
        <div className={"main-page-services-container"}>
            <h2 className={"service-title"}>{language[lang].mainPage.services.title}</h2>
            <h4 className={"services-subtitle"}>{language[lang].mainPage.services.description}</h4>
            <div className={"show-services-list"}>
                <Zoom>
                    <div className={"service-item"}>
                        <img src={"/static/icons/service-01.png"} alt={"service 01"}/>
                        <h3>{language[lang].mainPage.services.service[0].title}</h3>
                        <h6>{language[lang].mainPage.services.service[0].description}</h6>
                    </div>
                </Zoom>
                <Zoom>
                    <div className={"service-item service-item-with-borders"}>
                        <img src={"/static/icons/parcel-insurance.png"} alt={"service 01"}/>
                        <h3>{language[lang].mainPage.services.service[1].title}</h3>
                        <h6>{language[lang].mainPage.services.service[1].description}</h6>
                    </div>
                </Zoom>
                <Zoom>
                    <div className={"service-item"}>
                        <img src={"/static/icons/international-delivery.png"} alt={"service 01"}/>
                        <h3>{language[lang].mainPage.services.service[2].title}</h3>
                        <h6>{language[lang].mainPage.services.service[2].description}</h6>
                    </div>
                </Zoom>
            </div>
        </div>
    )
}

export default Services