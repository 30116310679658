import language from "../store/language";
import {useSelector} from "react-redux";
import {useEffect} from "react";
import {Link} from "react-router-dom";

const DeliveryService = ({setIsMyPage, windowSize}) => {
    const lang = useSelector((state) => state.user.language);
    useEffect(() => {
        setIsMyPage(false)
    }, [])
    return(
        <div className={"information-pages-container"}>
            {windowSize < 880 && (
                <>
                    <div className="horizontal-scroll-menu">
                        <Link to="/info/services/online-parcels">Online Parcels</Link>
                        <Link to="/info/services/delivery-services">Delivery Service</Link>
                        <Link to="/info/useful-information/how-to-buy">How To Buy</Link>
                        <Link to="/info/useful-information/customs-processes">Customs Procedure</Link>
                        <Link to="/info/useful-information/danger-items">Danger Items</Link>
                        <Link to="/info/useful-information/trustees">Trustees</Link>
                        <Link to="/info/useful-information/faq">FAQ</Link>
                    </div>
                </>
            )}
            <div className={"useful-information-banner"}
                 style={{backgroundImage: `url('/static/icons/services-banner-gradient.jpg')`}}>
                <h5>{language[lang].services.delivery.title}</h5></div>
            <p className={"information-page-subtitles"}>{language[lang].services.delivery.content[0].title}</p>
            <p className={"information-page-text"}>{language[lang].services.delivery.content[0].content[0]}</p>
            <p className={"information-page-text"}>{language[lang].services.delivery.content[0].content[1]}</p>
            <p className={"information-page-subtitles"}>{language[lang].services.delivery.content[1].title}</p>
            <p className={"information-page-text"}>{language[lang].services.delivery.content[1].content[0]}</p>
            <p className={"information-page-text"} style={{fontWeight: "bold"}}>{language[lang].services.delivery.content[1].content[1]}</p>
            <p className={"information-page-subtitles"}>{language[lang].services.delivery.content[1].content[2]}</p>
            <p className={"information-page-subtitles"}>{language[lang].services.delivery.content[2].title}</p>
            <p className={"information-page-text"}>{language[lang].services.delivery.content[2].content[0]}</p>
            <p className={"information-page-text"}>{language[lang].services.delivery.content[2].content[1]}</p>
            <p className={"information-page-text"}>{language[lang].services.delivery.content[2].content[2]}</p>
            <p className={"information-page-subtitles"}>{language[lang].services.delivery.content[3].title}</p>
            <p className={"information-page-text"}>{language[lang].services.delivery.content[3].content[0]}</p>
            <p className={"information-page-text"}>{language[lang].services.delivery.content[3].content[1]}</p>
        </div>
    )
}

export default DeliveryService