import {Modal} from "react-bootstrap";
import {useEffect, useState} from "react";
import {Button} from "@mui/material";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import { notificationActions } from "../../../store/notification";

const CreateNewAdmin = ({show, setShow, loadAdmins, active, action}) => {
    const [createAdminForm, setCreateAdminForm] = useState({
        name: "",
        surname: "",
        email: "",
        username: "",
        password: "",
        phone: "",
        company_number: "",
        position: "",
        role: ""
    });
    const dispatch = useDispatch();
    const lang = useSelector((state) => state.user.language)

    useEffect(() => {
        if(action === "edit"){
            setCreateAdminForm({
                name: active.name,
                surname: active.surname,
                email: active.email,
                username: active.username,
                password: "",
                phone: active.phone,
                company_number: active.company_number,
                position: active.position,
                role: active.role,
                is_active: active.is_active
            })
        }else{
            setCreateAdminForm({
                name: "",
                surname: "",
                email: "",
                username: "",
                password: "",
                phone: "",
                company_number: "",
                position: "",
                role: ""
            });
        }
    }, [show]);

    const addNewAdmin = (e) => {
        e.preventDefault();
        axios.post('/api/admins', createAdminForm)
            .then((res) => {
                loadAdmins();
                setShow(false);
            })
            .catch((err) => {
                dispatch(notificationActions.setNotification({show: true, message: err.response.data.message[lang], type: "error"}));
                setTimeout(() => {
                    dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                }, 4000)
            })
    }

    const updateAdmin = (e) => {
        e.preventDefault();
        axios.put(`/api/admins/${active.id}`, createAdminForm)
            .then(() => {
                loadAdmins();
                setShow(false);
            })
            .catch(() => {

            })
    }

    return (
        <Modal show={show} onHide={() => setShow(false)} centered size={"lg"}>
            <Modal.Header><h4>{action === "add" ? "Create new admin" : `Edit admin ${active.username}`}</h4></Modal.Header>
            <Modal.Body>
                <form onSubmit={action === "add" ? addNewAdmin : updateAdmin}>
                    <div className={"row"}>
                        <div className={"col-md-6"}>
                            <div className={"form-group"}>
                                <label className={"form-label"}>Name</label>
                                <input
                                    typeof={"text"}
                                    required
                                    onChange={(e) => setCreateAdminForm((prev) => ({...prev, name: e.target.value}))}
                                    value={createAdminForm.name}
                                    className={"form-control"}
                                    placeholder={"Enter admin name"}
                                />
                            </div>
                            <div className={"form-group mt-4"}>
                                <label className={"form-label"}>Surname</label>
                                <input
                                    typeof={"text"}
                                    required
                                    onChange={(e) => setCreateAdminForm((prev) => ({...prev, surname: e.target.value}))}
                                    value={createAdminForm.surname}
                                    className={"form-control"}
                                    placeholder={"Enter admin surname"}
                                />
                            </div>
                            <div className={"form-group mt-4"}>
                                <label className={"form-label"}>Email address</label>
                                <input
                                    typeof={"email"}
                                    required
                                    onChange={(e) => setCreateAdminForm((prev) => ({...prev, email: e.target.value}))}
                                    value={createAdminForm.email}
                                    className={"form-control"}
                                    placeholder={"Enter admin email address"}
                                />
                            </div>
                            <div className={"form-group mt-4"}>
                                <label className={"form-label"}>Username</label>
                                <input
                                    typeof={"text"}
                                    required
                                    onChange={(e) => setCreateAdminForm((prev) => ({
                                        ...prev,
                                        username: e.target.value
                                    }))}
                                    value={createAdminForm.username}
                                    className={"form-control"}
                                    placeholder={"Enter admin username"}
                                />
                            </div>
                            <div className={"form-group mt-4"}>
                                <label className={"form-label"}>Password</label>
                                <input
                                    typeof={"password"}
                                    required={action === "add"}
                                    onChange={(e) => setCreateAdminForm((prev) => ({
                                        ...prev,
                                        password: e.target.value
                                    }))}
                                    value={createAdminForm.password}
                                    className={"form-control"}
                                    placeholder={"Enter admin password"}
                                />
                            </div>
                        </div>
                        <div className={"col-md-6"}>
                            <div className={"form-group"}>
                                <label className={"form-label"}>Phone</label>
                                <input
                                    required
                                    typeof={"text"}
                                    onChange={(e) => setCreateAdminForm((prev) => ({...prev, phone: e.target.value}))}
                                    value={createAdminForm.phone}
                                    className={"form-control"}
                                    placeholder={"Enter admin phone"}
                                />
                            </div>
                            <div className={"form-group mt-4"}>
                                <label className={"form-label"}>Company number</label>
                                <input
                                    required
                                    typeof={"email"}
                                    onChange={(e) => setCreateAdminForm((prev) => ({
                                        ...prev,
                                        company_number: e.target.value
                                    }))}
                                    value={createAdminForm.company_number}
                                    className={"form-control"}
                                    placeholder={"Enter admin email company number"}
                                />
                            </div>
                            <div className={"form-group mt-4"}>
                                <label className={"form-label"}>Position</label>
                                <select value={createAdminForm.position}
                                        className={"form-control"}
                                        onChange={(e) => setCreateAdminForm((prev) => ({
                                            ...prev,
                                            position: e.target.value
                                        }))}>
                                    <option value={""}>Select position</option>
                                    <option value={"ოპერატორი"}>ოპერატორი</option>
                                    <option value={"ჩინეთის საწყობი"}>ჩინეთის საწყობი</option>
                                    <option value={"საწყობის თანამშრომელი"}>საწყობის თანამშრომელი</option>
                                    <option value={"დეკლარანტი"}>დეკლარანტი</option>
                                    <option value={"ბუღალტერი"}>ბუღალტერი</option>
                                    <option value={"კურიერი"}>კურიერი</option>
                                </select>
                            </div>
                            <div className={"form-group mt-4"}>
                                <label className={"form-label"}>Role</label>
                                <select value={createAdminForm.role}
                                        className={"form-control"}
                                        onChange={(e) => setCreateAdminForm((prev) => ({
                                            ...prev,
                                            role: e.target.value
                                        }))}>
                                    <option value={""}>Select role</option>
                                    <option value={"admin"}>Admin</option>
                                    <option value={"sadmin"}>Super Admin</option>
                                </select>
                            </div>
                            {action === "edit" && <div className={"form-group mt-4"}>
                                <label className={"form-label"}>Active</label>
                                <input type={"checkbox"} onChange={(e) => setCreateAdminForm((prev) => ({
                                    ...prev,
                                    is_active: e.target.checked
                                }))} className={"form-check"} defaultChecked={active.is_active}/>
                            </div>}
                        </div>
                        <div className={"modal-footer"} style={{marginTop: "30px"}}>
                            <Button type={"submit"} variant={"contained"}>Submit</Button>
                            <Button onClick={() => setShow(false)} variant={"outlined"}
                                    style={{marginLeft: "20px"}}>Cancel</Button>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default CreateNewAdmin