import {Zoom} from "react-awesome-reveal";
import WarehouseIcon from '@mui/icons-material/Warehouse';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import {useSelector} from "react-redux";
import language from "../../store/language";

const Flight = ({data, width}) => {
    const lang = useSelector((state) => state.user.language);

    return(
        <Zoom>
            <div className={"main-page-flight-box"}>
                {/*<img src={"/static/icons/flights-icon.png"} className={"parcel-flights-icon"} alt={"flight"}/>*/}
                <div className={"d-flex justify-content-center"}>
                    {
                        data.status === "warehouse" && <WarehouseIcon style={{fontSize: width < 640 ? "50px" : "80px", color: "#03A5BC"}} className={"parcel-flights-icon"}/>
                    }
                    {
                        data.status === "on way" && <FlightTakeoffIcon style={{fontSize: width < 640 ? "50px" : "80px", color: "#03A5BC"}} className={"parcel-flights-icon"}/>
                    }
                </div>
                <h5 style={{fontWeight: "bold"}}>{language[lang].mainPage.flights.flightId}: {data.flight_id}</h5>
                <p className={"flights-box-text"}>{language[lang].mainPage.flights.status}: {language[lang].mainPage.flights[data.status.replace(" ", "_")]}</p>
                <p className={"flights-box-text"}>{language[lang].mainPage.flights.country}: {language[lang].mainPage.flights[data.country]}</p>
                <div className={"flights-dates"}>
                    <div className={"flight-date-mobile"}>
                        <img src={"/static/icons/plane.png"} className={"take-off-icon"} alt={"plane"}/>
                        <p>{data.take_off_date == null ? language[lang].mainPage.flights.defined : data.take_off_date}</p>
                    </div>
                    <div>
                        <img src={"/static/icons/plane.png"} className={"take-off-icon"} alt={"plane"}
                             style={{transform: "rotate(45deg)"}}/>
                        <p>{data.estimate_arrival_date}</p>
                    </div>
                </div>
            </div>
        </Zoom>
    )
}

export default Flight