import language from "../store/language";
import {useSelector} from "react-redux";
import {useEffect} from "react";
import {Link} from "react-router-dom";

const DangerItems = ({setIsMyPage, windowSize}) => {
    const lang = useSelector((state) => state.user.language);
    useEffect(() => {
        setIsMyPage(false)
    }, [])
    return(
        <div className={"information-pages-container customs"}>
            {windowSize < 880 && (
                <>
                    <div className="horizontal-scroll-menu">
                        <Link to="/info/services/online-parcels">Online Parcels</Link>
                        <Link to="/info/services/delivery-services">Delivery Service</Link>
                        <Link to="/info/useful-information/how-to-buy">How To Buy</Link>
                        <Link to="/info/useful-information/customs-processes">Customs Procedure</Link>
                        <Link to="/info/useful-information/danger-items">Danger Items</Link>
                        <Link to="/info/useful-information/trustees">Trustees</Link>
                        <Link to="/info/useful-information/faq">FAQ</Link>
                    </div>
                </>
            )}
            <div className={"useful-information-banner"}
                 style={{backgroundImage: `url('/static/icons/banner-gradient.jpg')`}}>
                <h5>{language[lang].usefulInformation.danger.title}</h5></div>
            <p className={"information-page-subtitles"}>{language[lang].usefulInformation.danger.content[0]}</p>
            <ul className={"ul-level-one"}>
                <li>{language[lang].usefulInformation.danger.content[1]}</li>
                <li>{language[lang].usefulInformation.danger.content[2]}</li>
                <li>{language[lang].usefulInformation.danger.content[3]}</li>
                <li>{language[lang].usefulInformation.danger.content[4]}</li>
                <li>{language[lang].usefulInformation.danger.content[5]}</li>
                <li>{language[lang].usefulInformation.danger.content[6]}</li>
                <li>{language[lang].usefulInformation.danger.content[7]}</li>
                <li>{language[lang].usefulInformation.danger.content[8]}</li>
                <li>{language[lang].usefulInformation.danger.content[9]}</li>
                <li>{language[lang].usefulInformation.danger.content[10]}</li>
                <li>{language[lang].usefulInformation.danger.content[11]}</li>
                <li>{language[lang].usefulInformation.danger.content[12]}</li>
            </ul>
            <p className={"information-page-subtitles"}>{language[lang].usefulInformation.danger.content[13]}</p>
            <p className={"information-page-text"}>{language[lang].usefulInformation.danger.content[14]}</p>
        </div>
    )
}

export default DangerItems