import {useEffect, useState} from "react";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import {Modal, Table} from "react-bootstrap";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import OutsideClickHandler from "react-outside-click-handler";
import {Button, Pagination, Stack} from "@mui/material";
import core from "../../../core";
import ViewParcel from "../../../components/MyPage/ViewParcel";
import language from "../../../store/language";
import {useSelector, useDispatch} from "react-redux";
import DialogBox from "../../../components/DialogBox";
import AddNewParcel from "../Components/AddNewParcel";
import BlockParcel from "../Components/BlockParcel";
import { notificationActions } from "../../../store/notification";

const Parcels = () => {
    const dispatch = useDispatch();
    const lang = useSelector((state) => state.user.language)
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [keyword, setKeyword] = useState("");
    const [position, setPosition] = useState("all");
    const [data, setData] = useState([]);
    const [searchFieldValue, setSearchFieldValue] = useState("");
    const [active, setActive] = useState({});
    const [total, setTotal] = useState(0);
    const [optionsDropdown, setOptionsDropdown] = useState({show: false, id: 0});
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showViewParcel, setShowViewParcel] = useState(false);
    const [showTakenParcelModal, setShowTakenParcelModal] = useState(false);
    const [showAddParcelModal, setShowAddParcelModal] = useState(false);
    const [action, setAction] = useState("add");
    const [showBlockParcel, setShowBlockParcel] = useState(false);
    const [declarationStatus, setDeclarationStatus] = useState("all");
    const [showCustomStatus, setShowCustomStatus] = useState(false);
    const [customStatusForm, setCustomStatusForm] = useState("");
    const [shelfIdForm, setShelfIdForm] = useState("");
    const [showShelfId, setShowShelfId] = useState(false);
    const [statusColor, setStatusColor] = useState("all");
    const [selectedParcels, setSelectedParcels] = useState([]);
    const [showLogs, setShowLogs] = useState(false);
    const [showOnlyProblemic, setShowOnlyProblemic] = useState(0);
    const [showSetPayed, setShowSetPayed] = useState(false);

    const loadParcels = () => {
        axios(`/api/admin/parcels?limit=${limit}&page=${page}&keyword=${keyword}&onlyProblem=${showOnlyProblemic}&status=${position}&declaration=${declarationStatus}&statusColor=${statusColor}`)
            .then((res) => {
                const parcels = res.data.data;
                setData([...parcels]);
                setTotal(res.data.total);
            })
            .catch((err) => {
                dispatch(notificationActions.setNotification({show: true, message: err.response.data.message[lang], type: "error"}));
                setTimeout(() => {
                    dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                }, 4000)
            })
    }

    useEffect(loadParcels, [limit, page, keyword, position, declarationStatus, statusColor, showOnlyProblemic]);

    const handlers = {
        markAsTaken(){
            axios.put(`/api/admin/parcels/status/taken`, {tracking: selectedParcels})
                .then(() => {
                    loadParcels();
                    setShowTakenParcelModal(false);
                    setSelectedParcels([]);
                })
                .catch((err) => {
                    dispatch(notificationActions.setNotification({show: true, message: err.response.data.message[lang], type: "error"}));
                    setTimeout(() => {
                        dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                    }, 4000)
                })
        },
        deleteParcel(){
            axios.delete(`/api/parcels/${active.tracking}`)
                .then(() => {
                    loadParcels();
                    setShowDeleteDialog(false);
                })
                .catch((err) => {
                    dispatch(notificationActions.setNotification({show: true, message: err.response.data.message[lang], type: "error"}));
                    setTimeout(() => {
                        dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                    }, 4000)
                })
        },
        changeCustomStatus(){
            axios.put(`/api/parcels/${active.tracking}/change-custom-status`, {status: customStatusForm})
                .then(() => {
                    setShowCustomStatus(false);
                    loadParcels();
                })
                .catch((errors) => {
                    dispatch(notificationActions.setNotification({show: true, message: "Something went wrong", type: "error"}));
                    setTimeout(() => {
                        dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                    }, 4000)
                })
        },
        changeShelfNumber(){
            axios.put(`/api/parcels/${active.tracking}/shelf-id`, {shelf_id: shelfIdForm})
                .then(() => {
                    setShowShelfId(false);
                    loadParcels();
                })
                .catch((err) => {
                    dispatch(notificationActions.setNotification({show: true, message: "Something went wrong", type: "error"}));
                    setTimeout(() => {
                        dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                    }, 4000)
                })
        },
        setAsPayed(){
            axios.put(`/api/parcels/${active.tracking}/is-payed`, {shelf_id: shelfIdForm})
                .then(() => {
                    setShowSetPayed(false);
                    loadParcels();
                })
                .catch((err) => {
                    dispatch(notificationActions.setNotification({show: true, message: err.response.data.message.en, type: "error"}));
                    setTimeout(() => {
                        dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                    }, 4000)
                })
        }
    }

    return (
        <>
            <h3 style={{marginBottom: "35px"}}>Parcels</h3>
            <div style={{width: "100%"}}>
                <div className={"data-table-header"}>
                    <div>
                        <input type="checkbox" id="only-problemic" onChange={(e) => {
                            setShowOnlyProblemic((prev) => {
                                if(prev == 0){
                                    return 1
                                }else{
                                    return 0
                                }
                            });
                        }} />
                        <label style={{marginLeft: "5px"}} htmlFor="only-problemic">პრობლემურები</label>
                    </div>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <select style={{maxWidth: "160px", marginRight: "20px"}} className={"form-control"}
                                value={statusColor}
                                onChange={(e) => setStatusColor(e.target.value)}>
                            <option value={"all"}>სტატუსის ფერი</option>
                            <option value={"clean"}>მწვანე</option>
                            <option value={"need_clearance"}>წითელი, განსაბაჟებელი</option>
                            <option value={"stopped_on_custom"}>ყვითელი, საბაჟოზე გაჩერებული</option>
                            <option value={"undeclared"}>ცისფერი, დასადეკლარირებელი</option>
                            <option value={"id_number_problem"}>ლურჯი, გაჩერებული პ/ნ გამო</option>
                        </select>
                        <select style={{maxWidth: "160px"}} className={"form-control"} value={declarationStatus}
                                onChange={(e) => setDeclarationStatus(e.target.value)}>
                            <option value={"all"}>დეკლარირება</option>
                            <option value={"1"}>დეკლარირებული</option>
                            <option value={"0"}>დასადეკლარირებელი</option>
                        </select>
                        <Button disabled={selectedParcels.length === 0} style={{marginLeft: "20px"}} onClick={() => {
                            setShowTakenParcelModal(true);
                        }} variant={"contained"}>გატანილია</Button>
                    </div>
                </div>
                <div className={"data-table-header"}>
                    <div className={"search-field-container"}>
                        <input type={"text"}
                               onKeyUp={(e) => {
                                   if (e.keyCode === 13) {
                                       setKeyword(searchFieldValue);
                                   }
                               }}
                               onChange={(e) => setSearchFieldValue(e.target.value)}
                               placeholder={"Enter keyword"}
                               className={"search-field"}/>
                        <button><SearchIcon onClick={() => setKeyword(searchFieldValue)} style={{color: "white"}}/>
                        </button>
                    </div>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <select style={{maxWidth: "130px"}} className={"form-control"} value={position}
                                onChange={(e) => setPosition(e.target.value)}>
                            <option value={"all"}>სტატუსი</option>
                            <option value={"predeclared"}>წინასწარი დეკლარირება</option>
                            <option value={"warehouse"}>საწყობში</option>
                            <option value={"on way"}>გზაში</option>
                            <option value={"arrived"}>ჩამოსული</option>
                            <option value={"taken"}>გატანილი</option>
                        </select>
                        <Button style={{marginLeft: "20px"}} onClick={() => {
                            setAction("add");
                            setShowAddParcelModal(true);
                        }} variant={"contained"}>დამატება</Button>
                    </div>
                </div>
                <Table responsive style={{width: "100%"}} className={"data-table"}>
                    <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th>თრექინგი</th>
                        <th>ოთახის#</th>
                        <th>მიმღები</th>
                        <th>თარიღი</th>
                        <th>რეისი</th>
                        <th>სტატუსი</th>
                        <th>წონა</th>
                        <th>დავალ.</th>
                        <th>გად.</th>
                        <th>დეკლ.</th>
                        <th>ნომერი</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        data.map((p, i) => {
                            let color = "black";
                            if(p.block_status === "prohibition") color = "red";
                            if(p.block_status === "stopped") color = "orange";
                            let parcelColor = "green";
                            if(p.custom_process_status === "need_clearance") parcelColor = "red";
                            if(p.custom_process_status === "stopped_on_custom") parcelColor = "yellow";
                            if(p.custom_process_status === "undeclared") parcelColor = "#61b3ce";
                            if(p.custom_process_status === "id_number_problem") parcelColor = "blue";

                            return (
                                <tr className={`${i % 2 === 1 ? 'odd-row' : 'even-row'} table-rows`}
                                    key={p.tracking}>
                                    <td>
                                        <input type="checkbox" checked={selectedParcels.includes(p.tracking)} onChange={(e) => {
                                            if(e.target.checked && color === "black" && p.status === "arrived"){
                                                setSelectedParcels((prev) => ([...prev, p.tracking]))
                                            }else if(color != "black" || p.status != "arrived"){
                                                alert("Parcel blocked or is not arrived")
                                            }else{
                                                const removeParcel = selectedParcels.filter((t) => t !== p.tracking);
                                                setSelectedParcels(removeParcel);
                                            }
                                        }} />
                                    </td>
                                    <td><div className="parcel-color-code" style={{background: parcelColor}}></div></td>
                                    <td style={{paddingLeft: "5px", color: color}}>{p.tracking}</td>
                                    <td>{p.user_id}</td>
                                    <td>{p.receiver}</td>
                                    <td>{p.warehouse_receive_date === null ? "" : core.formatDate(p.warehouse_receive_date)}</td>
                                    <td>{p.flight_id}</td>
                                    <td>{p.status}</td>
                                    <td>{p.weight}</td>
                                    <td>{p.debt}</td>
                                    <td>{p.payed ? <img style={{width: "20px"}}
                                                        src={"https://usdelivery.ge/static/image/check.png"}
                                                        alt={"yes"}/> : <img style={{width: "20px"}}
                                                                             src={"https://usdelivery.ge/static/image/cross.png"}
                                                                             alt={"yes"}/>}</td>
                                    <td>{p.is_declared ?
                                        <img style={{width: "20px"}}
                                             src={"https://usdelivery.ge/static/image/check.png"} alt={"yes"}/> :
                                        <img style={{width: "20px"}}
                                             src={"https://usdelivery.ge/static/image/cross.png"} alt={"yes"}/>}</td>
                                    <td>{p.receiver_phone}</td>
                                    <td>
                                        <div className={"table-actions-section"}>
                                            <button className={"table-actions-button"} onClick={() => {
                                                setOptionsDropdown({show: true, id: p.id});
                                            }}><MoreVertIcon/></button>
                                            {(optionsDropdown.show && optionsDropdown.id === p.id) &&
                                                <div className={"options-dropdown-box"}>
                                                    <OutsideClickHandler onOutsideClick={() => {
                                                        setOptionsDropdown({show: false, id: 0})
                                                    }}>
                                                        <ul className={"table-options-list"}>
                                                            <li onClick={() => {
                                                                setActive({...p});
                                                                setShowViewParcel(true)
                                                                setOptionsDropdown({show: false, id: 0});
                                                            }}>ნახვა
                                                            </li>
                                                            {/*<li style={{cursor: p.file_url.slice(0, 16) !== "/static/invoices" ? "not-allowed" : "pointer"}}>{p.file_url.slice(0, 16) !== "/static/invoices" ? <span style={{color: "gray"}}>ინვოისი</span> :*/}
                                                            {/*    <a style={{textDecoration: "none", color: "black"}} href={p.file_url} target={"_blank"}>ინვოისი</a>}</li>*/}

                                                            <li onClick={() => {
                                                                setShowAddParcelModal(true);
                                                                setAction("edit");
                                                                setActive({...p})
                                                                setOptionsDropdown({show: false, id: 0});
                                                            }}>რედაქტირება
                                                            </li>
                                                            <li onClick={() => {
                                                                setShowBlockParcel(true);
                                                                setActive({...p});
                                                                setOptionsDropdown({show: false, id: 0});
                                                            }}>დაბლოკვა
                                                            </li>
                                                            <li onClick={() => {
                                                                setShowCustomStatus(true);
                                                                setActive({...p});
                                                                setOptionsDropdown({show: false, id: 0});
                                                                setCustomStatusForm(p.custom_process_status)
                                                            }}>საბაჟო სტატუსი
                                                            </li>
                                                            <li onClick={() => {
                                                                setShowShelfId(true);
                                                                setActive({...p});
                                                                setOptionsDropdown({show: false, id: 0});
                                                                setShelfIdForm(() => {
                                                                    return p.shelf_number > 0 ? p.shelf_number : ""
                                                                });
                                                            }}>თაროს ნომერი
                                                            </li>
                                                            <li onClick={() => {
                                                                setShowLogs(true);
                                                                setActive({...p});
                                                                setOptionsDropdown({show: false, id: 0});
                                                            }}>ლოგები
                                                            </li>
                                                            <li onClick={() => {
                                                                setShowSetPayed(true);
                                                                setActive({...p});
                                                                setOptionsDropdown({show: false, id: 0});
                                                            }}>გადახდილად მონიშვნა
                                                            </li>
                                                            <li onClick={() => {
                                                                setShowDeleteDialog(true);
                                                                setActive({...p});
                                                                setOptionsDropdown({show: false, id: 0});
                                                            }}>წაშლა
                                                            </li>
                                                        </ul>
                                                    </OutsideClickHandler>
                                                </div>}

                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                    }
                    {
                        data.length === 0 && (
                            <tr>
                                <td colSpan={9} style={{padding: "12px 0"}}>
                                    <p style={{
                                        width: "100%",
                                        textAlign: "center"
                                    }}>{language[lang].myPage.myParcels.recordsNotFound}</p>
                                </td>
                            </tr>
                        )
                    }
                    </tbody>
                </Table>
                <div className={"pagination-container"}>
                    <p>Total: {total}</p>
                    <Stack spacing={2}>
                        <Pagination count={Math.ceil(total / limit)} size="large" page={page} onChange={(e, v) => setPage(v)}/>
                    </Stack>
                </div>
            </div>
            <ViewParcel data={active} show={showViewParcel} setShow={setShowViewParcel} language={language}
                        lang={lang}/>
            <DialogBox description={"ნამდვილად გსურთ გზავნილის/ების გატანილად მონიშვნა?"} title={"გზავნილი გატანილია"}
                       cancelText={"არა"} open={showTakenParcelModal} setOpen={setShowTakenParcelModal}
                       agreeText={"დიახ"} agree={handlers.markAsTaken}/>
            <DialogBox description={"ნამდვილად გსურთ გზავნილის წაშლა?"} title={"გზავნილი წაშლა"} cancelText={"არა"}
                       open={showDeleteDialog} setOpen={setShowDeleteDialog} agreeText={"დიახ"}
                       agree={handlers.deleteParcel}/>
            <AddNewParcel loadParcels={loadParcels} setActive={setActive} show={showAddParcelModal} setShow={setShowAddParcelModal}
                          action={action} data={active}/>
            <BlockParcel show={showBlockParcel} reload={loadParcels} hide={setShowBlockParcel} active={active}/>
            <Modal show={showCustomStatus} onHide={() => setShowCustomStatus(false)} size={"md"}>
                <Modal.Header><h4>{language[lang].myPage.myParcels.tracking}: {active.tracking}</h4></Modal.Header>
                <Modal.Body>
                    <select className={"form-control"} onChange={(e) => setCustomStatusForm(e.target.value)} value={customStatusForm}>
                        <option value={"clean"}>მწვანე</option>
                        <option value={"need_clearance"}>წითელი, განსაბაჟებელი</option>
                        <option value={"stopped_on_custom"}>ყვითელი, საბაჟოზე გაჩერებული</option>
                        <option value={"undeclared"}>ცისფერი, დასადეკლარირებელი</option>
                        <option value={"id_number_problem"}>ლურჯი, გაჩერებული პ/ნ გამო</option>
                    </select>
                    <div className={"modal-footer"} style={{marginTop: "30px"}}>
                        <Button variant={"contained"} onClick={handlers.changeCustomStatus}>Submit</Button>
                        <Button onClick={() => setShowCustomStatus(false)} variant={"outlined"}
                                style={{marginLeft: "20px"}}>Cancel</Button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={showShelfId} onHide={() => setShowShelfId(false)} size={"md"}>
                <Modal.Header><h4>{language[lang].myPage.myParcels.tracking}: {active.tracking}</h4></Modal.Header>
                <Modal.Body>
                    <input placeholder={"ჩაწერეთ თაროს ნომერი"} className={"form-control"} onChange={(e) => setShelfIdForm(e.target.value)} value={shelfIdForm} />
                    <div className={"modal-footer"} style={{marginTop: "30px"}}>
                        <Button variant={"contained"} onClick={handlers.changeShelfNumber}>Submit</Button>
                        <Button onClick={() => setShowShelfId(false)} variant={"outlined"}
                                style={{marginLeft: "20px"}}>Cancel</Button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={showLogs} onHide={() => setShowLogs(false)} centered size={"lg"}>
                <Modal.Header><h4>ცვლილებების ისტორია</h4></Modal.Header>
                <Modal.Body>
                    <ul style={{listStyleType: "revert"}}>
                        {
                            active.logs && JSON.parse(active.logs).map((l) => {
                                return <li>{l}</li>
                            })
                        }
                        {
                            active.logs && (JSON.parse(active.logs).length === 0 && <p style={{textAlign: "center"}}>ჩანაწერები ცარიელია</p>)
                        }
                    </ul>
                </Modal.Body>
            </Modal>
            <Modal show={showSetPayed} onHide={() => setShowSetPayed(false)}>
                <Modal.Header>Set As Payed</Modal.Header>
                <Modal.Body>
                    <p>Do you want to set parcel as payed?</p>
                    <div className={"modal-footer"} style={{marginTop: "30px"}}>
                        <Button variant={"contained"} onClick={handlers.setAsPayed}>Yes</Button>
                        <Button onClick={() => setShowSetPayed(false)} variant={"outlined"}
                                style={{marginLeft: "20px"}}>No</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Parcels