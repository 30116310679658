import {Modal} from "react-bootstrap";
import {Button} from "@mui/material";
import {useEffect, useState} from "react";
import axios from "axios";
import core from "../../../core";
import { notificationActions } from "../../../store/notification";
import {useDispatch, useSelector} from "react-redux";

const AddNewFlight = ({action, show, setShow, data, loadFlights}) => {
    const dispatch = useDispatch();
    const [flightForm, setFlightForm] = useState({
        flight_id: "", country: "", estimate_arrival_date: "", comment: "", status: ""
    });
    const lang = useSelector((state) => state.user.language);

    useEffect(() => {
        if(action === "edit"){
            setFlightForm({
                flight_id: data.flight_id, country: data.country, estimate_arrival_date: core.formatDate(data.estimate_arrival_date).replaceAll("/", "-"), comment: data.comment, status: data.status
            })
        }else{
            setFlightForm({flight_id: "", country: "", estimate_arrival_date: "", comment: "", status: ""})
        }
    }, [show]);

    const handlers = {
        addFlight(e){
            e.preventDefault();
            axios.post(`/api/flight`, flightForm)
                .then(() => {
                    loadFlights();
                    setShow(false);
                })
                .catch((err) => {
                    dispatch(notificationActions.setNotification({show: true, message: err.response.data.message[lang], type: "error"}));
                    setTimeout(() => {
                        dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                    }, 4000)
                })
        },
        editFlight(e){
            e.preventDefault();
            axios.put(`/api/flight/${data.flight_id}`, flightForm)
                .then(() => {
                    loadFlights();
                    setShow(false);
                })
                .catch((err) => {
                    dispatch(notificationActions.setNotification({show: true, message: err.response.data.message[lang], type: "error"}));
                    setTimeout(() => {
                        dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                    }, 4000)
                })
        }
    }

    return(
        <Modal show={show} onHide={() => setShow(false)} centered size={"lg"}>
            <Modal.Header><h4>{action === "add" ? "Add new flight" : `Edit flight ${data.flight_id}`}</h4></Modal.Header>
            <Modal.Body>
                <form onSubmit={action === "add" ? handlers.addFlight : handlers.editFlight}>
                    <div className={"row"}>
                        <div className={"col-md-6"}>
                            <div className={"form-group mt-4"}>
                                <label className={"form-label"}>Flight ID</label>
                                <input
                                    required
                                    type={"text"}
                                    onChange={(e) => setFlightForm((prev) => ({
                                        ...prev,
                                        flight_id: e.target.value
                                    }))}
                                    value={flightForm.flight_id}
                                    className={"form-control"}
                                    placeholder={"Enter flight ID"}
                                />
                            </div>
                            <div className={"form-group mt-4"}>
                                <label className={"form-label"}>Country</label>
                                <select value={flightForm.country}
                                        className={"form-control"}
                                        onChange={(e) => {
                                            setFlightForm((prev) => ({
                                                ...prev,
                                                country: e.target.value,
                                            }));
                                        }}>
                                    <option value={""}>Select Country</option>
                                    <option value={"China"}>China</option>
                                </select>
                            </div>
                            <div className={"form-group mt-4"}>
                                <label className={"form-label"}>Status</label>
                                <select value={flightForm.status}
                                        className={"form-control"}
                                        onChange={(e) => {
                                            setFlightForm((prev) => ({
                                                ...prev,
                                                status: e.target.value
                                            }));
                                        }}>
                                    <option value={""}>Select Status</option>
                                    <option value={"warehouse"}>Warehouse</option>
                                    <option value={"on way"}>On way</option>
                                    <option value={"arrived"}>Arrived</option>
                                </select>
                            </div>
                        </div>
                        <div className={"col-md-6"}>
                            <div className={"form-group mt-4"}>
                                <label className={"form-label"}>Estimated arrival date</label>
                                <input
                                    required
                                    type={"date"}
                                    onChange={(e) => setFlightForm((prev) => ({
                                        ...prev,
                                        estimate_arrival_date: e.target.value
                                    }))}
                                    value={flightForm.estimate_arrival_date}
                                    className={"form-control"}
                                    placeholder={"Enter flight ID"}
                                />
                            </div>
                            <div className={"form-group mt-4"}>
                                <label className={"form-label"}>Comment</label>
                                <textarea
                                    onChange={(e) => setFlightForm((prev) => ({
                                        ...prev,
                                        comment: e.target.value
                                    }))}
                                    value={flightForm.comment}
                                    className={"form-control"}
                                    placeholder={"Enter comment"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={"modal-footer"} style={{marginTop: "30px"}}>
                        <Button type={"submit"} variant={"contained"}>Submit</Button>
                        <Button onClick={() => setShow(false)} variant={"outlined"}
                                style={{marginLeft: "20px"}}>Cancel</Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default AddNewFlight