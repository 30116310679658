import React, { useState } from 'react';
import {NavLink} from "react-router-dom";
import language from "../store/language";
import {useSelector} from "react-redux";

const Navigation = ({isLoggedIn}) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [showServices, setShowServices] = useState(false);
    const [showUserfulInformation, setShowUserfulInformation] = useState(false)
    const lang = useSelector((state) => state.user.language);

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const toggleSubDropdown = () => {
        setShowServices(!showServices);
    }

    return (
        <nav className={"hide-on-mobile"}>
            <ul className="navbar">
                <li><NavLink activeClassName={"active-navbar-item"} to={"/"}
                             exact>{language[lang].mainPage.header.home}</NavLink></li>
                {isLoggedIn && <li><NavLink activeClassName={"active-navbar-item"}
                                            to={"/my-page/my-parcels"}>{language[lang].mainPage.header.myPage}</NavLink>
                </li>}
                <li><NavLink activeClassName={"active-navbar-item"}
                             to={"/flights"}>{language[lang].mainPage.header.flights}</NavLink></li>
                <li onMouseEnter={toggleDropdown} onMouseLeave={toggleDropdown}>
                    <span>{language[lang].mainPage.header.info}</span>
                    {showDropdown && (
                        <ul className="dropdown first-level-dropdown">
                            <li onMouseEnter={toggleSubDropdown} onMouseLeave={toggleSubDropdown}>
                                <a href="/">{language[lang].mainPage.header.services}</a>
                                {showServices && <ul className="sub-dropdown">

                                    <li><NavLink to="/info/services/online-parcels">{language[lang].mainPage.header.onlineParcels}</NavLink></li>
                                    <li><NavLink to="/info/services/delivery-services">{language[lang].mainPage.header.deliveryService}</NavLink></li>
                                </ul>}
                            </li>
                            <li onMouseEnter={() => setShowUserfulInformation(!showUserfulInformation)}
                                onMouseLeave={() => setShowUserfulInformation(!showUserfulInformation)}>
                                <span>{language[lang].mainPage.header.usefulInformation}</span>
                                {showUserfulInformation && <ul className="sub-dropdown">

                                    <li><NavLink to="/info/useful-information/how-to-buy">{language[lang].mainPage.header.howToBuy}</NavLink></li>
                                    <li><NavLink to="/info/useful-information/customs-processes">{language[lang].mainPage.header.customsProcedure}</NavLink></li>
                                    <li><NavLink to="/info/useful-information/danger-items">{language[lang].mainPage.header.prohibitedItems}</NavLink></li>
                                    <li><NavLink to="/info/useful-information/trustees">{language[lang].mainPage.header.trustees}</NavLink></li>
                                    <li><NavLink to="/info/useful-information/faq">{language[lang].mainPage.header.faq}</NavLink></li>
                                </ul>}
                            </li>
                            <li><a href="/">{language[lang].mainPage.header.about}</a></li>
                            <li><NavLink to={"/blog"}>{language[lang].mainPage.header.blog}</NavLink></li>
                            <li><NavLink to={"/careers"}>{language[lang].mainPage.header.careers}</NavLink></li>
                        </ul>
                    )}
                </li>
                <li><NavLink activeClassName={"active-navbar-item"}
                    to={"/contact-us"}>{language[lang].mainPage.header.contactUs}</NavLink></li>
                {/* Add more menu items as needed */}
            </ul>
        </nav>
    );
};

export default Navigation;