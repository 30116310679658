import {Modal} from "react-bootstrap";
import {useEffect, useState} from "react";
import {Button} from "@mui/material";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import { notificationActions } from "../../../store/notification";

const EditUsers = ({show, setShow, data, reload}) => {
    const lang = useSelector((state) => state.user.language)
    const dispatch = useDispatch();
    const [editUserForm, setEditUserForm] = useState({
        name_geo: "",
        surname_geo: "",
        name_en: "",
        surname_en: "",
        email: "",
        phone: "",
        password: "",
        balance: "",
        debt: "",
        debt_in_usd: "",
        delivery_debt: "",
        company_name_geo: "",
        company_name_en: "",
        identification_code: "",
        name_of_representative: "",
        surname_of_representative: "",
        number_of_id: "",
        position_of_representative: ""
    })

    useEffect(() => {
        setEditUserForm({
            name_geo: data.name_geo,
            surname_geo: data.surname_geo,
            name_en: data.name_en,
            surname_en: data.surname_en,
            email: data.email,
            phone: data.phone,
            password: data.password,
            balance: data.balance,
            debt: data.debt,
            debt_in_usd: data.debt_in_usd,
            delivery_debt: data.delivery_debt,
            company_name_geo: data.company_name_geo,
            company_name_en: data.company_name_en,
            identification_code: data.identification_code,
            name_of_representative: data.name_of_representative,
            surname_of_representative: data.surname_of_representative,
            number_of_id: data.number_of_id,
            position_of_representative: data.position_of_representative
        })
    }, [show]);

    const saveUser = (e) => {
        e.preventDefault();
        axios.put(`/api/admin/users/${data.user_id}`, editUserForm)
            .then(() => {
                reload();
                setShow(false);
            })
            .catch((err) => {
                dispatch(notificationActions.setNotification({show: true, message: err.response.data.message[lang], type: "error"}));
                setTimeout(() => {
                    dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                }, 4000)
            })
    }

    return (
        <Modal show={show} onHide={() => setShow(false)} centered size={"lg"}>
            <Modal.Header><h4>Edit user: {data.user_id}</h4></Modal.Header>
            <Modal.Body>
                <form onSubmit={saveUser}>
                    <div className={"row"}>
                        <div className={"col-md-6"}>
                            {data.user_type === "physical" && <>
                                <div className={"form-group mt-4"}>
                                    <label className={"form-label"}>Name (En)</label>
                                    <input
                                        typeof={"text"}
                                        required
                                        onChange={(e) => setEditUserForm((prev) => ({
                                            ...prev,
                                            name_en: e.target.value
                                        }))}
                                        value={editUserForm.name_en}
                                        className={"form-control"}
                                        placeholder={"Enter user name (En)"}
                                    />
                                </div>
                                <div className={"form-group mt-4"}>
                                    <label className={"form-label"}>Name (Geo)</label>
                                    <input
                                        typeof={"text"}
                                        required
                                        onChange={(e) => setEditUserForm((prev) => ({
                                            ...prev,
                                            name_geo: e.target.value
                                        }))}
                                        value={editUserForm.name_geo}
                                        className={"form-control"}
                                        placeholder={"Enter user name (Geo)"}
                                    />
                                </div>
                                <div className={"form-group mt-4"}>
                                    <label className={"form-label"}>Surname (En)</label>
                                    <input
                                        typeof={"text"}
                                        required
                                        onChange={(e) => setEditUserForm((prev) => ({
                                            ...prev,
                                            surname_en: e.target.value
                                        }))}
                                        value={editUserForm.surname_en}
                                        className={"form-control"}
                                        placeholder={"Enter user Surname (En)"}
                                    />
                                </div>
                                <div className={"form-group mt-4"}>
                                    <label className={"form-label"}>Surname (Geo)</label>
                                    <input
                                        typeof={"text"}
                                        required
                                        onChange={(e) => setEditUserForm((prev) => ({
                                            ...prev,
                                            surname_geo: e.target.value
                                        }))}
                                        value={editUserForm.surname_geo}
                                        className={"form-control"}
                                        placeholder={"Enter user surname (Geo)"}
                                    />
                                </div>
                            </>
                            }
                            {
                                data.user_type === "legal" && <>
                                    <div className={"form-group mt-4"}>
                                        <label className={"form-label"}>Company Name (En)</label>
                                        <input
                                            typeof={"text"}
                                            required
                                            onChange={(e) => setEditUserForm((prev) => ({
                                                ...prev,
                                                company_name_en: e.target.value
                                            }))}
                                            value={editUserForm.company_name_en}
                                            className={"form-control"}
                                            placeholder={"Enter company name (En)"}
                                        />
                                    </div>
                                    <div className={"form-group mt-4"}>
                                        <label className={"form-label"}>Company Name (Geo)</label>
                                        <input
                                            typeof={"text"}
                                            required
                                            onChange={(e) => setEditUserForm((prev) => ({
                                                ...prev,
                                                company_name_geo: e.target.value
                                            }))}
                                            value={editUserForm.company_name_geo}
                                            className={"form-control"}
                                            placeholder={"Enter company name (Geo)"}
                                        />
                                    </div>
                                    <div className={"form-group mt-4"}>
                                        <label className={"form-label"}>Identification Code</label>
                                        <input
                                            typeof={"text"}
                                            required
                                            onChange={(e) => setEditUserForm((prev) => ({
                                                ...prev,
                                                identification_code: e.target.value
                                            }))}
                                            value={editUserForm.identification_code}
                                            className={"form-control"}
                                            placeholder={"Enter identification code"}
                                        />
                                    </div>
                                    <div className={"form-group mt-4"}>
                                        <label className={"form-label"}>Name of representative</label>
                                        <input
                                            typeof={"text"}
                                            required
                                            onChange={(e) => setEditUserForm((prev) => ({
                                                ...prev,
                                                name_of_representative: e.target.value
                                            }))}
                                            value={editUserForm.name_of_representative}
                                            className={"form-control"}
                                            placeholder={"Enter name of representative"}
                                        />
                                    </div>
                                    <div className={"form-group mt-4"}>
                                        <label className={"form-label"}>Surname of representative</label>
                                        <input
                                            typeof={"text"}
                                            required
                                            onChange={(e) => setEditUserForm((prev) => ({
                                                ...prev,
                                                surname_of_representative: e.target.value
                                            }))}
                                            value={editUserForm.surname_of_representative}
                                            className={"form-control"}
                                            placeholder={"Enter surname of representative"}
                                        />
                                    </div>
                                </>
                            }
                            {data.user_type === "physical" && <div className={"form-group mt-4"}>
                                <label className={"form-label"}>Number of ID</label>
                                <input
                                    typeof={"text"}
                                    required
                                    onChange={(e) => setEditUserForm((prev) => ({
                                        ...prev,
                                        number_of_id: e.target.value
                                    }))}
                                    value={editUserForm.number_of_id}
                                    className={"form-control"}
                                    placeholder={"Enter number of ID"}
                                />
                            </div>}
                            {data.user_type === "legal" && <div className={"form-group mt-4"}>
                                <label className={"form-label"}>Position of representative</label>
                                <input
                                    typeof={"text"}
                                    required
                                    onChange={(e) => setEditUserForm((prev) => ({
                                        ...prev,
                                        position_of_representative: e.target.value
                                    }))}
                                    value={editUserForm.position_of_representative}
                                    className={"form-control"}
                                    placeholder={"Enter position of representative"}
                                />
                            </div>}
                        </div>
                        <div className={"col-md-6"}>
                            <div className={"form-group mt-4"}>
                                <label className={"form-label"}>Email</label>
                                <input
                                    typeof={"text"}
                                    required
                                    onChange={(e) => setEditUserForm((prev) => ({...prev, email: e.target.value}))}
                                    value={editUserForm.email}
                                    className={"form-control"}
                                    placeholder={"Enter user email"}
                                />
                            </div>
                            <div className={"form-group mt-4"}>
                                <label className={"form-label"}>Phone</label>
                                <input
                                    typeof={"text"}
                                    required
                                    onChange={(e) => setEditUserForm((prev) => ({...prev, phone: e.target.value}))}
                                    value={editUserForm.phone}
                                    className={"form-control"}
                                    placeholder={"Enter user phone"}
                                />
                            </div>
                            <div className={"form-group mt-4"}>
                                <label className={"form-label"}>Debt</label>
                                <input
                                    typeof={"text"}
                                    required
                                    onChange={(e) => setEditUserForm((prev) => ({...prev, debt: e.target.value}))}
                                    value={editUserForm.debt}
                                    className={"form-control"}
                                    placeholder={"Enter user debt"}
                                />
                            </div>
                            <div className={"form-group mt-4"}>
                                <label className={"form-label"}>Delivery debt</label>
                                <input
                                    typeof={"text"}
                                    required
                                    onChange={(e) => setEditUserForm((prev) => ({
                                        ...prev,
                                        delivery_debt: e.target.value
                                    }))}
                                    value={editUserForm.delivery_debt}
                                    className={"form-control"}
                                    placeholder={"Enter delivery debt"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={"modal-footer"} style={{marginTop: "30px"}}>
                        <Button type={"submit"} variant={"contained"}>Submit</Button>
                        <Button onClick={() => setShow(false)} variant={"outlined"}
                                style={{marginLeft: "20px"}}>Cancel</Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default EditUsers