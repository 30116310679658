import {useEffect, useState} from "react";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import {Pagination, Stack} from "@mui/material";
import WarehouseIcon from '@mui/icons-material/Warehouse';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import core from "../core";
import {useSelector} from "react-redux";
import language from "../store/language";

const Flights = ({setIsMyPage}) => {
    const windowWidth = window.innerWidth;
    const lang = useSelector((state) => state.user.language);
    const [keyword, setKeyword] = useState("");
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const [searchFieldValue, setSearchFieldValue] = useState("");
    const [status, setStatus] = useState("all");
    const [showOnMobile, setShowOnMobile] = useState([]);

    useEffect(() => {
        setIsMyPage(false);
    }, []);

    useEffect(() => {
        axios(`/api/flights?page=${page}&limit=${limit}&keyword=${keyword}&status=${status}`)
            .then((res) => {
                setData(res.data.data);
                setTotal(res.data.total);
            })
            .catch((err) => {

            })
    }, [page, limit, keyword, status]);

    return(
        <div className={"flights-page"}>
            <div style={{width: "100%"}}>
                <div className={"data-table-header"}>
                    <div className={"search-field-container"}>
                        <input type={"text"}
                               onKeyUp={(e) => {
                                   if (e.keyCode === 13) {
                                       setKeyword(searchFieldValue);
                                   }
                               }}
                               onChange={(e) => setSearchFieldValue(e.target.value)}
                               className={"search-field"}/>
                        <button><SearchIcon onClick={() => setKeyword(searchFieldValue)} style={{color: "white"}}/>
                        </button>
                    </div>
                    <select style={{maxWidth: "170px"}} className={"form-control"} value={status}
                            onChange={(e) => setStatus(e.target.value)}>
                        <option value={"all"}>{language[lang].flights.statusFilters[0]}</option>
                        <option value={"arrived"}>{language[lang].flights.statusFilters[1]}</option>
                        <option value={"on way"}>{language[lang].flights.statusFilters[2]}</option>
                        <option value={"warehouse"}>{language[lang].flights.statusFilters[3]}</option>
                    </select>
                </div>
                <table style={{width: "100%"}} className={"data-table"}>
                    <thead>
                    <tr>
                        <th></th>
                        <th>{language[lang].flights.flightId}</th>
                        <th>{language[lang].flights.country}</th>
                        {
                            windowWidth > 900 && <>
                                <th>{language[lang].flights.estArrive}</th>
                                <th>{language[lang].flights.arrivalDate}</th>
                            </>
                        }
                        <th>{language[lang].flights.status}</th>
                        {
                            windowWidth > 900 && <>
                                <th>{language[lang].flights.comment}</th>
                            </>
                        }
                    </tr>
                    </thead>
                    <tbody>
                    {
                        data.map((p, i) => {
                            return (
                                <>
                                    <tr className={`${i % 2 === 1 ? 'odd-row' : 'even-row'} table-rows`}
                                        key={p.tracking}>
                                        <td style={{paddingLeft: "5px", paddingBottom: showOnMobile.includes(p.flight_id) ? "160px" : (windowWidth > 900 ? "10px" : "20px")}}>
                                            <div style={{
                                                padding: windowWidth > 900 ? "12px" : "6px",
                                                borderRadius: "50%",
                                                background: "#d8f3f5",
                                                width: "fit-content"
                                            }}>
                                                {
                                                    p.status === "warehouse" &&
                                                    <WarehouseIcon style={{fontSize: windowWidth > 900 ? "30px" : "20px", color: "#02D7DE"}}/>
                                                }
                                                {
                                                    p.status === "on way" &&
                                                    <FlightTakeoffIcon style={{fontSize: windowWidth > 900 ? "30px" : "20px", color: "#02D7DE"}}/>
                                                }
                                                {
                                                    p.status === "arrived" &&
                                                    <FlightLandIcon style={{fontSize: windowWidth > 900 ? "30px" : "20px", color: "#02D7DE"}}/>
                                                }
                                            </div>
                                        </td>
                                        <td>{p.flight_id}</td>
                                        <td>{language[lang].flights[p.country]}</td>
                                        {windowWidth > 900 && <>
                                            <td>{core.formatDate(p.estimate_arrival_date)}</td>
                                            <td>{core.formatDate(p.arrival_date)}</td>
                                        </>}
                                        <td>{language[lang].flights[p.status.replace(" ", "_")]}</td>
                                        {windowWidth > 900 && <td>{p.comment}</td>}
                                    </tr>
                                    <span className={"flights-table-mobile"} style={{marginTop: showOnMobile.includes(p.flight_id) ? "-165px" : "-25px"}}>
                                        <button onClick={() => {
                                            if(showOnMobile.includes(p.flight_id)){
                                                const result = showOnMobile.filter((f) => f !== p.flight_id);
                                                setShowOnMobile([...result]);
                                            }else {
                                                setShowOnMobile((prev) => ([...prev, p.flight_id]));
                                            }
                                        }}>{showOnMobile.includes(p.flight_id) ? "-Hide" : "+Show"}</button>
                                        {showOnMobile.includes(p.flight_id) && <ul>
                                            <li><p>{language[lang].flights.estArrive}:</p>
                                                <span>{core.formatDate(p.estimate_arrival_date)}</span></li>
                                            <li><p>{language[lang].flights.arrivalDate}:</p>
                                                <span>{core.formatDate(p.arrival_date)}</span></li>
                                            <li><p>{language[lang].flights.status}:</p>
                                                <span>{language[lang].flights[p.status.replace(" ", "_")]}</span></li>
                                            <li><p>{language[lang].flights.comment}:</p> <span>{p.comment}</span></li>
                                        </ul>}
                                    </span>
                                </>
                            )
                        })
                    }
                    {
                        data.length === 0 && (
                            <tr>
                                <td colSpan={9} style={{padding: "12px 0"}}>
                                    <p style={{width: "100%", textAlign: "center"}}>{language[lang].myPage.myParcels.recordsNotFound}</p>
                                </td>
                            </tr>
                        )
                    }
                    </tbody>
                </table>
                <div className={"pagination-container"}>
                    <p>{language[lang].flights.total}: {total}</p>
                    <Stack spacing={2}>
                        <Pagination count={Math.ceil(total / limit)} size="large" page={page} onChange={(e, v) => setPage(v)}/>
                    </Stack>
                </div>
            </div>
        </div>
    )
}

export default Flights