import {Modal} from "react-bootstrap";
import {useEffect, useState} from "react";
import BackupIcon from '@mui/icons-material/Backup';
import {Button} from "@mui/material";
import axios from "axios";
import language from "../../store/language";
import { notificationActions } from "../../store/notification";
import {useDispatch} from "react-redux";

const Declaration = ({lang, language, show, hide, action, data, submit}) => {
    const [declarationForm, setDeclarationForm] = useState({
        price: "", currency: "", website: "", description: "", tracking: "", another_taker: false, file: "", insurance: false,
        trusteer_fullname: "", trusteer_address: "", trusteer_phone: "", trusteer_id_number: ""
    });
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({tracking: false, website: false})
    const [filename, setFileName] = useState("");
    const [trusteers, setTrusteers] = useState([]);
    const [createTrusteerModal, setCreateTrusteerModal] = useState(false);
    const [trusteersForm, setTrusteersForm] = useState({name: "", surname: "", address: "", phone: "", id_number: ""});
    const [activeTrusteer, setActiveTrusteer] = useState("");
    const [error, setError] = useState({file: false, taker: false, currency: false, website: false});

    const loadTrusteers = () => {
        axios('/api/trusteers')
            .then((res) => {
                setTrusteers(res.data.data);
            })
            .catch((err) => {
                dispatch(notificationActions.setNotification({show: true, message: err.response.data.message[lang], type: "error"}));
                setTimeout(() => {
                    dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                }, 4000)
            })
    }

    const handlers = {
        createTrusteer(e) {
            e.preventDefault();
            axios.post(`/api/trusteers`, trusteersForm)
                .then((res) => {
                    loadTrusteers();
                    setCreateTrusteerModal(false);
                    dispatch(notificationActions.setNotification({show: true, message: res.data.message[lang], type: "error"}));
                    setTimeout(() => {
                        dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                    }, 4000)
                })
                .catch((err) => {
                    dispatch(notificationActions.setNotification({show: true, message: err.response.data.message[lang], type: "error"}));
                    setTimeout(() => {
                        dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                    }, 4000)
                })
        }
    }

    useEffect(() => {
        if (action === "edit" || action === "declaration") {
            if(data.is_declared === true) {
                setDeclarationForm({
                    price: data.price,
                    currency: data.currency,
                    website: data.website,
                    description: data.description,
                    tracking: data.tracking,
                    another_taker: data.another_taker,
                    insurance: data.insurance,
                    file: ""
                })
            }else{
                setDeclarationForm({
                    price: "", currency: "", website: "", description: "", tracking: data.tracking, another_taker: false, file: "", insurance: false
                })
            }
            setActiveTrusteer(data.trusteer_id_number)
        } else {
            setDeclarationForm({
                price: "",
                currency: "",
                website: "",
                description: "",
                tracking: "",
                another_taker: false,
                file: "",
                insurance: false
            })
        }
        setError({file: false, taker: false, currency: false})
        setActiveTrusteer(data.trusteer_id_number == null ? "" : data.trusteer_id_number);
        setFileName("");
    }, [action, show]);

    useEffect(loadTrusteers, []);
    useEffect(() => {
        setTrusteersForm({name: "", surname: "", address: "", phone: "", id_number: ""})
    }, [createTrusteerModal]);

    return (
        <Modal show={show} onHide={() => hide(false)} centered>
            {
                show && <>
                    <Modal.Header>
                        <h5>{action === "add" ? language[lang].myPage.myParcels.preliminaryTitle : `${language[lang].myPage.myParcels.declarationTitle} ${data.tracking}`}</h5>
                    </Modal.Header>
                    <Modal.Body>
                        {!createTrusteerModal && <form className="declaration-form" onSubmit={async (e) => {
                            e.preventDefault();
                            try{
                                const getPriceInGel = await axios.get(`/api/convertation?amount=${declarationForm.price}&currency=${declarationForm.currency}`);
                                const limitReached = getPriceInGel.data.amount;
                                if(!declarationForm.website.includes(".com")){
                                    setError((prev) => ({...prev, website: true}))
                                }else if (limitReached >= 300 && filename === "" && data.file_url == null) {
                                    setError((prev) => ({...prev, file: true}));
                                }else if(declarationForm.currency === "" || declarationForm.currency == null){
                                    setError((prev) => ({...prev, currency: true}));
                                } else if (declarationForm.another_taker === true && activeTrusteer === "") {
                                    setError((prev) => ({...prev, taker: true}));
                                } else {
                                    let obj = {...declarationForm};
                                    if (declarationForm.another_taker === true) {
                                        const trust = trusteers.filter((t) => t.id_number === activeTrusteer);
                                        obj = {...obj, ...trust[0]}
                                    }
                                    submit(obj);
                                }
                            }catch(err){
                                alert("error")
                            }
                        }}>
                            <div className={"form-group"} style={{marginTop: "10px"}}>
                                <label className={"form-label"}>{language[lang].myPage.myParcels.tracking}*</label>
                                <input required type={"text"} disabled={action === "declaration"}
                                       value={declarationForm.tracking} className={"form-control"}
                                       onChange={(e) => setDeclarationForm((prev) => ({
                                           ...prev,
                                           tracking: e.target.value
                                       }))}
                                       placeholder={""} onBlur={() => {
                                    // handlers.validate("tracking", declarationForm.tracking);
                                }}/>
                                {errors.tracking && <p className={"form-error-message"}>{language[lang].myPage.myParcels.noTracking}</p>}
                            </div>
                            <div className={"form-group"} style={{marginTop: "20px"}}>
                                <label className={"form-label"}>{language[lang].myPage.myParcels.website}*</label>
                                <input required type={"text"} value={declarationForm.website} className={"form-control"}
                                       onChange={(e) => setDeclarationForm((prev) => ({
                                           ...prev,
                                           website: e.target.value
                                       }))}
                                       placeholder={""} onBlur={() => {
                                    // handlers.validate("tracking", declarationForm.tracking);
                                }}/>
                                {error.website && <p className={"form-error-message"}>{language[lang].myPage.myParcels.website}</p>}
                            </div>
                            <div className={"form-group"} style={{marginTop: "20px"}}>
                                <label className={"form-label"}>{language[lang].myPage.myParcels.price}*</label>
                                <input required type={"text"} value={declarationForm.price} className={"form-control"}
                                       onChange={(e) => setDeclarationForm((prev) => ({
                                           ...prev,
                                           price: e.target.value
                                       }))}
                                       placeholder={""} onBlur={() => {
                                    // handlers.validate("tracking", declarationForm.tracking);
                                }}/>
                            </div>
                            <div className={"form-group"} style={{marginTop: "20px"}}>
                                <label className={"form-label"}>{language[lang].myPage.myParcels.currency}*</label>
                                <select className={"form-control"} onChange={(e) => {
                                    setDeclarationForm((prev) => ({
                                        ...prev,
                                        currency: e.target.value
                                    }));
                                    setError((prev) => ({...prev, currency: false}))
                                }} value={declarationForm.currency}>
                                    <option value={""}>{language[lang].myPage.myParcels.selectCurrency}</option>
                                    <option value={"USD"}>USD</option>
                                    <option value={"YEN"}>YEN</option>
                                </select>
                                {error.currency && <p className={"form-error-message"}>{language[lang].auth.signUp.accountType.errors.required}</p>}
                            </div>
                            <div className={"form-group"} style={{marginTop: "20px"}}>
                                <label className={"form-label"}>{language[lang].myPage.myParcels.description}*</label>
                                <textarea required style={{height: "110px"}} value={declarationForm.description}
                                          className={"form-control"}
                                          onChange={(e) => setDeclarationForm((prev) => ({
                                              ...prev,
                                              description: e.target.value
                                          }))}
                                          placeholder={""} onBlur={() => {
                                    // handlers.validate("tracking", declarationForm.tracking);
                                }}/>
                            </div>
                            <div className={"form-group"}
                                 style={{marginTop: "20px", display: "flex", flexDirection: "column"}}>
                                <label className={"form-label"}>{language[lang].myPage.myParcels.upload}</label>
                                <input
                                       id={"upload-invoice"} type={"file"} onChange={(e) => {
                                    setDeclarationForm((prev) => ({...prev, file: e.target.files[0]}));
                                    setFileName(e.target.files[0].name);
                                    setError((prev) => ({...prev, file: false}));
                                }} hidden/>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <label htmlFor={"upload-invoice"}
                                           className={`upload-invoice-button`}>
                                        <BackupIcon style={{color: "#02D7DE"}}/>
                                        <span>{language[lang].myPage.myParcels.select}</span>
                                    </label>
                                    <span style={{marginLeft: "10px"}}>{filename}</span>
                                    {
                                        ((action === "edit" || action === "declaration") && data.file_url != null && declarationForm.file === "") &&
                                        <img src={data.file_url} style={{width: "80px", marginLeft: "30px"}}
                                             alt={"invoice"}/>
                                    }
                                </div>
                                {error.file && <p className={"form-error-message"}>{language[lang].myPage.myParcels.selectFileError}</p>}
                            </div>
                            <div className={"form-group"} style={{marginTop: "20px"}}>
                                <input id={"insurance"} type={"checkbox"}
                                    style={{marginTop: 0}}
                                    checked={declarationForm.insurance}
                                    className={"form-check-input"}
                                    onChange={(e) => {
                                        setDeclarationForm((prev) => ({
                                            ...prev,
                                            insurance: e.target.checked
                                        }))
                                    }}
                                />
                                <label className={"form-label"} htmlFor="insurance" style={{marginLeft: "8px", cursor: "pointer"}}>{language[lang].myPage.myParcels.insurance}</label>
                            </div>
                            <div className={"form-group"} style={{marginTop: "20px"}}>
                                <span className={"form-check-label"}>{language[lang].myPage.myParcels.pickup}*</span>
                                <div style={{marginTop: "8px", display: "flex", alignItems: "center"}}>
                                    <div>
                                        <span>{language[lang].myPage.myParcels.yes}</span>
                                        <input type={"checkbox"}
                                               style={{marginTop: 0, marginLeft: "8px"}}
                                               checked={declarationForm.another_taker}
                                               className={"form-check-input"}
                                               onChange={(e) => {
                                                   if (e.target.checked) {
                                                       setDeclarationForm((prev) => ({
                                                           ...prev,
                                                           another_taker: true
                                                       }))
                                                   }
                                               }}
                                        />
                                    </div>
                                    <div style={{marginLeft: "20px"}}>
                                        <span>{language[lang].myPage.myParcels.no}</span>
                                        <input type={"checkbox"}
                                               style={{marginTop: 0, marginLeft: "8px"}}
                                               checked={!declarationForm.another_taker}
                                               className={"form-check-input"}
                                               onChange={(e) => {
                                                   if (e.target.checked) {
                                                       setDeclarationForm((prev) => ({
                                                           ...prev,
                                                           another_taker: false
                                                       }))
                                                   }
                                               }}
                                        />
                                    </div>
                                </div>
                                {declarationForm.another_taker && <div>
                                    <div style={{display: "flex", justifyContent: "flex-end"}}>
                                        <Button onClick={() => setCreateTrusteerModal(true)}>Add New</Button>
                                    </div>
                                    {error.taker && <p className={"form-error-message"}>{language[lang].myPage.myParcels.parcelTakerError}</p>}
                                    <ul className={"trusteers-list"}>
                                        {trusteers.map((t) => {
                                            return <li key={t.id} onClick={() => {
                                                setActiveTrusteer(t.id_number);
                                                setDeclarationForm((prev) => ({...prev, trusteer_fullname: `${t.name} ${t.surname}`, trusteer_address: t.address, trusteer_phone: t.phone, trusteer_id_number: t.id_number}));
                                                setError((prev) => ({...prev, taker: false}));
                                            }} className={activeTrusteer === t.id_number ? 'active-trusteer' : ''}>
                                                <span>{t.name} {t.surname}</span>
                                                <span> {t.phone}</span>
                                            </li>
                                        })}
                                    </ul>
                                    {
                                        trusteers.length === 0 && <div><p
                                            style={{textAlign: "center", marginTop: "15px", color: "gray"}}>Trusteers
                                            not found</p></div>
                                    }
                                </div>}
                            </div>
                            <Button type={"submit"} style={{width: "100%", marginTop: "40px"}} variant={"contained"}>
                                {language[lang].myPage.myParcels.declaration}
                            </Button>
                        </form>}
                        {
                            createTrusteerModal && <form className="declaration-form" onSubmit={handlers.createTrusteer}>
                                <div className={"form-group"} style={{marginTop: "10px"}}>
                                    <label className={"form-label"}>{language[lang].myPage.myParcels.name}*</label>
                                    <input required type={"text"}
                                           value={trusteersForm.name} className={"form-control"}
                                           onChange={(e) => setTrusteersForm((prev) => ({
                                               ...prev,
                                               name: e.target.value
                                           }))}
                                    />
                                </div>
                                <div className={"form-group mt-4"}>
                                    <label className={"form-label"}>{language[lang].myPage.myParcels.surname}*</label>
                                    <input required type={"text"}
                                           value={trusteersForm.surname} className={"form-control"}
                                           onChange={(e) => setTrusteersForm((prev) => ({
                                               ...prev,
                                               surname: e.target.value
                                           }))}
                                    />
                                </div>
                                <div className={"form-group mt-4"}>
                                    <label className={"form-label"}>{language[lang].myPage.myParcels.id_number}*</label>
                                    <input required type={"text"}
                                           value={trusteersForm.id_number} className={"form-control"}
                                           onChange={(e) => setTrusteersForm((prev) => ({
                                               ...prev,
                                               id_number: e.target.value
                                           }))}
                                    />
                                </div>
                                <div className={"form-group mt-4"}>
                                    <label className={"form-label"}>{language[lang].myPage.myParcels.address}*</label>
                                    <input required type={"text"}
                                           value={trusteersForm.address} className={"form-control"}
                                           onChange={(e) => setTrusteersForm((prev) => ({
                                               ...prev,
                                               address: e.target.value
                                           }))}
                                    />
                                </div>
                                <div className={"form-group mt-4"}>
                                    <label className={"form-label"}>{language[lang].myPage.myParcels.phone}*</label>
                                    <input required type={"text"}
                                           value={trusteersForm.phone} className={"form-control"}
                                           onChange={(e) => setTrusteersForm((prev) => ({
                                               ...prev,
                                               phone: e.target.value
                                           }))}
                                    />
                                </div>
                                <div className={"modal-footer"} style={{marginTop: "30px"}}>
                                    <Button type={"submit"} variant={"contained"}>Submit</Button>
                                    <Button onClick={() => setCreateTrusteerModal(false)} variant={"outlined"}
                                            style={{marginLeft: "20px"}}>Cancel</Button>
                                </div>
                            </form>
                        }
                    </Modal.Body>
                </>
            }
        </Modal>
    )
}

export default Declaration