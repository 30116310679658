import {useEffect} from "react";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {adminAction} from "../../store/admin";
import {Route} from "react-router-dom";
import Auth from "./common/Auth";
import "./admin.css"
import Aside from "./common/Aside/Aside";
import Admins from "./Pages/Admins";
import Users from "./Pages/Users";
import Delivery from "./Pages/Delivery";
import Parcels from "./Pages/Parcels";
import Flights from "./Pages/Flights";
import MessagesAdmin from "./Pages/MessagesAdmin";

const AdminRoute = ({setAdminRoutes}) => {
    const dispatch = useDispatch();
    const isLoggedIn = useSelector((state) => state.admin.isLoggedIn);

    useEffect(() => {
        setAdminRoutes(true)
        axios(`/api/admin`)
            .then((res) => {
                const data = res.data.data[0];
                dispatch(adminAction.setUserData({
                    name: data.name,
                    surname: data.surname,
                    user_id: data.user_id,
                    role: data.role,
                    phone: data.phone,
                    email: data.email,
                }))
                dispatch(adminAction.setIsLoggedIn(true));
            })
            .catch(() => {
                dispatch(adminAction.setIsLoggedIn(false));
            })
    }, [])
    return <>
        {
            isLoggedIn === false && <Auth/>
        }
        {
            isLoggedIn === true && (
                <div className={"admin-dashboard-container"}>
                    <div className={"admin-dashboard-aside"}><Aside/></div>
                    <div className={"admin-dashboard-content"}>
                        <Route path={"/admin-dashboard/admins"}><Admins/></Route>
                        <Route path={"/admin-dashboard/users"}><Users/></Route>
                        <Route path={"/admin-dashboard/parcels"}><Parcels/></Route>
                        <Route path={"/admin-dashboard/delivery"}><Delivery/></Route>
                        <Route path={"/admin-dashboard/flights"}><Flights/></Route>
                        <Route path={"/admin-dashboard/conversation"}><MessagesAdmin/></Route>
                    </div>
                </div>
            )
        }
    </>
}

export default AdminRoute