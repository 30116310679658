import {useSelector} from "react-redux";
import FilePresentIcon from '@mui/icons-material/FilePresent';
import {useEffect, useState} from "react";
import Person2Icon from '@mui/icons-material/Person2';

const MessageCard = ({data, setReadMessageIndex, readMessageIndex}) => {
    const user = useSelector((state) => state.user.userId);
    const admin = useSelector((state) => state.admin.userId);
    const [user_id, set_user_id] = useState("");
    useEffect(() => {
        if(data.is_read){
            setReadMessageIndex(data.id);
        }
        setReadMessageIndex();
        set_user_id(user === "" ? admin : user)
    }, []);
    return(
        <div className={`message-box ${data.user_id === user_id ? "sender-side" : "receiver-side"}`}>
            <div style={{display: "flex", alignItems: "center", maxWidth: "75%"}}>
                {data.user_id !== user_id && <div title={`${data.name_geo} ${data.surname_geo}`} style={{marginRight: "10px", backgroundImage: `url('${data.profile_image}')`}} className={"chat-profile-icon"}>
                    {
                        data.profile_image === "" && <Person2Icon style={{color: "white"}}/>
                    }
                </div>}
                <div className={`message-text ${data.user_id === user_id ? "sent-message" : "received-message"}`}>
                    <p>{data.message}</p>
                    {
                        data.linked_file === true &&
                        <a href={data.file_url} style={{marginTop: "8px"}} target={"_blank"}><FilePresentIcon
                            style={{fontSize: "30px"}}/></a>
                    }
                </div>
                {data.user_id === user_id && <div title={`${data.name_geo} ${data.surname_geo}`} style={{marginLeft: "10px", backgroundImage: `url('${data.profile_image}')`}} className={"chat-profile-icon"}>
                    {
                        data.profile_image === "" && <Person2Icon style={{color: "white"}}/>
                    }
                </div>}
            </div>
            {(data.is_read && readMessageIndex === data.id) && <span className={"is-message-read"}>Read</span>}
        </div>
    )
}

export default MessageCard