import {useEffect, useState} from "react";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import {Modal, Table} from "react-bootstrap";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import OutsideClickHandler from "react-outside-click-handler";
import {Button, Pagination, Stack} from "@mui/material";
import AddNewFlight from "../Components/AddNewFlight";
import DialogBox from "../../../components/DialogBox";
import core from "../../../core";
import language from "../../../store/language";
import {useSelector, useDispatch} from "react-redux";
import { notificationActions } from "../../../store/notification";

const Flights = () => {
    const dispatch = useDispatch();
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [keyword, setKeyword] = useState("");
    const [status, setStatus] = useState("all");
    const [data, setData] = useState([]);
    const [searchFieldValue, setSearchFieldValue] = useState("");
    const [active, setActive] = useState({});
    const [total, setTotal] = useState(0);
    const [optionsDropdown, setOptionsDropdown] = useState({show: false, id: 0});
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showCreateFlightModal, setShowCreateFlightModal] = useState(false);
    const [action, setAction] = useState("add");
    const lang = useSelector((state) => state.user.language);

    const loadFlights = () => {
        axios(`/api/flights?limit=${limit}&page=${page}&keyword=${keyword}&status=${status}`)
            .then((res) => {
                const admins = res.data.data;
                setData([...admins]);
                setTotal(res.data.total);
            })
            .catch((err) => {
                dispatch(notificationActions.setNotification({show: true, message: err.response.data.message[lang], type: "error"}));
                setTimeout(() => {
                    dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                }, 4000)
            })
    }

    const handlers = {
        removeFlight(){
            axios.delete(`/api/flight/${active.id}`)
                .then(() => {
                    loadFlights();
                    setShowDeleteDialog(false);
                })
                .catch((err) => {
                    dispatch(notificationActions.setNotification({show: true, message: err.response.data.message[lang], type: "error"}));
                    setTimeout(() => {
                        dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                    }, 4000)
                })
        }
    }

    useEffect(loadFlights, [limit, page, keyword, status]);

    return (
        <>
            <h3 style={{marginBottom: "35px"}}>Flights</h3>
            <div style={{width: "100%"}}>
                <div className={"data-table-header"}>
                    <div className={"search-field-container"}>
                        <input type={"text"}
                               onKeyUp={(e) => {
                                   if (e.keyCode === 13) {
                                       setKeyword(searchFieldValue);
                                   }
                               }}
                               onChange={(e) => setSearchFieldValue(e.target.value)}
                               placeholder={"Enter keyword"}
                               className={"search-field"}/>
                        <button><SearchIcon onClick={() => {
                            setKeyword(searchFieldValue);
                            setAction("add");
                        }} style={{color: "white"}}/>
                        </button>
                    </div>
                    <div style={{display: "flex"}}>
                        <select style={{maxWidth: "130px"}} className={"form-control"} value={status}
                                onChange={(e) => setStatus(e.target.value)}>
                            <option value={"all"}>Select status</option>
                            <option value={"warehouse"}>Warehouse</option>
                            <option value={"On way"}>On way</option>
                            <option value={"arrived"}>Arrived</option>
                        </select>
                        <Button onClick={() => {
                            setShowCreateFlightModal(true);
                            setAction("add");
                        }} variant={"contained"} style={{marginLeft: "20px"}}>დამატება</Button>
                    </div>
                </div>
                <Table responsive style={{width: "100%"}} className={"data-table"}>
                    <thead>
                    <tr>
                        <th>რეისის ნომერი</th>
                        <th>ქვეყანა</th>
                        <th>სავარაუდო ჩამოსვლა</th>
                        <th>ჩამოსვლა</th>
                        <th>სტატუსი</th>
                        <th>კომენტარი</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        data.map((p, i) => {
                            return (
                                <tr className={`${i % 2 === 1 ? 'odd-row' : 'even-row'} table-rows`}
                                    key={p.id}>
                                    <td style={{paddingLeft: "5px"}}>{p.flight_id}</td>
                                    <td>{p.country}</td>
                                    <td>{core.formatDate(p.estimate_arrival_date)}</td>
                                    <td>{core.formatDate(p.arrival_date)}</td>
                                    <td>{p.status}</td>
                                    <td>{p.comment}</td>
                                    <td>
                                        <div className={"table-actions-section"}>
                                            <button className={"table-actions-button"} onClick={() => {
                                                setOptionsDropdown({show: true, id: p.id});
                                            }}><MoreVertIcon/></button>
                                            {(optionsDropdown.show && optionsDropdown.id === p.id) &&
                                                <div className={"options-dropdown-box"}>
                                                    <OutsideClickHandler onOutsideClick={() => {
                                                        setOptionsDropdown({show: false, id: 0})
                                                    }}>
                                                        <ul className={"table-options-list"}>
                                                            <li onClick={() => {
                                                                setActive({...p});
                                                                setAction("edit");
                                                                setOptionsDropdown({show: false, id: 0});
                                                                setShowCreateFlightModal(true)
                                                            }}>რედაქტირება
                                                            </li>
                                                            <li onClick={() => {
                                                                setShowDeleteDialog(false);
                                                                setActive({...p});
                                                                setOptionsDropdown({show: false, id: 0});
                                                            }}>გზავნილების ჩამოტვირთვა
                                                            </li>
                                                            <li onClick={() => {
                                                                setShowDeleteDialog(true);
                                                                setOptionsDropdown({show: false, id: 0});
                                                                setActive({...p});
                                                            }}>წაშლა
                                                            </li>
                                                        </ul>
                                                    </OutsideClickHandler>
                                                </div>}

                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                    }
                    {
                        data.length === 0 && (
                            <tr>
                                <td colSpan={9} style={{padding: "12px 0"}}>
                                    <p style={{width: "100%", textAlign: "center"}}>{language[lang].myPage.myParcels.recordsNotFound}</p>
                                </td>
                            </tr>
                        )
                    }
                    </tbody>
                </Table>
                <div className={"pagination-container"}>
                    <p>Total: {total}</p>
                    <Stack spacing={2}>
                        <Pagination count={Math.ceil(total / limit)} size="large" page={page} onChange={(e, v) => setPage(v)}/>
                    </Stack>
                </div>
            </div>

            <AddNewFlight action={action} show={showCreateFlightModal} setShow={setShowCreateFlightModal} data={active} loadFlights={loadFlights}/>
            <DialogBox open={showDeleteDialog} setOpen={setShowDeleteDialog} agree={handlers.removeFlight}
                       cancelText={"No"} agreeText={"Yes"} title={"Delete flight"}
                       description={`Do you want to delete flight ${active.flight_id}`}/>
        </>
    )
}

export default Flights