import {Modal} from "react-bootstrap";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import core from '../../core';
import {useSelector} from "react-redux";
import {useEffect} from "react";

const ViewParcel = ({data, show, setShow, lang, language, role}) => {
    const warehouse_receive_date = core.formatDate(data.warehouse_receive_date);

    return(
        <Modal show={show} onHide={() => setShow(false)} centered size={"lg"}>
            <Modal.Header><h4>{language[lang].myPage.myParcels.tracking}: {data.tracking}</h4></Modal.Header>
            <Modal.Body>
                <div className={"view-parcel-details-modal"}>
                    <ul className={"parcels-details-list"}>
                        <li><p>{language[lang].myPage.myParcels.userId}: </p><p>{data.user_id}</p></li>
                        {role !== "user" &&
                            <li><p>{language[lang].myPage.myParcels.shelf_id}: </p><p>{data.shelf_number}</p></li>}
                        {role !== "user" && <li><p>{language[lang].myPage.myParcels.custom_status}: </p>
                            <p>{language[lang].myPage.myParcels[data.custom_process_status]}</p></li>}
                        {role !== "user" && <li><p>პრობლემის ტიპი: </p>
                            <p>{data.problem_type}</p></li>}
                        {role !== "user" && <li><p>პრობლემის სტატუსი: </p>
                            <p>{data.problem_status}</p></li>}
                        {role !== "user" && <li><p>აქვს პრობლემა: </p>
                            <p>{data.has_problem ? language[lang].myPage.myParcels.yes : language[lang].myPage.myParcels.no}</p></li>}
                        <li><p>{language[lang].myPage.myParcels.receiver}: </p><p>{data.receiver}</p></li>
                        <li><p>{language[lang].myPage.myParcels.status}: </p>
                            <p>{data.status && language[lang].myPage.myParcels[data.status.replace(" ", "_")]}</p></li>
                        <li><p>{language[lang].myPage.myParcels.flightId}: </p><p>{data.flight_id}</p></li>
                        <li><p>{language[lang].myPage.myParcels.website}: </p><p>{data.website}</p></li>
                        <li><p>{language[lang].myPage.myParcels.warehouseReceiveDate}: </p>
                            <p>{warehouse_receive_date}</p></li>
                        <li><p>{language[lang].myPage.myParcels.price}: </p><p>{data.price} {data.currency}</p></li>
                        <li><p>{language[lang].myPage.myParcels.weight}: </p><p>{data.weight}</p></li>
                        <li><p>{language[lang].myPage.myParcels.debtGel} </p><p>{data.debt}₾</p></li>
                        <li><p>{language[lang].myPage.myParcels.debtUsd}: </p><p>${data.debt_in_usd}</p></li>
                        <li><p>{language[lang].myPage.myParcels.insurance}: </p>
                            <p>{data.insurance ? language[lang].myPage.myParcels.yes : language[lang].myPage.myParcels.no}</p>
                        </li>
                        {data.insurance && <li><p>Price of insurance: </p><p>{data.insurance_debt}₾</p></li>}
                        <li><p>{language[lang].myPage.myParcels.danger}: </p>
                            <p>{data.is_danger ? language[lang].myPage.myParcels.yes : language[lang].myPage.myParcels.no}</p>
                        </li>
                        <li><p>{language[lang].myPage.myParcels.invoice}: </p> {data.file_url == null ?
                            <p>{language[lang].myPage.myParcels.noFile}</p> :
                            <a href={data.file_url} style={{cursor: "pointer"}}
                               target={"_blank"}><CloudDownloadIcon/></a>}</li>
                        <li><p>{language[lang].myPage.myParcels.description}: </p><p>{data.description}</p></li>
                        <li><p>{language[lang].myPage.myParcels.country}: </p>
                            <p>{language[lang].myPage.myParcels[data.country]}</p></li>
                        <li><p>{language[lang].myPage.myParcels.receiverIdNumber}: </p><p>{data.receiver_id_number}</p>
                        </li>
                        <li><p>{language[lang].myPage.myParcels.receiverPhone}: </p><a
                            href={`tel:${data.receiver_phone}`}>{data.receiver_phone}</a></li>
                        {
                            data.another_taker === true && <>
                                <li><p>{language[lang].myPage.myParcels.trustee}: </p><p>{data.trusteer_fullname}</p>
                                </li>
                                <li><p>{language[lang].myPage.myParcels.trusteePhone}: </p><a
                                    href={`tel:${data.trusteer_phone}`}>{data.trusteer_phone}</a></li>
                                <li><p>{language[lang].myPage.myParcels.trusteeIdNumber}: </p>
                                    <p>{data.trusteer_id_number}</p>
                                </li>
                            </>
                        }
                        <li><p>{language[lang].myPage.myParcels.comment}: </p><p>{data.comment}</p>
                        </li>
                    </ul>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ViewParcel