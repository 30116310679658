import "./Footer.css"
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import language from "../store/language";
import { notificationActions } from "../store/notification";
import axios from "axios";
import { useState } from "react";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

const Footer = ({isMyPage}) => {
    const lang = useSelector((state) => state.user.language);
    const [email, setEmail] = useState("");
    const dispatch = useDispatch();

    const subscribe = (e) => {
        e.preventDefault();
        axios.post('/api/subscribe', {email})
            .then((res) => {
                dispatch(notificationActions.setNotification({show: true, message: res.data.message[lang], type: "success"}));
                setTimeout(() => {
                    dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                }, 4000);
                setEmail("");
            })
            .catch((err) => {
                dispatch(notificationActions.setNotification({show: true, message: err.response.data.message[lang], type: "error"}));
                setTimeout(() => {
                    dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                }, 4000)
            })
    }

    return (
        <div className={isMyPage ? "my-page-header-container" : ""}>
            <footer className={`footer-container ${isMyPage ? "header-on-my-page" : ""}`}>
                <div className={"footer-content"}>
                    <div className={"footer-column reserved-column"}>
                        <Link to={"/"}><img className={"footer-logo"} src={"/static/icons/logo.png"}
                                            alt={"footer logo"}/></Link>
                        <p className={"all-rights"}>© 2024 SimplePost {language[lang].mainPage.footer.allRights}.</p>
                        <p className="working-hours">ორშაბათი-პარასკევი 10:00-18:00 <br /> შაბათი 10:00-16:00 კვირა დასვენება</p>
                    </div>
                    <div className={"footer-column information-column"}>
                        <p className={"footer-title"}>{language[lang].mainPage.footer.info}</p>
                        <ul>
                            <li><Link to={"/about-us"}>{language[lang].mainPage.footer.about}</Link></li>
                            <li><Link to={"/blogs"}>{language[lang].mainPage.footer.blogs}</Link></li>
                            <li><Link to={"/services"}>{language[lang].mainPage.footer.services}</Link></li>
                            <li><Link to={"/terms-and-conditions"}>{language[lang].mainPage.footer.terms}</Link></li>
                            <li><Link to={"/privacy-policy"}>{language[lang].mainPage.footer.privacy}</Link></li>
                        </ul>
                    </div>
                    <div className={"footer-column helpful-info-column"}>
                        <p className={"footer-title"}>{language[lang].mainPage.footer.helpful}</p>
                        <ul>
                            <li><Link to={"/contact-us"}>{language[lang].mainPage.footer.contact}</Link></li>
                            <li><Link to={"/info/useful-information/faq"}>{language[lang].mainPage.footer.faq}</Link></li>
                            <li><Link to={"/careers"}>{language[lang].mainPage.footer.careers}</Link></li>
                        </ul>
                    </div>
                    <div className={"footer-column subscribe-column"}>
                        <p className={"footer-title"}>{language[lang].mainPage.footer.subscribeNews}</p>
                        <form className={"subscription-box"} onSubmit={subscribe}>
                            <input required type={"email"} onChange={(e) => setEmail(e.target.value)} value={email} placeholder={language[lang].mainPage.footer.placeholder}
                                   className={"subscription-box-input"}/>
                            <button type="submit" className={"subscribe-us-button"}>{language[lang].mainPage.footer.button}</button>
                        </form>
                        <div style={{marginTop: "15px", marginBottom: "35px", display: "flex", alignItems: "center"}}>
                            <div
                                 className={"facebook-background"}>
                                <img className={"facebook-icon"} src={"/static/icons/facebook.svg"} alt={"facebook"}/>
                            </div>
                            <p style={{color: "white", fontWeight: "bold", marginLeft: "5px"}}>SimplePost</p>
                        </div>
                        <a href="tel:0322445455" style={{textDecoration: "none"}}>
                            <LocalPhoneIcon style={{color: "yellow-green", fontSize: "32px"}} />
                            <span style={{color: "white", fontWeight: "bold", fontSize: "17px"}}>032 2 445 455</span>
                        </a>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Footer