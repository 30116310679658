import {useEffect, useState} from "react";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import {Table} from "react-bootstrap";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import OutsideClickHandler from "react-outside-click-handler";
import {Pagination, Stack} from "@mui/material";
import DialogBox from "../../../components/DialogBox";
import ViewUsers from "../Components/ViewUsers";
import EditUsers from "../Components/EditUsers";
import language from "../../../store/language";
import {useSelector, useDispatch} from "react-redux";
import { notificationActions } from "../../../store/notification";

const Users = () => {
    const dispatch = useDispatch();
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [keyword, setKeyword] = useState("");
    const [position, setPosition] = useState("all");
    const [data, setData] = useState([]);
    const [searchFieldValue, setSearchFieldValue] = useState("");
    const [active, setActive] = useState({});
    const [total, setTotal] = useState(0);
    const [optionsDropdown, setOptionsDropdown] = useState({show: false, id: 0});
    const [showEditUserModal, setShowEditUserModal] = useState(false);
    const [showUserData, setShowUserData] = useState(false);
    const lang = useSelector((state) => state.user.language);
    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
    const [showChangeActiveStatus, setShowChangeActiveStatus] = useState(false);

    const loadUsers = () => {
        axios(`/api/admin/users?limit=${limit}&page=${page}&keyword=${keyword}&position=${position}`)
            .then((res) => {
                const users = res.data.data;
                setData([...users]);
                setTotal(res.data.total);
            })
            .catch((err) => {

            })
    }

    useEffect(loadUsers, [limit, page, keyword, position]);

    const handlers = {
        resetPassword (u){
            axios.put(`/api/admin/users/${u.user_id}/reset-password`)
                .then(() => {
                    dispatch(notificationActions.setNotification({show: true, message: "ახალი პაროლი გაგზავნილია ელ. ფოსტაზე", type: "success"}));
                    setTimeout(() => {
                        dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                    }, 4000);
                    setShowResetPasswordModal(false);
                })
                .catch(() => {
                    dispatch(notificationActions.setNotification({show: true, message: "Something went wrong", type: "error"}));
                    setTimeout(() => {
                        dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                    }, 4000);
                    setShowResetPasswordModal(false);
                })
        },
        changeActiveStatus(){
            axios.put(`/api/users/${active.user_id}/active-status`, {status: active.active == 0 ? "active" : "inactive"})
                .then((res) => {
                    setShowChangeActiveStatus(false);
                    dispatch(notificationActions.setNotification({show: true, message: res.data.message.geo, type: "success"}));
                    setTimeout(() => {
                        dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                    }, 4000);
                    loadUsers();
                })
                .catch(() => {
                    setShowChangeActiveStatus(false);
                    dispatch(notificationActions.setNotification({show: true, message: "Something went wrong", type: "error"}));
                    setTimeout(() => {
                        dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                    }, 4000)
                })
        }
    }

    return (
        <>
            <h3 style={{marginBottom: "35px"}}>Users</h3>
            <div style={{width: "100%"}}>
                <div className={"data-table-header"}>
                    <div className={"search-field-container"}>
                        <input type={"text"}
                               onKeyUp={(e) => {
                                   if (e.keyCode === 13) {
                                       setKeyword(searchFieldValue);
                                   }
                               }}
                               onChange={(e) => setSearchFieldValue(e.target.value)}
                               placeholder={"Enter keyword"}
                               className={"search-field"}/>
                        <button><SearchIcon onClick={() => setKeyword(searchFieldValue)} style={{color: "white"}}/>
                        </button>
                    </div>
                </div>
                <Table responsive style={{width: "100%"}} className={"data-table"}>
                    <thead>
                    <tr>
                        <th>ოთახის#</th>
                        <th>სახელი</th>
                        <th>მომხ.სახელი</th>
                        <th>ელ.ფოსტა</th>
                        <th>ტელეფონი</th>
                        <th>დავალიანება</th>
                        <th>ქალაქი</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        data.map((p, i) => {
                            return (
                                <tr className={`${i % 2 === 1 ? 'odd-row' : 'even-row'} table-rows`}
                                    key={p.id}>
                                    <td className={p.active == 0 ? "inactive-user-row" : ""} style={{paddingLeft: "5px"}}>{p.user_id}</td>
                                    <td className={p.active == 0 ? "inactive-user-row" : ""}>{p.user_type === "legal" ? p.company_name_geo : `${p.name_geo} ${p.surname_geo}`}</td>
                                    <td className={p.active == 0 ? "inactive-user-row" : ""}>{p.username}</td>
                                    <td className={p.active == 0 ? "inactive-user-row" : ""}>{p.email}</td>
                                    <td className={p.active == 0 ? "inactive-user-row" : ""}>{p.phone}</td>
                                    <td className={p.active == 0 ? "inactive-user-row" : ""}>{p.debt}</td>
                                    <td className={p.active == 0 ? "inactive-user-row" : ""}>{p.city}</td>
                                    <td className={p.active == 0 ? "inactive-user-row" : ""}>
                                        <div className={"table-actions-section"}>
                                            <button className={"table-actions-button"} onClick={() => {
                                                setOptionsDropdown({show: true, id: p.id});
                                            }}><MoreVertIcon style={{color: p.active == 0 ? "white" : "black"}}/></button>
                                            {(optionsDropdown.show && optionsDropdown.id === p.id) &&
                                                <div className={"options-dropdown-box"}>
                                                    <OutsideClickHandler onOutsideClick={() => {
                                                        setOptionsDropdown({show: false, id: 0})
                                                    }}>
                                                        <ul className={"table-options-list"}>
                                                            <li onClick={() => {
                                                                setShowUserData(true);
                                                                setActive({...p});
                                                                setOptionsDropdown({show: false, id: 0});
                                                            }}>ნახვა
                                                            </li>
                                                            <li onClick={() => {
                                                                setShowEditUserModal(true);
                                                                setActive({...p});
                                                                setOptionsDropdown({show: false, id: 0});
                                                            }}>რედაქტირება
                                                            </li>
                                                            <li onClick={() => {
                                                                setShowResetPasswordModal(true);
                                                                setActive({...p});
                                                                setOptionsDropdown({show: false, id: 0});
                                                            }}>პაროლის აღდგენა
                                                            </li>
                                                            <li onClick={() => {
                                                                setShowChangeActiveStatus(true);
                                                                setActive({...p});
                                                                setOptionsDropdown({show: false, id: 0});
                                                            }}>{p.active == 0 ? "გააქტიურება" : "გაპასიურება"}
                                                            </li>
                                                        </ul>
                                                    </OutsideClickHandler>
                                                </div>}

                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                    }
                    {
                        data.length === 0 && (
                            <tr>
                                <td colSpan={9} style={{padding: "12px 0"}}>
                                    <p style={{width: "100%", textAlign: "center"}}>{language[lang].myPage.myParcels.recordsNotFound}</p>
                                </td>
                            </tr>
                        )
                    }
                    </tbody>
                </Table>
                <div className={"pagination-container"}>
                    <p>Total: {total}</p>
                    <Stack spacing={2}>
                        <Pagination count={Math.ceil(total / limit)} size="large" page={page} onChange={(e, v) => setPage(v)}/>
                    </Stack>
                </div>
                <ViewUsers show={showUserData} setShow={setShowUserData} data={active}/>
                <EditUsers show={showEditUserModal} setShow={setShowEditUserModal} data={active} reload={loadUsers}/>
                <DialogBox description={"ნამდვილად გსურთ პაროლის აღდგენა?"} title={"პაროლის აღდგენა"} cancelText={"არა"}
                       open={showResetPasswordModal} setOpen={setShowResetPasswordModal} agreeText={"დიახ"}
                       agree={handlers.resetPassword}/>
                <DialogBox description={"ნამდვილად გსურთ მომხმარებლის აქტიურობის სტატუსის შეცვლა?"} title={"აქტიურობის სტატუსის ცვლილება"} cancelText={"არა"}
                       open={showChangeActiveStatus} setOpen={setShowChangeActiveStatus} agreeText={"დიახ"}
                       agree={handlers.changeActiveStatus}/>
            </div>
        </>
    )
}

export default Users