import React from "react";
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import axios from "axios";
import {Link} from "react-router-dom";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import language from "../store/language";
import {useDispatch, useSelector} from "react-redux";
import { notificationActions } from "../store/notification";

const LoggedInAvatar = () => {
    const lang = useSelector((state) => state.user.language);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const dispatch = useDispatch();

    const handlers = {
        logout() {
            axios.post('/api/logout')
                .then(() => {
                    window.location.href = '/';
                })
                .catch((err) => {
                    dispatch(notificationActions.setNotification({show: true, message: err.response.data.message[lang], type: "error"}));
                    setTimeout(() => {
                        dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                    }, 4000)
                })
        }
    }

    return (
        <React.Fragment>
            <Box sx={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
                <Tooltip title="Account settings">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ml: 2}}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <Avatar sx={{width: 32, height: 32}}>M</Avatar>
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            >
                <MenuItem>
                    <Link className={"profile-dropdown-link"} to={"/my-page?content=inbox"}>
                        <div className={"profile-dropdown-rounded"}><MailOutlineIcon style={{color: "white"}}/></div>
                        <span>{language[lang].profileDropdown.inbox}</span></Link>
                </MenuItem>
                <MenuItem>
                    <Link className={"profile-dropdown-link"} to={"/my-page?content=payments"}>
                        <div className={"profile-dropdown-rounded"}><CreditCardIcon style={{color: "white"}}/></div>
                        <span>{language[lang].profileDropdown.payments}</span></Link>
                </MenuItem>
                <Divider/>
                <MenuItem onClick={handleClose}>
                    <Link to={"/my-page/profile"} style={{color: "black", display: "flex", alignItems: "center", textDecoration: "none"}}>
                        <ListItemIcon>
                            <Settings fontSize="small"/>
                        </ListItemIcon>
                        {language[lang].profileDropdown.setting}
                    </Link>
                </MenuItem>
                <MenuItem onClick={handlers.logout}>
                    <ListItemIcon>
                        <Logout fontSize="small"/>
                    </ListItemIcon>
                    {language[lang].profileDropdown.logout}
                </MenuItem>
            </Menu>
        </React.Fragment>
    )
}

export default LoggedInAvatar