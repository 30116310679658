const TermsAndCond = () => {
    return(
        <div className="terms-and-conditions-box">
            <h1>მომსახურების ძირითადი პირობები</h1>
            <p><strong>შემსრულებელი:</strong> შპს „სიმფელ ფოსტ“</p>
            <p><strong>ვებ-გვერდი:</strong> <a href="http://www.simplepost.ge">www.simplepost.ge</a></p>
            <p><strong>მისამართი:</strong> ქ. თბილისი, რიჩარდ ჰოლბრუკის N4. </p>
            <p><strong>ტელეფონი:</strong> (032) 2 44 54 55</p>
            <p><strong>მომხმარებელი:</strong> ფიზიკური ან იურიდიული პირი რომელიც სარგებლობს შპს „სიმფელ ფოსტ“-ის სერვისით.</p>

            <div className="section">
                <h2>1. რეგისტრაცია</h2>
                <p>1.1. www.simplepost.ge -ზე რეგისტრაციის გავლისას მომხმარებელი ვალდებულია გაეცნოს და დაეთანხმოს კომპანიის წესებს და პირობებს. ღილაკზე „გავეცანი და ვეთანხმები“ ფანჯრის მონიშვნით მომხმარებელი ადასტურებს, როგორც წესებისა და პირობების, ასევე კონფიდენციალურობის პოლიტიკის სრულად გაცნობას. შპს „სიმფელ ფოსტ“-ის ვებგვერდზე (www.simplepost.ge) გამოქვეყნებული მომსახურების წესები და პირობები და კონფიდეციალურობის პოლიტიკა საჯაროა.</p>
                <p>1.2. „სიმფელ ფოსტ“-ის ვებგვერდზე რეგისტრაციისას მომხმარებელს ენიჭება ინდივიდუალური კოდი, „ოთახის ნომერი“.</p>
                <p>1.3. მომხმარებელი ვალდებულია „სიმფელ ფოსტ“-ის გვერდზე რეგისტრაციისას მიუთითოს სწორი მონაცემები. არასწორი ან სხვა პირის მაიდენტიფიცირებელი ინფორმაციის მითითება შესაძლებელია გახდეს რეგისტრაციის გაუქმების საფუძველი.</p>
                <p>1.4. მომხმარებლის სახელისა და გვარის შეცვლა რეგისტრაციის შემდეგ შეუძლებელია. აღნიშნულის საჭიროების შემთხვევაში მომხმარებელმა უნდა მიმართოს კომპანიას.</p>
                <p>1.5. მომხმარებლის მიერ რეგისტრაციის დროს მოწოდებული ინფორმაცია კონფიდენციალურია, გარდა იმ შემთხვევისა, როდესაც კანონი მოითხოვს ასეთი ინფორმაციის გასაჯაროებას ან მისი სხვა ფორმით გადაცემას.</p>
                <p>1.6. რეგისტრაციის გავლის შემდეგ, მომხმარებელი ვალდებულია დაიცვას საკუთარი სარეგისტრაციო მონაცემების კონფიდენციალურობა, არ გაამჟღავნოს, არ გადასცეს მესამე პირებს და თავად აკონტროლოს მისი მონაცემების გამოყენების შესაძლებლობა.</p>
                <p>1.7. წინამდებარე მომსახურების პირობები მოქმედებს საქართველოში ტრანსპორტირებისთვის განკუთვნილი ამანათის შპს „სიმფელ ფოსტ“-ისათვის მიწოდებიდან და წყვეტს მოქმედებას ნივთის ადრესატისთვის მიწოდებისთანავე. შემსრულებელი უზრუნველყოფს მომხმარებლის ტვირთის ტრანსპორტირების ორგანიზებას. წინამდებარე პუნქტით გათვალისწინებული მომსახურების გაწევის მისამართი/ლოკაცია მითითებულია შპს „სიმფელ ფოსტ“-ის ვებ-გვერდზე.</p>
            </div>

            <div className="section">
                <h2>2. ხელშეკრულების საგანი</h2>
                <p>2.1. შპს „სიმფელ ფოსტ“-ი თავისი სახელით და მომხმარებლის ხარჯზე ახორციელებს ამანათების ტრანსპორტირებასთან დაკავშირებულ მოქმედებებს, აღნიშნული მომსახურების სანაცვლოდ, მომხმარებელი, ამანათის მიღებამდე, ვალდებულია გადაიხადოს ტრანსპორტირების შესაბამისი ფასი. მომსახურება გულისხმობს შპს „სიმფელ ფოსტ“-ის ვებ-გვერდზე განთავსებულ ქვეყნის მისამართზე მიტანილი მომხმარებლის კუთვნილი ნივთის ტრანსპორტირებას დანიშნულების ადგილამდე (მომხმარებლის მიერ მითითებულ მისამართამდე.) და მიწოდებას ადრესატისთვის.</p>
            </div>

            <div className="section">
                <h2>3. შპს „სიმფელ ფოსტ“-ის პირობები ნივთის ტრანსპორტირებისას</h2>
                <p>3.1. შპს „სიმფელ ფოსტ“-ის ვებ-გვერდზე განთავსებულ ქვეყნის მისამართზე მისული ამანათი, რომელზეც დატანილია მომხმარებლის სახელი, გვარი და ოთახის ნომერი მიიჩნევა ამ პირის საკუთრებად და იგზავნება საქართველოში.</p>
                <p>3.2. მომხმარებლის მითითების საფუძველზე არ ხდება ამანათების შეყოვნება ან დატოვება რომელიმე ქვეყნის საწყობში.</p>
                <p>3.3. კომპანია არ ამოწმებს საწყობში მიტანილი ტვირთის შიგთავსის მდგომარეობას.</p>
                <p>3.4. ამანათის დეკლარირება სავალდებულოა. დეკლარირება გულისხმობს მომხმარებლის პირად ანგარიშზე - „ოთახზე“ ამანათის ასახვისას ან წინასწარი დეკლარირებისას მომხმარებლის ვალდებულებას, მიუთითოს პროდუქციის გამომგზავნის დასახელება, ამანათის აღწერა და ფასი. ასევე, საჭიროების შემთხვევაში წარმოადგინოს ნივთის შესყიდვის დამადასტურებელი დოკუმენტი - ინვოისი.</p>
                <p>3.5. მომხმარებელი ვალდებულია გადაიხადოს ტრანსპორტირების ღირებულება, მიუხედავად იმისა, გზავნილი წარმოადგენს თუ არა გამყიდველის/გამომგზავნის შეცდომას, თუ მიმღებს აღარ სურს ნივთის მიღება.</p>
                <p>3.5.1. მომხმარებლის კომპანიის მიმართ სატრანსპორტო დავალიანების არსებობის შემთხვევაში, კომპანია იტოვებს უფლებას არ გასცეს სხვა ჩამოსული ამანათები ძველი დავალიანების დაფარვამდე.</p>
                <p>3.6. შპს „სიმფელ ფოსტ“-ი მომხმარებელს სთავაზობს საკურიერო სერვისს. აღნიშნულის მოთხოვნა მომხმარებელს შეუძლია პირადი ანგარიშიდან.</p>
                <p>3.7. თუ ტრანსპორტირებისას ტვირთს სჭირდება სპეციალური პირობები ან მაღალია ტრანსპორტირებისას ნივთის დაზიანების რისკი, ან შეიცავს სახიფათო ტვირთს, მომხმარებელი ვალდებულია შეათანხმოს პირობები გადამზიდავთან.</p>
                <p>3.8. დაუშვებელია ისეთი ნივთების ტრანსპორტირება, რომელთა გადაზიდვა აკრძალულია ავიაკომპანიის მიერ. შპს „სიმფელ ფოსტ“-ი იხსნის პასუხისმგებლობას ასეთი პროდუქციის ტრანსპორტირებით დამდგარ შედეგებზე.</p>
                <p>3.9. კომპანიის უცხოური მისამართიდან ამანათები იგზავნება ვებ-გვერდზე მითითებული განრიგის შესაბამისად. განრიგის ცვლილებასთან დაკავშირებით მომხმარებელს ინფორმაცია მიეწოდება ვებგვერდის საშუალებით.</p>
                <p>3.10. ტრანსპორტირებისთვის განკუთვნილი ამანათი უნდა იყოს სათანადოდ შეფუთული, საერთაშორისო გადაზიდვისთვის (ამანათის შეფუთვისას გათვალისწინებული უნდა იყოს შესაძლო გარეგანი ზემოქმედების ფაქტორები - წონა, ტენიანობა და ა.შ., ასევე, ნივთი საჭიროების შემთხვევაში უნდა იყოს უზრუნველყოფილი დამატებითი შეფუთვით, ყუთი უნდა იყოს დალუქული წებოვანი ლენტით ან თოკით და ა.შ).</p>
                <p>3.11. ამანათის ფილიალში ან საკურიერო სერვისით მიღებისას მომხმარებელი ვალდებულია შეამოწმოს გზავნილი კომპანიის წარმომადგენლის თანდასწრებით. ფილიალიდან უკვე გატანილ ან საკურიერო სერვისით მიღებულ ამანათზე, რომლის გახსნასაც არ ესწრებოდა კომპანიის თანამშრომელი, პრეტენზია აღარ მიიღება.</p>
                <p>3.12. კომპანიის პასუხისმგებლობა ნივთის დაკარგვის ან დაზიანების შემთხვევაში განისაზღვრება შემდეგი წესით:</p>
                <ul>
                    <li>კომპანია პასუხისმგებელია ნივთის რეალურ ღირებულებაზე, იმ შემთხვევაში თუ მომხმარებელს აქვს წარმოდგენილი ნივთის შესყიდვის დამადასტურებელი დოკუმენტი - ინვოისი.</li>
                    <li>ამანათის დაკარგვის ან დაზიანების შემთხვევაში, მომხმარებელმა უნდა წარადგინოს ნივთის ღირებულების დამადასტურებელი დოკუმენტი. წინააღმდეგ შემთხვევაში კომპანია პასუხისმგებელია ერთ ამანათზე არაუმეტეს 100 ლარის ოდენობით.</li>
                </ul>
            </div>
            <div className="section">
            <h2>4. მხარეთა პასუხისმგებლობა</h2>
<p>4.1. „სიმფელ ფოსტ“-ის პასუხისმგებლობის ფარგლები:</p>
<p>4.1.1. კომპანია პასუხს არ აგებს არასათანადო შეფუთვის გამო ნივთის დაზიანებისთვის.
შიგთავსის მთლიანობაზე პასუხისმგებლობას არ იღებს კომპანია.</p>
<p>4.1.2. „სიმფელ ფოსტ“-ი პასუხს არ აგებს დაზიანებული ნივთის გამო, თუ პროდუქცია
შეძენილია კერძო პირისგან ან სხვა ტიპის აუქციონზე, ასევე ჩამოწერილ, შეკეთებულ ან
მეორადი მოხმარების ნივთებზე.</p>
<p>4.1.3. ნივთის ტრანსპორტირების შესაძლებლობა, რომელიც საჭიროებს
ტრანსპორტირებისთვის შესაბამის სპეციალურ პირობებს, უნდა შეთანხმდეს
შემსრულებელთან. შეთანხმების არარსებობის შემთხვევაში, ნივთის
შენახვის/ტრანსპორტირების პირობების დარღვევით წარმოშობილი ზიანის გამო
კომპანია პასუხს არ აგებს.</p>
<p>4.1.4. „სიმფელ ფოსტ“-ი პასუხისმგებელია მომხმარებლის წინაშე განზრახ ან
გაუფრთხილებელი მოქმედებით გამოწვეული მატერიალური ზიანისთვის (შესაბამისი
მტკიცებულებების არსებობისას), რომელიც გამოწვეულია ნივთის დაკარგვის, მთლიანი
ან ნაწილობრივი დაზიანებით. ანაზღაურება არ მოხდება თუ ტვირთი დაზიანებული
მივიდა საწყობში, მომხმარებელმა ზიანის არსებობის შესახებ განაცხადა მას მერე, რაც
დატოვა კომპანიის ფილიალი/არ გადაამოწმა კურიერის თანდასწრებით, ან თუ
ვიზუალურად ამანათის შეფუთვას არ აღენიშნება გარეგნული დაზიანება. განხილვის
შემდეგ კომპანია იღებს გადაწყვეტილებას ზიანის ანაზღაურების ან ანაზღაურებაზე</p>

<p>უარის შესახებ და გადაწყვეტილებას აცნობებს მიმღებს. თანხის ანაზღაურება ხდება
ტვირთის ჩამოსვლიდან 30 კალენდარული დღის ვადაში.</p>
<p>4.1.5. შპს „სიმფელ ფოსტ“-ის ბრალის დადგენის შემთხვევაში, ტვირთის დაკარგვის
ან/და დაზიანებისას შემსრულებელი აუნაზღაურებს მომხმარებელზე დამდგარ ზიანს.
ასანაზღაურებელი თანხა განისაზღვრება პროდუქციის ღირებულებით, რაც პროდუქციის
შესყიდვის დამადასტურებელ დოკუმენტშია მითითებული და არ აღემატება
მომხმარებლის მიერ დეკლარირებისას მითითებულ თანხას, ხოლო შემსრულებლის
ბრალის გამორიცხვის შემთხვევაში, მომხმარებელი ვალდებულია გადაიხადოს ამანათის
ტრანსპორტირების ღირებულება.</p>
<p>4.1.6. ნივთები, რომელთა გადაზიდვა აკრძალულია ავიაკომპანიის მიერ ან
საფრთხისშემცველია და ამის შესახებ არ იცის „სიმფელ ფოსტ“-მა, შეიძლება
გადმოიტვირთოს ან/და განადგურდეს კომპანიის მხრიდან ზიანის ანაზღაურების
მოვალეობის დაუკისრებლად, ხოლო ასეთი ნივთის ტრანსპორტირებისას გამოწვეული
ზიანისთვის პასუხისმგებელია შემკვეთი.</p>
<p>4.1.7. იმ შემთხვევაში თუ მომხმარებელი გაგზავნის ავიაკომპანიის მიერ აკრძალულ
პროდუქციას, კომპანია არ იღებს პასუხისმგებლობას
ტვირთის გამომგზავნ-მაღაზიასთან ან კერძო პირთან ტვირთის დაბრუნებაზე ან
უცხოეთში საბაჟოს მხრიდან პროდუქციის კონფისკაციაზე.</p>
<p>4.1.8. თუ მომხმარებლის მიერ მაინც მოხდა ავიაკომპანიის მიერ
აკრძალული/საფრთხისშემცველი პროდუქციის გამოგზავნა, რის გამოც შპს „სიმფელ
ფოსტ“-ს მიადგა მატერიალური ზარალი, კომპანია იტოვებს უფლებას ზიანის
ანაზღაურებისგან დამოუკიდებლად მომხმარებელს დააკისროს საჯარიმო სანქცია.</p>
<p>4.1.9. ავიაკომპანიის მიერ აკრძალული, ან წინასწარ შესათანხმებელი პროდუქტის
ტრანსპორტირებისას, ნივთის დაკარგვასა ან დაზიანებაზე კომპანია პასუხისმგებლობას
არ იღებს.</p>
<p>4.1.10. „სიმფელ ფოსტ“-ი არ იღებს პასუხისმგებლობას ამანათზე, თუ ამანათზე
დატანილია არასწორი ან არასრული მონაცემები. მომხმარებლის მხრიდან მისამართის
ცვლილება დაუშვებელია და ის უნდა ჩაიწეროს ზუსტად ისე, როგორც მოცემულია შპს
„სიმფელ ფოსტ“-ის ვებგვერდზე.</p>
<p>4.1.11. თუ, ამანათს არ აქვს დართული სწორი პარამეტრები მომხმარებლის/მაღაზიის
მიერ, არ ემთხვევა ან აკლია ოთახის ნომერი, სახელი და გვარი, შეცდომით არის
მოწოდებული თრექინგი - შემსრულებელი პასუხს არ აგებს ასეთი ამანათის დროულად
მიწოდებასა და მომხმარებლისთვის გადაცემაზე. პროცედურა გახანგრძლივდება</p>

<p>გაურკვეველი ვადით პროდუქციის სრულ იდენტიფიკაციამდე ინვოისისა და ამანათის
შემადგენლობის საფუძველზე.</p>
<p>4.1.12. „სიმფელ ფოსტ“-ი არ იღებს პასუხისმგებლობას იმ ამანათებზე, რომელთა
უცხოეთის ოფისში მიტანას ახორციელებს კომპანია, რომელსაც არ აქვს ამანათის
ჩაბარების სავალდებულო ხელმოწერით დადასტურების სერვისი და შესაბამისად
სადავო სიტუაციის წარმოქმნისას, ასეთი კომპანიები ვერ დაადასტურებენ „სიმფელ
ფოსტ“-ის საწყობში ტვირთის მიტანას. „სიმფელ ფოსტ“-ი არ იღებს პასუხისმგებლობას
გაურკვეველ/დაუზუსტებელ მისამართზე მიტანილ ამანათზე, ასევე იმ ამანათებზე,
რომლის ჩაბარებაზე ფიქსირდება უცხო პირის ხელმოწერა. კომპანია არ იღებს
პასუხისმგებლობას დაუზუსტებელ მისამართზე, მეილბოქსში ან რომელიმე სხვა
წერტილში დატოვებულ ამანათებზე, ქუჩაში მიტანილ ან კართან დატოვებულ
ამანათებზე.</p>
<p>4.1.13. „სიმფელ ფოსტ“-ი არ აგებს პასუხს საბაჟო ორგანოების მიერ მიღებულ ნებისმიერ
გადაწყვეტილებაზე, რომელიც ამ ორგანოს მიერ იქნება მიღებული საბაჟო კონტროლს
დაქვემდებარებული გზავნილების მიმართ.</p>
<p>4.1.14. „სიმფელ ფოსტ“-ი პასუხს არ აგებს თუ ტვირთის დაკავება, კონფისკაცია,
დაყადაღება, მოხდება საბაჟო სამსახურების ან/და ნებისმიერი სახელმწიფო
სამსახურების მიერ ტვირთის შემცველობის ან/და მომხმარებლის მიერ არასწორად
დეკლარირებული დოკუმენტების, განბაჟების საფასურის გადაუხდელობის გამო.
შემსრულებელი არ არის პასუხისმგებელი მომხმარებლისათვის მიყენებული
ზიანისათვის, თუ აღნიშნული გამოწვეულია მომხმარებლის მხრიდან არასწორი,
არასრული ან სხვა არასარწმუნო ინფორმაციის მიწოდების შედეგად.</p>
<p>4.1.15. იმ შემთხვევაში, თუ საბაჟო სამსახური ან ფინანსთა სამინისტრო დააკისრებს
საჯარიმო სანქციას კომპანიას მომხმარებლის მიერ მომსახურების პირობების მთლიანად
ან ნაწილობრივ შეუსრულებლობის გამო (მაგ.: პროდუქციის არასრული ან არაზუსტი
დეკლარირება), კომპანია უფლებას იტოვებს აღნიშნული ჯარიმის გადახდა დააკისროს
მომხმარებელს.</p>
<p>4.1.16. იმ შემთხვევაში, თუ, მოხდება შემსრულებლის დაჯარიმება ან/და
შემსრულებლისთვის სხვა სახის ზიანის მიყენება მომხმარებლის მიერ მომსახურების
პირობების ან/ და ხელშეკრულების სხვა პირობების მთლიანად ან ნაწილობრივ
შეუსრულებლობის გამო (მაგ: პროდუქციის არასრული ან არაზუსტი დეკლარირება და
სხვა) შემსრულებელს უფლება აქვს მიყენებული ზიანის ანაზღაურება დააკისროს
მომხმარებელს.</p>

<p>4.1.17. დაუძლეველი ძალით გამოწვეული ზიანის შემთხვევაში კომპანიის
პასუხისმგებლობა გამოირიცხება;</p>
<p>4.1.18. კომპანია „სიმფელ ფოსტ“-ი პასუხისმგებლობას არ იღებს შეცდომით
გამოგზავნილ პროდუქციაზე (ფერი / ზომა/რაოდენობა/მოცულობა/სახეობა და სხვა);</p>
<p>4.1.19. კომპანია პასუხისმგებლობას არ იღებს რეისების დაგვიანებაზე, რომელიც
შეიძლება იყოს გამოწვეული ავიაკომპანიის ბრალეულობით ან უამინდობის, ტექნიკური
ხარვეზის (თვითმფრინავის გაუმართაობა), ბუნებრივი მოვლენების ან
დღესასწაულებთან დაკავშირებული გადატვირთვების გამო.</p>
            </div>

<div className="section">
<h2>4.2. მომხმარებლის პასუხისმგებლობის ფარგლები:</h2>
<p>4.2.1. მომხმარებელი ვალდებულია დროულად მიაწოდოს კომპანიას შესაბამისი ცნობები
ტვირთის შესახებ, აგრეთვე მისცეს ინფორმაცია, რომლებიც აუცილებელია გადაზიდვის
დოკუმენტების გასაფორმებლად, მიაწოდოს აუცილებელი დოკუმენტები/ინფორმაცია
საბაჟო და სხვა მოქმედებათა შესასრულებლად.</p>
<p>4.2.2. მომხმარებელი ვალდებულია არ გაავრცელოს ცრუ, ზიანის მომტანი ინფორმაცია.
ასეთ შემთხვევაში კომპანია მიმართავს სამართლებრივ ზომებს.</p>
<p>4.2.3. მომხმარებელი ვალდებულია ყველა დეტალი ამანათისა და შიგთავსის, ასევე
შეფუთვის და წონის შესახებ შეათანხმოს და დააზუსტოს გამყიდველთან ნივთის
შეძენამდე.</p>
<p>4.2.4. ამანათის მიღებისას მომხმარებელი ვალდებულია კურიერის/ოპერატორის
თანდასწრებით შეამოწმოს შეფუთვა და გარეგანი დაზიანების შემთხვევაში ადგილზევე
შეატყობინოს პასუხისმგებელ პირს. ინფორმაციას განიხილავს შესაბამისი სამსახური და
მომხმარებელი გონივრულ ვადაში მიიღებს პასუხს. ოფისიდან გასვლის შემდეგ
კომპანია პასუხს არ აგებს ამანათზე.</p>
<p>4.2.5. ნივთის ოპერატორის/კურიერის თანდასწრებით შემოწმებისას თუ დაფიქსირდება
ნივთის დაზიანების ფაქტი და არ დგინდება ნივთის ტრანსპორტირებისას დაზიანება,
მომხმარებელი განცხადებით ადასტურებს, რომ კომპანიასთან პრეტენზია არ გააჩნია და
ხდება ნივთის გაცემა. ხოლო დავის შემთხვევაში კომპანია იტოვებს უფლებას, ნივთი
შეინახოს თავის საწყობში დავის გადაჭრამდე.</p>

<p>4.2.6. მომხმარებელი პასუხისმგებელია მის მიერ მოწოდებული პირადი, საკონტაქტო,
ტვირთის შესახებ ინფორმაციის სიზუსტეზე. შემსრულებელი არ აგებს პასუხს
მომხმარებლის მიერ არასწორად მოწოდებული ინფორმაციით გამოწვეული ზიანისთვის.</p>
<p>4.2.7. მომხმარებელს უფლება აქვს განახორციელოს ნივთის წინასწარი დეკლარირება,
გამომგზავნის მიერ თრექინგ კოდის მინიჭებისთანავე, რათა მის პირად ოთახში
წინასწარ აისახოს ამანათის საწყობში შესვლის შესახებ ინფორმაცია, რაც ხელს შეუწყობს
ამანათის იდენტიფიცირებას საწყობში შესვლისთანავე.</p>
<p>4.2.8. მომხმარებელი ვალდებულია 30 დღის ვადაში გადაიხადოს ტრანსპორტირების
ღირებულება და მიიღოს ამანათი. აღნიშნული ვადის გასვლის შემდგომ კომპანია
იტოვებს უფლებას, დაბეგვრას დაუქვემდებარებელი ამანათები გადაიტანოს ფასიან
საწყობში, რომლის საფასურის გადახდა დაეკისრება მომხმარებელს. ფასიან საწყობში
ნივთის განთავსებიდან 30 დღის ვადაში თუ მომხმარებელი არ მიიღებს ამანათს,
კომპანია განკარგავს მას საკუთარი შეხედულებით, თუმცა აღნიშნული არ
გაათავისუფლებს მომხმარებელს ტრანსპორტირების და სასაწყობე მომსახურების
საფასურის გადახდისგან.</p>
<p>4.2.9. მომხმარებლის მიერ ამანათის მიღებაზე უარის თქმა, გარკვეული გარემოებების
(კომპანიისგან დამოუკიდებელი მიზეზების გამო) ან მომხმარებლის სურვილით ნივთის
სახელმწიფოსთვის გადაცემა არ ათავისუფლებს მომხმარებელს ტრანსპორტირების
ღირებულების გადახდისგან.</p>
</div>

<div className="section">
<h2>5. ტრანსპორტირება და ამანათის შენახვის ვადა</h2>
<p>5.1. ტრანსპორტირების ღირებულება გამოითვლება ამანათის (ამანათების) რეალური ან
მოცულობითი წონიდან.</p>
<p>5.2. ჩინეთიდან ამანათები იგზავნება რეალური წონით.</p>
<p>5.3. მინიმალური წონა განისაზღვრება - 100 გრ-ით.</p>
<p>5.4. ის ამანათი, რომლის მისამართის ან/და ადრესატის მოძიებაც ვერ მოხერხდა,
გადაეცემა სახელმწიფოს შენახვის 30 - დღიანი ვადის დაუცველად.</p>
<p>5.5. ტრანსპორტირების ხანგრძლივობაა საშუალოდ, ნივთის მისამართზე მიტანიდან 7-
დან 10 დღემდე. რეისების განრიგი და ვადები მოცემულია შპს „სიმფელ ფოსტ“-ის ვებ-
გვერდზე. აღნიშნული ვადები არის საორიენტაციო და შეიძლება იყოს ცვალებადი.</p>
</div>

<div className="section">
<h2>6. ამანათის დეკლარირება</h2>
<p>6.1. ამანათის დადეკლარირება (შეძენილი ნივთის ვებგვერდის, დასახელების და ფასის
(ღირებულების) მითითება) „სიმფელ ფოსტ“-ის ვებგვერდზე აუცილებელია ნივთის
საქართველოში ჩამოსვლამდე.</p>
<p>6.2. დეკლარირებასთან ერთად სასურველია მომხმარებელმა ატვირთოს შეძენილი
ნივთების ინვოისები, რათა გამარტივდეს და დაჩქარდეს ამანათებზე საბაჟო
პროცედურების განხორციელება.</p>
<p>6.3. დეკლარირებული თანხა აუცილებლად უნდა ემთხვეოდეს შეძენილი ნივთის
რეალურ ღირებულებას. ინფორმაციის სისწორეზე პასუხისმგებლობა მთლიანად
ეკისრება მომხმარებელს. შევსებულ დეკლარაციაში ცვლილების შეტანასთან
დაკავშირებით მოგვმართეთ ცხელ ხაზზე. შევსებულ დეკლარაციაში ცვლილების შეტანა
შესაძლებელი იქნება ამანათის საქართველოს ტერიტორიაზე შემოსვლამდე.</p>
<p>6.4. იმ შემთხვევაში, თუ ნივთი ექვემდებარება განბაჟებას, ინვოისის ატვირთვა
დეკლარირებისას სავალდებულოა.</p>
<p>6.5. იმ შემთხვევაში, თუ მომხმარებელი არ შეავსებს გზავნილის დეკლარაციას
(საფოსტო გზავნილის ღირებულების დეკლარაციას), გზავნილი გაჩერდება საბაჟოზე.
მომხმარებელმა 8 დღის განმავლობაში უნდა მოახდინოს გზავნილის დეკლარირება
(წარმოადგინოს ინვოისი ან ჩაუტაროს საბაჟო ექსპერტიზა). დაუდეკლარირებლობის
შემთხვევაში მოხდება ნივთის განკარგვა დადგენილი საბაჟო პროცედურების მიხედვით.</p>
</div>

<div className="section">
<h2>7. განბაჟება</h2>
<p>7.1. ტვირთის განბაჟებაზე პასუხისმგებელია მომხმარებელი.</p>
<p>7.2. მზა საბაჟო დეკლარაცია მომხმარებელს გადაეცემა კუთვნილ ამანათთან ერთად.
ბაჟის გადახდა ხორციელდება დეკლარაციის დაბეჭდვიდან 30 (ოცდაათი) კალენდარული
დღის განმავლობაში, ნებისმიერ ბანკში. აღნიშნული წესი არ მოქმედებს გამონაკლის
შემთხვევებში, რაც გათვალისწინებულია საქართველოს კანონმდებლობით.</p>

<p>7.3. ამანათები, რომელთა ჯამური ღირებულება აღემატება 300 ლარს, აღემატება 30
კილოგრამს ან არის განკუთვნილი კომერციული საქმიანობისთვის, საქართველოს
კანონმდებლობით ექვემდებარება განბაჟებას.</p>
<p>7.4. განსაბაჟებელი ამანათები გაიცემა მხოლოდ ოფისებში სპეციალურ დოკუმენტთან
ერთად (ფორმა 4, რომელსაც „სიმფელ ფოსტ“-ი დაგიმზადებთ და გადმოგცემთ
ამანათთან ერთად). სადეკლარანტო მომსახურების ღირებულება ონლაინ
ამანათებისთვის განისაზღვრება - 5 ლარით.</p>
<p>7.5. ამანათის განბაჟება და სხვა საბაჟო პროცედურები რეგულირდება საქართველოს
კანონმდებლობის შესაბამისად, საბაჟო სამსახურების მიერ ამანათის გაჩერების ან/და
მომხმარებლის მიერ ინფორმაციის წარუდგენლობის ან სხვა ვალდებულების
შეუსრულებლობის გამო, ამანათის სახელმწიფო საკუთრებაში გადასვლის,
მომხმარებლისთვის სანქციების დაკისრების შემთხვევაში მომხმარებელი ვერ
წარუდგენს პრეტენზიას შემსრულებელს, რომ მას არ ჰქონდა შესაბამისი ინფორმაცია
შემსრულებლისგან კანონმდებლობით დადგენილი რეგულაციების და დაწესებული
სანქციების შესახებ. მომხმარებელი ვალდებულია დაიცვას საქართველოს
კანონმდებობით დადგენილი რეგულაციები. შემსრულებელს უფლება აქვს მიაწოდოს
მომხმარებელს სათანადო ინფორმაცია საბაჟო პროცედურებთან დაკავშირებით, თუმცა
აღნიშნული არ წარმოადგენს შემსრულებლის ვალდებულებას.</p>
</div>

<div className="section">
<h2>8. ამანათის გაცემა</h2>

<p>8.1. ამანათის მიღება შესაძლებელია „სიმფელ ფოსტ“-ის ფილიალში ან კურიერის
მეშვეობით. ამანათის გადაცემა ხდება მომხმარებლის მიერ პირადობის მოწმობის,
პასპორტის, მართვის მოწმობის ან სათანადო წესით დამოწმებული მინდობილობისა და
მინდობილი პირის პირადობის მოწმობის, პასპორტის, მართვის მოწმობის წარდგენით
და ხელმოწერით მიღება-ჩაბარების აქტზე ან ხელმოწერის გასაკეთებელ სპეციალურ
ელექტრონულ მოწყობილობაზე. ამანათის გაცემა არ ხდება დოკუმენტის ასლის
საფუძველზე.</p>
<p>8.2. დაბეგვრას დაუქვემდებარებელი ამანათის მიღება შეუძლია მომხმარებელს - 14
წლის ასაკიდან, ამ მუხლში განსაზღვრული დოკუმენტების საფუძველზე.</p>

<p>8.3. დაბეგვრადი (განსაბაჟებელი) ამანათის შემთხვევაში ამანათის სრულწლოვანმა
მიმღებმა უნდა წარმოადგინოს საკუთარი პირადობის მოწმობის ან პასპორტის დედანი.
თუ მიმღები არის მესამე პირი, უნდა წარმოადგინოს ნოტარიულად დამოწმებული
მინდობილობა და მინდობილი პირის პირადობის მოწმობის/პასპორტის დედანი. თუ
განსაბაჟებელი ამანათის მიმღები არის არასრულწლოვანი (18 წლამდე პირი), ამანათი
გაიცემა უფლებამოსილი წარმომადგენლის მომართვის და მის მიერ დამატებით
წარმოდგენილი წარმომადგენლობის უფლების დამადასტურებელი დოკუმენტების
საფუძველზე.</p>

<p>8.4. საბაჟო კონტროლს დაუქვემდებარებელი ამანათების მიღება მესამე პირის მიერ
შესაძლებელია იმ შემთხვევაში, თუ მომხმარებელი პროგრამაში მიუთითებს მინდობილ
პირს და მიმღები წარმოადგენს საკუთარი პირადობის მოწმობის, მართვის მოწმობის ან
პასპორტის დედანს. ასევე, თუ ამანათის მიმღები წარმოადგენს საკუთარ და ამანათის
მესაკუთრის პირადობის მოწმობის, მართვის მოწმობის ან პასპორტის ვადიანი/ძალაში
მყოფი დოკუმენტის დედანს, ან/და სათანადო წესით დამოწმებულ მინდობილობას.</p>

<p>8.5. ამანათის მისაღებად წარმოდგენილი დოკუმენტები უნდა იყოს ძალაში მყოფი და
ვადიანი დოკუმენტის დედანი ან სათანადოდ დამოწმებული ასლი. დოკუმენტის
ნამდვილობაზე პასუხისმგებელია მომხმარებელი.</p>
</div>

<div className="section">
<h2>9. მომსახურების საფასურის გადახდის ვადა და მეთოდი</h2>
<p>9.1. ტრანსპორტირების ღირებულების გადახდა მომხმარებლის მიერ უნდა
განხორციელდეს, ამანათის შპს „სიმფელ ფოსტ“-ის ფილიალში ჩამოსვლიდან არა
უგვიანეს 30 კალენდარული დღისა, შემსრულებლის მიერ გამოწერილი ინვოისის ან
მომხმარებლის პირად ოთახში მითითებული თანხის შესაბამისად. მომხმარებელი ვერ
გაიტანს ამანათს, რომელზეც არ აქვს გადახდილი ტრანსპორტირების საფასური.</p>
<p>9.2. ტრანსპორტირების ღირებულება გამოითვლება ამანათის მომხმარებლის პირად
ოთახში ასახვის დღეს, საქართველოს კომერციული ბანკის მიერ დადგენილი უცხოური
ვალუტის შესყიდვის კურსით ლარში.</p>
<p>9.3. სავალდებულოა ტრანსპორტირების ღირებულების გადახდა განხორციელდეს
მხოლოდ www.simplepost.ge -ზე მითითებული გადახდის მეთოდებით.</p>

<p>9.4. ტრანსპორტირების ღირებულების გადახდა ხორციელდება შემდეგი მეთოდებით:
- „სიმფელ ფოსტ“-ის ვებ-გვერდის საშუალებით - საკომისიო 0%
- გზავნილის გატანისას, ნაღდი ანგარიშსწორებით - საკომისიო 0%
- ფილიალში, POS ტერმინალის მეშვეობით.</p>
</div>

<div className="section">
<h2>10. საკურიერო მომსახურება.</h2>
<p>10.1. ონლაინ ამანათი - იმისთვის, რომ კურიერმა ონლაინ ამანათი მიუტანოს
მომხმარებელს, მომხმარებელმა თავისი პირადი კაბინეტიდან უნდა გადაიხადოს
საკურიერო მომსახურების საფასური.</p>
<p>10.2. თუ მომხმარებელი გამოთქვამს სურვილს ამანათის მისამართზე მიტანაზე შპს
„სიმფელ ფოსტ“-ი დაარიგებს ამანათებს იმ ლოკაციებზე, სადაც მოქმედებს კომპანიის
საკურიერო სერვისი.</p>
<p>10.3. გაბარიტული ამანათის შემთხვევაში საკურიერო მომსახურების ღირებულება
განისაზღვრება მომხმარებელთან შეთანხმებით.</p>
<p>10.4. საკურიერო მომსახურების დროს პროგრამაში მისამართი უნდა ჩაიწეროს შემდეგი
თანმიმდევრობით: ქალაქი, უბანი, ქუჩა და ნომერი. (მაგ. ვაკე, ჭავჭავაძის N 5.)</p>
<p>10.5. ყველა იმ ამანათზე, რომლის ტრანსპორტირების ღირებულება გადახდილი იქნება
ამანათის ჩამოსვლის დღემდე და მომხმარებელს მითითებული ექნება „საკურიერო
მომსახურება“ კურიერი დაარიგებს მსგავს ამანათებს ამანათის ჩამოსვლის მომდევნო
დღეს.</p>
<p>10.6. ამანათს მოგიტანენ თქვენს მიერ მითითებულ მისამართზე, მისამართის შეცვლა
შესაძლებელია მხოლოდ ამანათის ჩამოსვლამდე.</p>
<p>10.7. თუ კურიერმა ვერ მოახერხა წინასწარ დაკავშირება თქვენს მიერ მითითებული
მობილური ტელეფონის ნომერზე ამანათს დააბრუნებს ოფისში და მომხმარებელს
შეეძლება მისი გატანა ოფისიდან.</p>
<p>10.8. კურიერი არ არის ვალდებული ამანათი აიტანოს სართულებზე.</p>
<p>10.9. ამანათის მიღებისას მომხმარებელი ვალდებულია წარადგინოს მე-9 მუხლში
განსაზღვრული დოკუმენტი.</p>

<p>10.10. იმ შემთხვევაში, თუ საკურიერო მომსახურების მოთხოვნა ხდება უკვე ჩამოსულ
ამანათზე, საკურიერო მიწოდება განხორციელდება მომსახურების საფასურის
გადახდიდან მომდევნო დღეს.</p>
<p>10.11. განსაბაჟებელი ამანათების საკურიერო სერვისი ხორციელდება საბაჟო
პროცედურების დასრულების შემდგომ.</p>
</div>

<div className="section">
<h2>11. დაზღვევა</h2>
<p>11.1. იმისათვის, რომ მომხმარებელმა შეძლოს ამანათის დაზღვევა, აუცილებელია
მოახდინოთ მისი სავალდებულო დეკლარირება და უცხოეთში ჩვენს ოფისში მიტანის
შემდეგ, ამანათის საქართველოში გამოგზავნამდე, ჩართოს ამანათის დაზღვევა.
ფუნქციის ჩართვა ხდება დაზღვევის სპეციალური უჯრის მონიშვნით, თითოეული
ამანათისთვის ინდივიდუალურად.</p>
<p>11.2. გამოწერილი ამანათის დაზღვევა შესაძლებელია მისი მიღების შემდეგ ამანათის
საქართველოში გამოგზავნამდე.</p>
<p>11.3. დაზღვევის ღირებულება შეადგენს ამანათის ღირებულების 10% და ამ თანხის
დაანგარიშება და გადახდა ხდება ტრანსპორტირების ღირებულებასთან ერთად. თუ
თქვენ უკვე გადახდილი გაქვთ ტრანსპორტირების ღირებულება, ასეთ შემთხვევაში
დაზღვევის ჩართვა შეუძლებელია.</p>
<p>11.4. ზარალის ანაზღაურება მოხდება ამანათის დაკარგვის ან მისი შიგთავსის
დაზიანების შემთხვევაში. „სიმფელ ფოსტ“-ი არ აანაზღაურებს ამანათის შიგთავსის
დაზიანებისას გამოწვეულ ზარალს, თუ არ შეინიშნება ამანათის გარეგნული დაზიანება.
ზარალის ანაზღაურება ხდება მხოლოდ იმ შემთხვევაში, თუ უცხოეთში ჩვენს ოფისში
ამანათი მიიტანეს უვნებელი, ხოლო საქართველოში გაცემის დროს დაფიქსირდება მისი
დაზიანება.</p>
</div>

<div className="section">
<h2>12. მომხმარებლის პერსონალური მონაცემების დამუშავება და დაცვა</h2>
<p>12.1. მომხმარებლის მიერ რეგისტრაციისას, ასევე შემდგომ
წარმოდგენილი/მოპოვებული პირადი ინფორმაცია წარმოადგენს პერსონალურ მონაცემს
და დაცულია კანონით. კომპანია არ გადასცემს მესამე მხარეს მომხმარებლის შესახებ
რაიმე სახის ინფორმაციას კანონით დადგენილი საფუძვლის არსებობის გარეშე.</p>

<p>12.2. „სიმფელ ფოსტ“-ი მომხმარებელთან სასამართლო ან სხვა დავის წარმოქმნის
შემთხვევაში უფლებების დაცვის მიზნით, ასევე მომხმარებელთა ინტერესების და
უსაფრთხოების დაცვის მიზნით, ახორციელებს მომხმარებელთან ურთიერთობის
ჩაწერას და დამახსოვრებას ინტერნეტის, სატელეკომუნიკაციო და
ვიდეოსამეთვალყურეო სისტემების საშუალებით, მათ შორის ელ–ფოსტით
გამოგზავნილი წერილების და ტექსტური შეტყობინებებით საუბრების,სოციალური
ქსელის თუ ბლოგის კომენტარების გზით მოგროვებული ინფორმაციის დამუშავებას.</p>
<p>12.3. შემსრულებელი უფლებას იტოვებს გამოიყენოს კლიენტის/დამკვეთის ელ-ფოსტის
მისამართი და ტელეფონის ნომერი საკუთარ პროდუქციასთან ან/და მომსახურებასთან
დაკავშირებული მარკეტინგული შეტყობინებების გაზიარებისთვის.</p>

<p>12.4. მომხმარებელი ეთანხმება, რომ მის მიერ ამანათის მისაღებად წარმოდგენილი
დოკუმენტები (პირადობა, პასპორტი, ბანკის ქვითარი, გადახდის დამადასტურებელი
დოკუმენტი, პროდუქციის შესყიდვის დამადასტურებელი დოკუმენტი და სხვა
ნებისმიერი დოკუმენტი, რომელიც გადაზიდულ ამანათთან არის დაკავშირებული) არის
უტყუარი და ამ დოკუმენტების ნამდვილობაზე პასუხისმგებლობა მთლიანად ეკისრება
მომხმარებელს.</p>
<p>12.5. მომხმარებელი აცხადებს თანხმობას შპს „სიმფელ ფოსტ“-მა შეაგროვოს, დაამუშაოს
და შეინახოს მასთან დაკავშირებული მონაცემები მათ შორის არსებული
ხელშეკრულების, კონფიდენციალურობის პოლიტიკისა და საქართველოს
კანონმდებლობის შესაბამისად.</p>
</div>

<div className="section">
<h2>13. დამატებითი პირობები</h2>
<p>13.1. კომპანია უფლებას იტოვებს კლიენტის წინასწარი ინფორმირების შემდეგ, მასთან
შეთანხმების გარეშე, შეცვალოს მომსახურების მიწოდების პირობები ან შეუწყვიტოს
მომხმარებელს მომსახურების მიწოდება.</p>
<p>13.2. კომპანიის ან კომპანიაში დასაქმებული პირების მიმართ გამოხატული აგრესიული
სახის ქმედება, შეურაცხმყოფელი გამონათქვამები, დაუზუსტებელი ინფორმაციის
გავრცელება, ამანათების/ტვირთის არაკეთილსინდისიერი გზებით მოპოვება, ფილიალის
ფუნქციონირების შეფერხება ან შეფერხების მუქარა, რომელიც მიზანმიმართულად
აზიანებს კომპანიის იმიჯს და მოქმედებს კომპანიის ინტერესების საწინააღმდეგოდ,
შესაძლებელია გახდეს მომხმარებლისთვის მომსახურებაზე უარის თქმის საფუძველი.

აღნიშნულის შესახებ მომხმარებელს ეცნობება ელექტრონული ფოსტის მეშვეობით,
ასევე, აღნიშნული პირი ვეღარ შეძლებს მინდობილი პირის სტატუსით სარგებლობას და
ვერ მიიღებს მესამე პირების ამანათებს ვერც ერთი შესაძლო მეთოდით.</p>
<p>13.3. კომპანია უფლებას იტოვებს უარი თქვას სერვისის მიწოდებაზე იმ პირებისთვის
და კომპანიებისთვის, რომლებიც წარმოადგენენ კონკურენტ კომპანიას, არიან მათი
დამფუძნებლები, დირექტორები თუ სხვა მენეჯმენტის რგოლში მომუშავე პირები.</p>
<p>13.4. კომპანია ვალდებულებას იღებს კლიენტთან მხოლოდ იმ სერვისებზე, რაც
აღწერილია მომსახურების პირობებში.</p>
<p>13.5. „სიმფელ ფოსტ“-ი ამ დოკუმენტით განსაზღვრული დებულებების გარდა
მომხმარებლისთვის მომსახურების გაწევისას ხელმძღვანელობს საქართველოს
კონსტიტუციით, საქართველოს მოქმედი კანონმდებლობითა და საერთაშორისო
ხელშეკრულებებით.</p>
<p>13.6. შემსრულებელი იტოვებს უფლებას, ნებისმიერ დროს, საკუთარი
შეხედულებისამებრ, ცალმხრივად, სრულად ან ნაწილობრივ შეცვალოს/განაახლოს
მომსახურების წესები და პირობები ან კონფიდენციალურობის პოლიტიკა და
მოახდინოს აღნიშნულის შესახებ მომხმარებლის ინფორმირება.</p>
<p>13.7. წინამდებარე ხელშეკრულების რომელიმე მუხლის ბათილად ცნობა ზეგავლენას არ
ახდენს ხელშეკრულების დანარჩენი მუხლების იურიდიულ ქმედითობაზე.</p>
<p>13.8. მხარეები თანხმდებიან, რომ ყველა სადავო საკითხი ან/და წინააღმდეგობა
გადაწყვიტონ ურთიერთშეთანხმებისა და მოლაპარაკების გზით. მხარეთა მიერ სადავო
საკითხების მოლაპარაკებათა გზით გადაწყვეტის შეუძლებლობის შემთხვევაში, დავა
განსახილველად გადაეცემა თბილისის საქალაქო სასამართლოს.</p>
<p>
14. კომპანია არ აბრუნებს საქართველოში ჩამოსულ ამანათებს გამომგზავნ ქვეყანაში (ჩინეთი). შესაბამისად, არ მოხდება ტრანსპორტირების თანხის დაბრუნება.
</p>
</div>
        </div>
    )
}

export default TermsAndCond