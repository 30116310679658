const PrivacyPolicy = () => {
    return(
        <div style={{padding: "10px 50px"}} className="terms-and-cond-container">
            <h2>კონფიდენციალურობის პოლიტიკა</h2>
            <p> შპს  „სიმფელ ფოსტა“ იცავს იმ პირების პერსონალურ მონაცემებს, რომლებიც სარგებლობენ კომპანიის ვებგვერდით. შპს  „სიმფელ ფოსტა“-ს კონფიდენციალურობის პოლიტიკის 
                დოკუმენტი ვრცელდება კომპანიის კლიენტებზე, ფიზიკურ და იურიდიულ პირებზე, არაიურიდიულ პირებზე, სახელმწიფო ან ადგილობრივი თვითმმართველობის ერთეულებზე და 
                საჯარო სამართლის იურიდიულ პირებზე, რომლებიც სარგებლობენ შპს   „სიმფელ ფოსტა“-ს  მომსახურებით ან დაინტერესებული არიან კომპანიის სერვისით.</p>
            <p> წინამდებარე დოკუმენტი ვრცელდება ყველა იმ ინფორმაციაზე, რომელიც მომხმარებლის მიერ ვებგვერდის გამოყენების შემდგომ შპს  „სიმფელ ფოსტა“-სთვის ხდება ხელმისაწვდომი.</p>
            <p>კონფიდენციალურობის პოლიტიკის დოკუმენტში აღწერილია რა სახის ინფორმაციის დამუშავებას ახდენს კომპანია და განსაზღვრავს მონაცემების უსაფრთხოების დაცვის შესაბამის ზომებს. 
                შპს  „სიმფელ ფოსტა“ უფრთხილდება და იცავს მომხმარებლების პერსონალურ მონაცემებს, რომელიც შეგროვდა, მუშავდება და გამოიყენება ვებგვერდზე მომხმარებლის სტუმრობის 
                განმავლობაში და შემდგომში.</p>
            <p>ვებგვერდზე www.simplepost.ge ვიზიტით მომხმარებელი ეთანხმება წინამდებარე კონფიდენციალურობის პოლიტიკას. აღნიშნული წარმოადგენს შპს „სიმფელ ფოსტა“-ს მომსახურების 
                წესებისა და პირობების ნაწილს.<br />
                თუ მომხმარებელი არ ეთანხმება მონაცემთა დამუშავების იმ პირობებს, რომლებიც აღწერილია ამ დოკუმენტში, არ უნდა გამოიყენოს შპს „სიმფელ ფოსტა“-ს სერვისი.</p>
            <p>წინამდებარე პოლიტიკის ბოლოს მითითებული თარიღი მიუთითებს დოკუმენტში შესული ცვლილებების დროს, შესაბამისად, ვებგვერდზე ვიზიტისას გთხოვთ, გადაამოწმოთ ბოლო განახლებები.</p>
  
 
  
 
<h3>1. ინფორმაციის მოგროვება და მომხმარებლის უფლებები.</h3>
 
 <p>მომხმარებელი თავად არის პასუხისმგებელი მოწოდებული ინფორმაციის სისწორეზე. საჭიროების შემთხვევაში მომხმარებლის მიერ მითითებული უსწორობის შესწორება და ხარვეზის აღმოფხვრა შესაძლებელია ოფიციალური განცხადების საფუძველზე. იმ შემთხვევაში, თუ არასწორად უთითებთ რომელიმე მონაცემს და აღნიშნულის შესახებ ცნობილი გახდება კომპანიისთვის, შპს „სიმფელ ფოსტა“ იტოვებს უფლებას შეგიჩეროთ კომპანიის სერვისით სარგებლობის შესაძლებლობა.</p>
 
 <p>თუ მომხმარებელს სურს შპს „სიმფელ ფოსტა“-ში შექმნილი ანგარიშის გაუქმება, ასეთ შემთხვევაში უკავშრდება კომპანიას ელექტრონული ფოსტის მეშვეობით, შესაბამისი განცხადებით ან ოფიციალური წერილით. ამის შემდგომ მომხმარებლის ანგარიში ხდება არააქტიური, თუმცა კომპანია ინახავს ინფორმაციას ვიდრე ეს აუცილებელია იმ მიზნებისათვის, რომლისთვისაც ხდება ინფორმაციის კანონიერად გამოყენება.</p>
 
 <p>შპს „სიმფელ ფოსტა“-მ შესაძლებელია მოახდინოს მონაცემების დეპერსონალიზება იმისთვის, რომ მომხმარებლის მიერ მოწოდებული ინფორმაცია არ იყოს იდენტიფიცირებადი, თუმცა კომპანია მათ გამოიყენებს მხოლოდ ანალიტიკური/სტატისტიკური მიზნებისთვის.</p>
 
 <strong>მოქმედი კანონმდებლობით ან რეგულაციებით გათვალისწინებული წესით, თქვენ შეგიძლიათ:</strong>
 <p>-  გადაამოწმოს ვინახავთ თუ არა თქვენს პერსონალურ მონაცემებს;</p>
 <p>-  გვთხოვოთ ჩვენთან დაცული თქვენი პერსონალური მონაცემების ასლის გადმოცემა;</p>
 <p>-   მოითხოვოთ ჩვენთან დაცული თქვენი პერსონალურ მონაცემებში უსწორობის აღმოფხვრა.</p>
 
  
 
  
 
 <h3>2. დამუშავებული მონაცემების ტიპი და მიზნები.</h3>
 
 <p>შპს „სიმფელ ფოსტა“ მომხმარებლების პერსონალურ მონაცემებს ამუშავებს კანონიერად, განსაზღვრული მიზნით და შეესაბამება იმ მიზნებს, რომელთათვისაც მუშავდება მონაცემები. შეგროვებული მონაცემები არის ზუსტი და საჭიროების შემთხვევაში ხდება მათი განახლება.</p>
 
 <p>შპს „სიმფელ ფოსტა“-ს მიერ დამუშავებული ინფორმაცია შესაძლებელია მოიცავდეს მომხმარებლის სახელს, გვარს, მოწყობილობას, რომელსაც კომპანიის ვებგვერდზე ვიზიტისას იყენებს, მომხმარებლის მიერ რეგისტრაციისას მითითებულ მონაცემებს - საცხოვრებელ მისამართს, პირად ნომერს, ტელეფონის ნომერს, ელ. ფოსტის მისამართს, სქესს და დაბადების თარიღს, სადებეტო/საკრედიტო ბარათის პირველი 6 და ბოლო 4 ნომერს.</p>
 
 <strong>შპს „სიმფელ ფოსტა“ გამოიყენებს მომხმარებლის პერსონალურ მონაცემებს შემდეგი მიზნით:</strong>
 <ul>
        <li>ვებგვერდის ტექნიკური ფუნქციონირების კვლევის და განვითარებისთვის.</li>
        <li>მარკეტინგული მიზნებისთვის.</li>
        <li>კომპანიის სიახლეების შესახებ ინფორმაციის მოსაწოდებლად.</li>
        <li>წინამდებარე დოკუმენტით განსაზღვრულ სხვა შემთხვევებში.</li>
 </ul>
  
 
 <h3>3. ინფორმაციის მოგროვების მეთოდი</h3>
 
 <p>შპს „სიმფელ ფოსტა“ ინფორმაციის მოგროვებისთვის იყენებს მზა ჩანაწერებს, მცირე ზომის ტექსტურ ფაილებს (cookies), რომელიც იგზავნება ვებ-გვერდის სერვერიდან მომხმარებლის პერსონალურ კომპიუტერზე, მობილურ ტელეფონზე, ტაბლეტზე ან სხვა სახის ელექტრონულ მოწყობილობაზე და ინახება ამ მოწყობილობის მყარ დისკზე.</p>
 
 <p>Cookie ფაილებს ვიყენებთ იმისთვის, რომ მივიღოთ ინფორმაცია თუ როგორ იყენებენ მომხმარებლები კომპანიის ვებგვერდს. ისინი საშუალებას აძლევს ვებ სერვერებს შეინახონ ინფორმაცია მომხმარებლის მოწყობილობაზე ან თვალყური ადევნონ მომხმარებლის აქტივობას.</p>
 
 <p>როდესაც თქვენ იყენებთ ვებსაიტს, ჩვენი სერვერები ავტომატურად აფიქსირებენ გარკვეულ ინფორმაციას, რომელსაც თქვენი ბრაუზერი აგზავნის ვებგვერდზე ვიზიტისას. სერვერის ეს ჟურნალი შეიძლება შეიცავდეს ინფორმაციას, როგორიცაა თქვენი ვებ მოთხოვნა, ინტერნეტ პროტოკოლის ("IP") მისამართი, ბრაუზერის ტიპი, ბრაუზერის ენა, მიმართვის/გასვლის გვერდები და URL, პლატფორმის ტიპი, დაწკაპუნების რაოდენობა, დომენის სახელები, სადესანტო გვერდები, ნანახი გვერდები და ამ გვერდების თანმიმდევრობა, კონკრეტულ გვერდებზე დახარჯული დროის რაოდენობა, თქვენი მოთხოვნის თარიღი და დრო.</p>
 
 <p>თუ თქვენი პერსონალური ინფორმაცია შეიცვლება (როგორიცაა თქვენი ელექტრონული ფოსტის მისამართი), ან თუ გსურთ თქვენი ინფორმაციის ამოღება ჩვენი მონაცემთა ბაზიდან, დაგვიკავშირდით და ჩვენ გამოვასწორებთ, განვაახლებთ ან წაშლით ჩვენთვის მოწოდებულ პერსონალურ მონაცემებს.</p>
 
 <p>თუ გსურთ, შეზღუდოთ "cookie" ფაილები ან დაბლოკოთ ისინი ვებგვერდზე, შეგიძლიათ თქვენი ბრაუზერის პარამეტრების შეცვლა, აღნიშნულის შესახებ მეტი ინფორმაციის მისაღებად გაეცანით ბრაუზერის ფუნქციას "პარამეტრები". გთხოვთ, გაითვალისწინოთ, რომ ჩვენ მიერ შემოთავაზებული ზოგიერთი სერვისი არ იფუნქციონირებს თუ დაბლოკავთ ან წაშლით "cookie" ფაილებს.</p>
 
  
 
  <h3>4. დამუშავებული მონაცემების გადაცემა.</h3>
 
 <p>შპს „სიმფელ ფოსტა“-მ შესაძლოა თქვენი ინფორმაცია გადასცეს მასთან დაკავშირებულ პირებს, როგორიცაა პარტნიორები და აგენტები.  აღნიშნულ პირებს ასევე გააჩნიათ მომხმარებლების მონაცემების კონფიდენციალურობისდაცვის ვალდებულება</p>
 
 <p>კომპანიის მიერ მომხმარებლის ინფორმაცია შესაძლოა გადაეცეს სახელმწიფო ორგანოებს ან ნებისმიერ სხვა პირს, მოქმედი კანონმდებლობის, რეგულაციების, სასამართლო განჩინების, ოფიციალური მოთხოვნის და მიზნების შესაბამისად, მოქმედი კანონმდებლობის საფუძველზე.</p>
 
 <p>ასევე პერსონალური მონაცემების გადაცემა შეიძლება მოხდეს კომპანიის რეორგანიზაციის ან/და შერწყმის შემთხვევაში, რა დროსაც კომპანიის სამართალმემკვიდრე  მოიპოვებს პირად ინფორმაციაზე წვდომას, თუმცა მასაც იგივე უფლებები და მოვალეობები წარმოეშობა ამ პირად მონაცემებთან დაკავშირებით, რაც გაგვაჩნდა ჩვენ, ცვლილებების განხორციელებამდე.</p>
 
 <p>შპს „სიმფელ ფოსტა“-სთვის მომხმარებლის მიერ მოწოდებული ან კომპანიის მიერ შეგროვებული მონაცემების უსაფრთხოების დაცვისთვის კომპანიაში მიღებულია შესაბამისი ტექნიკური და ორგანიზაციული უსაფრთხოების ზომები იმისთვის, რომ „სიმფელ ფოსტა“-ს ვებგვერდის მეშვეობით მოპოვებული მომხმარებლის პერსონალური მონაცემები დაცული იყოს უნებართვო წვდომისგან, გამოყენებისგან, დაკარგვისგან ან განადგურებისგან.</p>
 
  
 
 <h3>5. შპს „სიმფელ ფოსტა“-ს პასუხისმგებლობის ფარგლები ინფორმაციის დაცვისას.</h3>
 
 <p>ვინაიდან ინტერნეტი არ წარმოადგენს დაცულ სივრცეს, შესაძლებელია მესამე პირებმა მოიპოვონ არასანქცირებული წვდომა თქვენს ინფორმაციაზე. გთხოვთ, გაითვალისწინოთ, რომ კომპანია პასუხს არ აგებს თქვენი ინფორმაციის უსაფრთხოებაზე იმ ეტაპზე, როდესაც ეს ინფორმაცია ინტერნეტის მეშვეობით შპს „სიმფელ ფოსტა“-სთვის გადაცემის მდგომარეობაში იმყოფება. იმისთვის, რომ დაიცვათ ინფორმაციის კონფიდენციალურობა, გთხოვთ, აირჩიოთ კომუნიკაციის ის გზა, რომელიც თქვენი შეხედულებით უფრო უსაფრთხოა.</p>
 
 <p>შპს „სიმფელ ფოსტა“ არ არის პასუხისმგებელი მომხმარებლისთვის მიყენებულ რაიმე ზიანზე, რომელიც დადგა მის კუთვნილ მოწყობილობაზე (მოწყობილობაზე, რომელსაც იყენებს სერვისის მისაღებად) არაუფლებამოსილი წვდომის შედეგად დაკარგული ან მოპარული მონაცემების გამო.</p>
 
 <p>კომპანია არ არის პასუხისმგებელი უსაფრთხოების ისეთ დარღვევაზე, რომელიც სცდება შპს „სიმფელ ფოსტა“-ს კონტროლის ფარგლებს, მაგალითისთვის, როგორიცაა: კომპიუტერში ჰაკერული შეღწევა, კომპიუტერულ მონაცემებსა და შენახვის მოწყობილობასთან არაუფლებამოსილი წვდომა, მოწყობილობის მწყობრიდან გამოსვლა და ა.შ.</p>
 
  
 
 <h3>6. მონაცემების შენახვის ვადა.</h3>
 
 <p>შპს „სიმფელ ფოსტა“ დამუშავებულ მონაცემებს სისტემაში ინახავს შემდეგი ვადით:</p>
 
 <p>რა ვადითაც საჭიროა შესაბამისი საქმიანობისთვის ან მომსახურების გასაწევად;</p>
 <p>კანონით განსაზღვრული ვადით;</p>
 <p>იმ ვადის დასასრულამდე, რომლის განმავლობაშიც შესაძლოა წარმოიშვას სამართლებრივი დავა ან დაიწყოს გამოძიება მომსახურებასთან დაკავშირებით.</p>
  
 
  
 
 <p>მომხმარებლის პერსოალური მონაცემების დამუშავებასთან დაკავშირებულ საკითხებზე გთხოვთ მოგვმართოთ ელ. ფოსტის მისამართზე: info@simplepost.ge</p>
 
 <p>დოკუმენტში ბოლოცვლილება განხორციელდა 2024 წლის 17 ივნისს.</p>
        </div>
    )
}

export default PrivacyPolicy