import { createSlice } from "@reduxjs/toolkit";

const initialUser = {
    isLoggedIn: null,
    name: "",
    surname: "",
    initials: "",
    role: "",
    phone: "",
    email: "",
    userId: ""
}

const admin = createSlice({
    name: 'admin',
    initialState: initialUser,
    reducers: {
        setIsLoggedIn(state, action){
            state.isLoggedIn = action.payload;
        },
        setUserData(state, action){
            state.name = action.payload.name;
            state.surname = action.payload.surname;
            state.initials = action.payload.initials;
            state.role = action.payload.role;
            state.phone = action.payload.phone;
            state.email = action.payload.email;
            state.userId = action.payload.user_id;
        }
    }
});

export const adminAction = admin.actions;

export default admin.reducer;