import './App.css';
import Header from "./common/Header";
import MainPage from "./pages/MainPage";
import {Route, Switch, useHistory} from "react-router-dom";
import Footer from "./common/Footer";
import MyPage from "./pages/MyPage";
import {useEffect, useState} from "react";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {userAction} from "./store/user";
import Flights from "./pages/Flights";
import ContactUs from "./pages/ContactUs";
import MobileNavigation from "./common/MobileNavigation";
import language from "./store/language";
import AdminRoute from "./pages/Admin/AdminRoute";
import OnlineParcels from "./pages/OnlineParcels";
import DeliveryService from "./pages/DeliveryService";
import HowToBuy from "./pages/HowToBuy";
import CustomsProcedure from "./pages/CustomsProcedure";
import DangerItems from "./pages/DangerItems";
import TrustedPersons from "./pages/TrustedPersons";
import Faq from "./pages/Faq";
import Alert from '@mui/material/Alert';
import { notificationActions } from './store/notification';
import PrivacyPolicy from './pages/Admin/Pages/PrivacyPolicy';
import TermsAndCond from './pages/Admin/Components/TermsAndCond';

function App() {
    const history = useHistory();
    const [isMyPage, setIsMyPage] = useState(false);
    const dispatch = useDispatch();
    const isLoggedIn =  useSelector((state) => state.user.isLoggedIn);
    const windowSize = window.innerWidth;
    const lang = useSelector((state) => state.user.language);
    const [currentPage, setCurrentPage] = useState(window.location.pathname);
    const [adminRoutes, setAdminRoutes] = useState(false);
    const notification = useSelector((state) => state.notification);

    useEffect(() => {
        history.push(currentPage);

    }, [currentPage]);

    useEffect(() => {
        axios('/api/users')
            .then((res) => {
                const data = res.data.data;
                dispatch(userAction.setUserData({
                    name: data.name_geo,
                    surname: data.surname_geo,
                    userId: data.user_id,
                    role: data.role,
                    phone: data.phone,
                    address: `${data.city}, ${data.address}`,
                    email: data.email,
                    userType: data.user_type,
                    conversation_id: data.conversation_id
                }))
                dispatch(userAction.setIsLoggedIn(true));
            })
            .catch(() => {

            })
    }, []);

    const handlers = {
        showNotifications(data){
            dispatch(notificationActions.setNotification({show: data.show, message: data.message, type: data.type}))
        }
    }

    return (
        <div className="page-content">
            {notification.show && <Alert
                severity={notification.type}
                style={{
                zIndex: "1111111111",
                minWidth: "250px",
                position: "fixed",
                bottom: "20px",
                left: "20px",
                }}
            >
                {notification.message}
            </Alert>}
            {!adminRoutes && <Header isLoggedIn={isLoggedIn} isMyPage={isMyPage}/>}
            <main className={"website-content"}>
                <Switch>
                    <Route path={"/"} exact><MainPage setIsMyPage={setIsMyPage}/></Route>
                    {isLoggedIn === true && <Route path={"/my-page"}><MyPage isMyPage={isMyPage} setIsMyPage={setIsMyPage}/></Route>}
                    <Route path={"/flights"}><Flights setIsMyPage={setIsMyPage}/></Route>
                    <Route path={"/contact-us"}><ContactUs notification={handlers.showNotifications} setIsMyPage={setIsMyPage}/></Route>
                    <Route path={"/admin-dashboard"}><AdminRoute setAdminRoutes={setAdminRoutes} setIsMyPage={setIsMyPage}/></Route>
                    <Route path={"/info/services/online-parcels"}><OnlineParcels windowSize={windowSize} setIsMyPage={setIsMyPage}/></Route>
                    <Route path={"/info/services/delivery-services"}><DeliveryService windowSize={windowSize} setIsMyPage={setIsMyPage}/></Route>
                    <Route path={"/info/useful-information/how-to-buy"}><HowToBuy windowSize={windowSize} setIsMyPage={setIsMyPage}/></Route>
                    <Route path={"/info/useful-information/customs-processes"}><CustomsProcedure windowSize={windowSize} setIsMyPage={setIsMyPage}/></Route>
                    <Route path={"/info/useful-information/danger-items"}><DangerItems windowSize={windowSize} setIsMyPage={setIsMyPage}/></Route>
                    <Route path={"/info/useful-information/trustees"}><TrustedPersons windowSize={windowSize} setIsMyPage={setIsMyPage}/></Route>
                    <Route path={"/info/useful-information/faq"}><Faq windowSize={windowSize} setIsMyPage={setIsMyPage}/></Route>
                    <Route path="/terms-and-conditions"><TermsAndCond /></Route>
                    <Route path="/privacy-policy"><PrivacyPolicy /></Route>

                    <Route path={"/*"}><p>404</p></Route>
                </Switch>
                {windowSize < 880 && <MobileNavigation isLoggedIn={isLoggedIn} setCurrentPage={setCurrentPage} language={language} lang={lang}/>}
            </main>
            {!adminRoutes && <Footer isMyPage={isMyPage}/>}
        </div>
    );
}

export default App;
