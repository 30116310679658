import {useEffect, useState} from "react";
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import OutsideClickHandler from 'react-outside-click-handler';
import axios from "axios";
import {Stack, Pagination, Button} from "@mui/material";
import ViewParcel from "./ViewParcel";
import {Modal, Table} from "react-bootstrap";
import Alert from "@mui/material/Alert";
import {useDispatch, useSelector} from "react-redux";
import Declaration from "./Declaration";
import language from "../../store/language";
import { notificationActions } from "../../store/notification";

const MyParcels = ({active, setActive, selectedParcels, setSelectedParcels, setCurrentContentTitle, hasPayedParcel, setHasPayedParcel, language, lang}) => {
    const [keyword, setKeyword] = useState("");
    const dispatch = useDispatch();
    const [optionsDropdown, setOptionsDropdown] = useState({show: false, id: 0});
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const [parcelsCount, setParcelsCount] = useState({all: 0, warehouse: 0, onTheWay: 0, arrived: 0});
    const [searchFieldValue, setSearchFieldValue] = useState("");
    const [activeParcel, setActiveParcel] = useState({});
    const [showViewParcelModal, setShowViewParcelModal] = useState(false);
    const [showDeliveryModal, setShowDeliveryModal] = useState(false);
    const [address, setAddress] = useState("");
    const [notification, setNotification] = useState({show: false, type: "", message: ""});
    const defaultAddress =  useSelector((state) => state.user.address);
    const [useDefaultAddress, setUseDefaultAddress] = useState(false);
    const [parcelsDebt, setParcelsDebt] = useState(0);
    const [showDeclaration, setShowDeclaration] = useState(false);

    const getParcels = () => {
        axios(`/api/parcels?status=${active}&keyword=${keyword}&page=${page}&limit=${limit}`)
            .then((res) => {
                setData(res.data.data);
                setTotal(res.data.total);
                const count = res.data.count;
                setParcelsCount({all: count.all, warehouse: count.warehouse, onTheWay: count.on_way, arrived: count.received})
            })
            .catch((err) => {
                dispatch(notificationActions.setNotification({show: true, message: err.response.data.message[lang], type: "error"}));
                setTimeout(() => {
                    dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                }, 4000)
            })
    };

    useEffect(getParcels, [active, keyword, limit, page]);
    useEffect(() => setCurrentContentTitle("My Parcels"), []);

    const handlers = {
        delivery(){
            axios.post('/api/delivery', {address, tracking: [...selectedParcels], debt: parcelsDebt})
                .then((res) => {
                    const message = res.data.message.en;
                    setNotification({show: true, type: "success", message: message});
                    setTimeout(() => setNotification({show: false, type: "", message: ""}), 6000);
                    setShowDeliveryModal(false);
                })
                .catch((err) => {
                    const message = err.response.data.message.en;
                    setNotification({show: true, type: "error", message: message});
                    setTimeout(() => setNotification({show: false, type: "", message: ""}), 6000);
                })
        },
        declaration(data){
            const formData = new FormData();
            formData.append("price", data.price);
            formData.append("currency", data.currency);
            formData.append("website", data.website);
            formData.append("description", data.description);
            formData.append("another_taker", data.another_taker);
            formData.append("trusteer_fullname", data.trusteer_fullname);
            formData.append("trusteer_address", data.trusteer_address);
            formData.append("trusteer_phone", data.trusteer_phone);
            formData.append("trusteer_id_number", data.trusteer_id_number);
            formData.append("file", data.file);
            formData.append("type", "declaration");
            formData.append("insurance", data.insurance);

            axios.put(`/api/parcels/${activeParcel.tracking}/pre-declaration`, formData)
                .then((res) => {
                    setShowDeclaration(false);
                    getParcels();
                })
                .catch((err) => {
                    dispatch(notificationActions.setNotification({show: true, message: err.response.data.message[lang], type: "error"}));
                    setTimeout(() => {
                        dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                    }, 4000)
                })
        }
    }

    return (
        <div className={"my-parcels-container"}>
            <div className={"my-parcels-status-selector"}>
                <div className={`my-parcels-status-item ${active === "all" ? "active-type-box" : ""}`} onClick={() => setActive("all")}>
                    <img src={"/static/icons/all-parcels.png"} alt={"all-parcels.png"}
                         className={"parcel-status-icons"}/>
                    <p className={active === "all" ? "active-type-box-text" : ""}>{language[lang].myPage.myParcels.all} ({parcelsCount.all})</p>
                </div>
                <div className={`my-parcels-status-item ${active === "warehouse" ? "active-type-box" : ""}`} onClick={() => setActive("warehouse")}>
                    <img src={"/static/icons/warehouse.png"} alt={"all-parcels.png"}
                         className={"parcel-status-icons"}/>
                    <p className={active === "warehouse" ? "active-type-box-text" : ""}>{language[lang].myPage.myParcels.warehouse} ({parcelsCount.warehouse})</p>
                </div>
                <div className={`my-parcels-status-item ${active === "on way" ? "active-type-box" : ""}`} onClick={() => setActive("on way")}>
                    <img src={"/static/icons/international-delivery.png"} alt={"all-parcels.png"}
                         className={"parcel-status-icons"}/>
                    <p className={active === "on way" ? "active-type-box-text" : ""}>{language[lang].myPage.myParcels.on_way} ({parcelsCount.onTheWay})</p>
                </div>
                <div className={`my-parcels-status-item ${active === "arrived" ? "active-type-box" : ""}`} onClick={() => setActive("arrived")}>
                    <img src={"/static/icons/received-parcels.png"} alt={"all-parcels.png"}
                         className={"parcel-status-icons"}/>
                    <p className={active === "arrived" ? "active-type-box-text" : ""}>{language[lang].myPage.myParcels.arrived} ({parcelsCount.arrived})</p>
                </div>
            </div>

            <div className={"parcels-data-table"}>
                <div className={"data-table-header"}>
                    <div className={"search-field-container"}>
                        <input type={"text"}
                               onKeyUp={(e) => {
                                   if(e.keyCode === 13){
                                       setKeyword(searchFieldValue);
                                   }
                               }}
                               onChange={(e) => setSearchFieldValue(e.target.value)} placeholder={language[lang].myPage.myParcels.search} className={"search-field"}/>
                        <button><SearchIcon onClick={() => setKeyword(searchFieldValue)} style={{color: "white"}}/></button>
                    </div>
                    <button onClick={() => setShowDeliveryModal(true)} disabled={selectedParcels.length === 0} title={selectedParcels.length === 0 ? language[lang].myPage.myParcels.selectToProcess : ""} className={`delivery-button ${selectedParcels.length === 0 ? "disabled-button" : ""}`}>{language[lang].myPage.myParcels.delivery}</button>
                </div>
                <Table responsive className={"data-table"}>
                    <thead>
                    <tr>
                        <th></th>
                        <th>{language[lang].myPage.myParcels.tracking}</th>
                        <th>{language[lang].myPage.myParcels.description}</th>
                        <th>{language[lang].myPage.myParcels.flightId}</th>
                        <th>{language[lang].myPage.myParcels.price}</th>
                        <th>{language[lang].myPage.myParcels.payed}</th>
                        <th>{language[lang].myPage.myParcels.declared}</th>
                        <th>{language[lang].myPage.myParcels.status}</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        data.map((p,i) => {
                            return(
                                <tr className={`${i % 2 === 1 ? 'odd-row' : 'even-row'} table-rows ${selectedParcels.includes(p.tracking) ? "selected-row" : ""}`}
                                    key={p.tracking}>
                                    <td style={{paddingLeft: "5px"}} onClick={(e) => {
                                        if (e.target.checked) {
                                            setSelectedParcels((prev) => ([...prev, p.tracking]));
                                            if (p.payed) {
                                                setHasPayedParcel((prev) => ([...prev, p.tracking]))
                                            } else {
                                                setParcelsDebt((prev) => prev + parseFloat(p.debt))
                                            }
                                        } else {
                                            const removeSelected = selectedParcels.filter((el) => el !== p.tracking);
                                            setSelectedParcels([...removeSelected]);
                                            if (p.payed) {
                                                const removeSelectedParcel = hasPayedParcel.filter((item) => item !== p.tracking);
                                                setHasPayedParcel([...removeSelectedParcel]);
                                            } else {
                                                setParcelsDebt((prev) => prev - parseFloat(p.debt))
                                            }
                                        }
                                    }}><input type={"checkbox"}/></td>
                                    <td>{p.tracking}</td>
                                    <td>{p.description}</td>
                                    <td>{p.flight_id}</td>
                                    <td>{p.debt}</td>
                                    <td>{p.payed ?
                                        <img src={"https://usdelivery.ge/static/image/check.png"}
                                             style={{width: "20px"}} alt={"yes icon"}/> :
                                        <img src={"https://usdelivery.ge/static/image/cross.png"}
                                             style={{width: "20px"}}
                                             alt={"yes icon"}/>}</td>
                                    <td>{p.is_declared ?
                                        <img src={"https://usdelivery.ge/static/image/check.png"}
                                             style={{width: "20px"}} alt={"yes icon"}/> :
                                        <img src={"https://usdelivery.ge/static/image/cross.png"}
                                             style={{width: "20px"}}
                                             alt={"yes icon"}/>}</td>
                                    <td>{language[lang].myPage.myParcels[p.status.replace(" ", "_")]}</td>
                                    <td>
                                        <div className={"table-actions-section"}>
                                            <button className={"table-actions-button"} onClick={() => {
                                                setOptionsDropdown({show: true, id: p.tracking});
                                            }}><MoreVertIcon/></button>
                                            {(optionsDropdown.show && optionsDropdown.id === p.tracking) &&
                                                <div className={"options-dropdown-box"}>
                                                    <OutsideClickHandler onOutsideClick={() => {
                                                        setOptionsDropdown({show: false, id: 0})
                                                    }}>
                                                        <ul className={"table-options-list"}>
                                                            <li onClick={() => {
                                                                setActiveParcel({...p});
                                                                setShowViewParcelModal(true);
                                                                setOptionsDropdown({show: false, id: 0})
                                                            }}>{language[lang].myPage.myParcels.view}
                                                            </li>
                                                            <li onClick={() => {
                                                                setActiveParcel({...p});
                                                                setShowDeclaration(true);
                                                                setOptionsDropdown({show: false, id: 0})
                                                            }}>{language[lang].myPage.myParcels.declaration}
                                                            </li>
                                                        </ul>
                                                    </OutsideClickHandler>
                                                </div>}

                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                    }
                    {
                        data.length === 0 && (
                            <tr>
                                <td colSpan={9} style={{padding: "12px 0"}}>
                                    <p style={{width: "100%", textAlign: "center"}}>{language[lang].myPage.myParcels.recordsNotFound}</p>
                                </td>
                            </tr>
                        )
                    }
                    </tbody>
                </Table>
                <div className={"pagination-container"} style={{justifyContent: "flex-end"}}>
                    <Stack spacing={2}>
                        <Pagination count={Math.ceil(total / limit)} size="large" page={page} onChange={(e, v) => setPage(v)} />
                    </Stack>
                </div>
            </div>
            <ViewParcel role="user" lang={lang} language={language} data={activeParcel} show={showViewParcelModal} setShow={setShowViewParcelModal}/>
            <Modal show={showDeliveryModal} onHide={() => setShowDeliveryModal(false)}>
                <Modal.Header><h4>{language[lang].myPage.myParcels.addDelivery}</h4></Modal.Header>
                <Modal.Body>
                    <div className={"form-group"} style={{marginTop: "20px"}}>
                        <label className={"form-label"}>{language[lang].myPage.myParcels.address}</label>
                        <input type={"text"} className={"form-control"} disabled={useDefaultAddress}
                               onChange={(e) => setAddress(e.target.value)}
                               placeholder={language[lang].myPage.myParcels.addressPlaceholder}/>
                    </div>
                    <div style={{marginTop: "20px", cursor: "pointer"}}>
                        <input type={"checkbox"} id={"use-default-address"} onChange={(e) => {
                            setUseDefaultAddress(e.target.checked)
                            if(e.target.checked) {
                                setAddress(defaultAddress)
                            }else {
                                setAddress("");
                            }
                        }}/>
                        <label style={{marginLeft: "10px"}} htmlFor={"use-default-address"}>{defaultAddress}</label>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handlers.delivery} style={{marginRight: "20px"}} variant={"contained"}>{language[lang].myPage.myParcels.submit}</Button>
                    <Button onClick={() => setShowDeliveryModal(false)} variant={"outlined"}>{language[lang].myPage.myParcels.cancel}</Button>
                </Modal.Footer>
            </Modal>
            {notification.show && <Alert severity={notification.type} style={{
                zIndex: "1111111111",
                minWidth: "250px",
                position: "fixed",
                bottom: "20px",
                left: "20px"
            }}>{notification.message}</Alert>}
            <Declaration show={showDeclaration} lang={lang} language={language} data={activeParcel} submit={handlers.declaration} action={"declaration"} hide={setShowDeclaration}/>
        </div>
    )
}

export default MyParcels;