import language from "../store/language";
import {useSelector} from "react-redux";
import {useEffect} from "react";
import {Link} from "react-router-dom";

const HowToBuy = ({setIsMyPage, windowSize}) => {
    const lang = useSelector((state) => state.user.language);
    useEffect(() => {
        setIsMyPage(false)
    }, [])
    return(
        <div className={"information-pages-container"}>
            {windowSize < 880 && (
                <>
                    <div className="horizontal-scroll-menu">
                        <Link to="/info/services/online-parcels">Online Parcels</Link>
                        <Link to="/info/services/delivery-services">Delivery Service</Link>
                        <Link to="/info/useful-information/how-to-buy">How To Buy</Link>
                        <Link to="/info/useful-information/customs-processes">Customs Procedure</Link>
                        <Link to="/info/useful-information/danger-items">Danger Items</Link>
                        <Link to="/info/useful-information/trustees">Trustees</Link>
                        <Link to="/info/useful-information/faq">FAQ</Link>
                    </div>
                </>
            )}
            <div className={"useful-information-banner"}
                 style={{backgroundImage: `url('/static/icons/banner-gradient.jpg')`}}>
                <h5>{language[lang].usefulInformation.howToBuy.title}</h5></div>
            <ul className={"ul-level-one"}>
                <li>{language[lang].usefulInformation.howToBuy.content[0]}</li>
                <li>{language[lang].usefulInformation.howToBuy.content[1]}</li>
                <li>
                    {language[lang].usefulInformation.howToBuy.content[2]}
                    <ul className={"ul-level-two"}>
                        <li>{language[lang].usefulInformation.howToBuy.content[3]}</li>
                        <li>{language[lang].usefulInformation.howToBuy.content[4]}</li>
                        <li>{language[lang].usefulInformation.howToBuy.content[5]}</li>
                        <li>{language[lang].usefulInformation.howToBuy.content[6]}</li>
                        <li>
                            {language[lang].usefulInformation.howToBuy.content[7]}
                            <ul className={"ul-level-three"}>
                                <li>{language[lang].usefulInformation.howToBuy.content[8]}</li>
                                <li>{language[lang].usefulInformation.howToBuy.content[9]}</li>
                                <li>{language[lang].usefulInformation.howToBuy.content[10]}</li>
                                <li>{language[lang].usefulInformation.howToBuy.content[11]}</li>
                                <li>{language[lang].usefulInformation.howToBuy.content[12]}</li>
                            </ul>
                        </li>
                        <li>{language[lang].usefulInformation.howToBuy.content[13]}</li>
                    </ul>
                </li>
            </ul>
        </div>
    )
}

export default HowToBuy