import "../components/Contacts.css";
import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import axios from "axios";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useDispatch, useSelector } from "react-redux";
import language from "../store/language";

const ContactUs = ({ setIsMyPage, notification }) => {
  const lang = useSelector((state) => state.user.language);
  const dispatch = useDispatch();
  const [contactForm, setContactForm] = useState({
    name: "",
    surname: "",
    email: "",
    phone: "",
    message: "",
  });

  useEffect(() => {
    setIsMyPage(false);
  }, []);

  const sendMessage = (e) => {
    e.preventDefault();
    axios
      .post("/api/contacts", contactForm)
      .then((res) => {
        notification({show: true, message: res.data.message[lang], type: "success"});
        setTimeout(() => {
            notification({show: false, message: "", type: ""})
        }, 4000);
        setContactForm({
          name: "",
          surname: "",
          email: "",
          phone: "",
          message: "",
        })
      })
      .catch((err) => {
        notification({show: true, message: err.response.data.message[lang], type: "error"});
        setTimeout(() => {
            notification({show: false, message: "", type: ""})
        }, 4000)
      });
  };

  return (
    <>
      <div className={"contact-page-container"}>
        <div className={"contact-page-banner-blur"}>
          <div className={"contact-page-background-color"}>
            <ul className={"contact-page-contact-information-list"}>
              <li>
                <div>
                  <LocationOnOutlinedIcon
                    style={{ color: "white", width: "50px", fontSize: "35px" }}
                  />
                  <div className={"contact-info-details-value"}>
                    <p>{language[lang].contacts.address}:</p>
                    <p className={"contact-detail-value"}>
                      {language[lang].contacts.addressValue[0]} <br />{" "}
                      {language[lang].contacts.addressValue[1]}
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div>
                  <LocalPhoneOutlinedIcon
                    style={{ color: "white", width: "50px", fontSize: "35px" }}
                  />
                  <div className={"contact-info-details-value"}>
                    <p>{language[lang].contacts.phone}:</p>
                    <a
                      href={"tel:0322445455"}
                      className={"contact-detail-value"}
                    >
                      0322-445-455
                    </a>
                  </div>
                </div>
              </li>
              <li>
                <div>
                  <EmailOutlinedIcon
                    style={{ color: "white", width: "50px", fontSize: "35px" }}
                  />
                  <div className={"contact-info-details-value"}>
                    <p>{language[lang].contacts.support}:</p>
                    <a
                      href={"mailto:info@simplepost.ge"}
                      className={"contact-detail-value"}
                    >
                      info@simplepost.ge
                    </a>
                  </div>
                </div>
              </li>
              <li>
                <div>
                  <AccessTimeIcon
                    style={{ color: "white", width: "50px", fontSize: "35px" }}
                  />
                  <div className={"contact-info-details-value"}>
                    <p>სამუშაო საათები:</p>
                    <p style={{marginTop: "8px"}}>ორშაბათი-პარასკევი 10:00-18:00</p>
                    <p style={{marginTop: "5px"}}>შაბათი 10:00-16:00</p>
                    <p style={{marginTop: "5px"}}>კვირა დასვენება</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div
            className={"contact-page-banner-image"}
            style={{ backgroundImage: `url('/static/icons/contact-page.jpg')` }}
          ></div>
        </div>
        <div className={"contact-form-box-container"}>
          <div className={"contact-form-box"}>
            <h3 style={{ textAlign: "center", color: "#2b2b2b" }}>
              {language[lang].contacts.contact}
            </h3>
            <form onSubmit={sendMessage}>
              <div className={"fields-divider"}>
                <div className={"form-group"} style={{ marginTop: "40px" }}>
                  <label className={"form-label"}>
                    {language[lang].contacts.name}*
                  </label>
                  <input
                    required
                    type={"text"}
                    value={contactForm.name}
                    className={"form-control"}
                    onChange={(e) => {
                      setContactForm((prev) => ({
                        ...prev,
                        name: e.target.value,
                      }));
                    }}
                    placeholder={language[lang].contacts.namePlaceholder}
                  />
                </div>
                <div className={"form-group"} style={{ marginTop: "40px" }}>
                  <label className={"form-label"}>
                    {language[lang].contacts.surname}*
                  </label>
                  <input
                    type={"text"}
                    value={contactForm.surname}
                    className={"form-control"}
                    onChange={(e) => {
                      setContactForm((prev) => ({
                        ...prev,
                        surname: e.target.value,
                      }));
                    }}
                    placeholder={language[lang].contacts.surnamePlaceholder}
                  />
                </div>
              </div>
              <div className={"fields-divider"}>
                <div className={"form-group"} style={{ marginTop: "40px" }}>
                  <label className={"form-label"}>
                    {language[lang].contacts.email}*
                  </label>
                  <input
                    type={"text"}
                    value={contactForm.email}
                    className={"form-control"}
                    onChange={(e) => {
                      setContactForm((prev) => ({
                        ...prev,
                        email: e.target.value,
                      }));
                    }}
                    placeholder={language[lang].contacts.emailPlaceholder}
                  />
                </div>
                <div className={"form-group"} style={{ marginTop: "40px" }}>
                  <label className={"form-label"}>
                    {language[lang].contacts.phone}*
                  </label>
                  <input
                    type={"text"}
                    value={contactForm.phone}
                    className={"form-control"}
                    onChange={(e) => {
                      if (/^[0-9- ]*$/.test(e.target.value)) {
                        setContactForm((prev) => ({
                          ...prev,
                          phone: e.target.value,
                        }));
                      }
                    }}
                    placeholder={language[lang].contacts.phonePlaceholder}
                  />
                </div>
              </div>
              <div className={"form-group"} style={{ marginTop: "40px" }}>
                <label className={"form-label"}>
                  {language[lang].contacts.message}*
                </label>
                <textarea
                  value={contactForm.message}
                  style={{ height: "130px" }}
                  className={"form-control"}
                  onChange={(e) => {
                    setContactForm((prev) => ({
                      ...prev,
                      message: e.target.value,
                    }));
                  }}
                  placeholder={language[lang].contacts.messagePlaceholder}
                />
              </div>
              <div className={"send-message-button-container"}>
                <Button type="submit" style={{ width: "100%" }} variant={"outlined"}>
                  {language[lang].contacts.send}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
