import {Slide} from "react-awesome-reveal";
import {Button} from "@mui/material";
import {useState} from "react";
import axios from "axios";
import {useSelector, useDispatch} from "react-redux";
import language from "../store/language";
import { notificationActions } from "../store/notification";

const Login = ({setShowLoginForm, setShowSignup}) => {
    const lang = useSelector((state) => state.user.language);
    const [showResetPassword, setShowResetPassword] = useState(false);
    const [resetPasswordForm, setResetPasswordForm] = useState("");
    const [error, setError] = useState({show: true, errors: {requireResetPasswordText: "", resetPasswordText: ""}})
    const [resetPasswordSteps, setResetPasswordStep] = useState(1);
    const [resetForm, setResetForm] = useState({code: "", password: "", repeat_password: ""});
    const dispatch = useDispatch();

    const [loginForm, setLoginForm] = useState({
        user: "",
        password: ""
    });
    const [errors, setErrors] = useState({
        user: {show: false, message: ""},
        password: {show: false, message: ""}
    })

    const handlers = {
        login(e){
            e.preventDefault();
            axios.post('/api/auth', loginForm)
                .then((res) => {
                    window.location.href = "/my-page/my-parcels"
                })
                .catch((err) => {
                    const message = err.response.data.message[lang];
                    const errorType = err.response.data.error_type;
                    if(errorType === "notification"){

                    }else{
                        setErrors((prev) => ({...prev, [errorType]: {show: true, message: message}}))
                    }
                })
        },
        requirePasswordReset(){
            axios.post('/api/password/reset', {user: resetPasswordForm})
                .then((res) => {
                    dispatch(notificationActions.setNotification({show: true, message: res.data.message[lang], type: "success"}));
                    setTimeout(() => dispatch(notificationActions.setNotification({show: false, message: "", type: "success"})), 5000)
                    setResetPasswordStep(2);
                })
                .catch((err) => {
                    setError({show: true, errors: {requireResetPasswordText: err.response.data.message[lang], resetPasswordText: ""}});
                    setTimeout(() => dispatch(notificationActions.setNotification({show: false, message: "", type: "success"})), 5000)
                })
        },
        resetPassword(){
            axios.put('/api/password/confirm-reset', resetForm)
                .then((res) => {
                    dispatch(notificationActions.setNotification({show: true, message: res.data.message[lang], type: "success"}));
                    setTimeout(() => dispatch(notificationActions.setNotification({show: false, message: "", type: "success"})), 5000);
                    setResetPasswordStep(1);
                    setShowResetPassword(false);
                })
                .catch((err) => {
                    dispatch(notificationActions.setNotification({show: true, message: err.response.data.message[lang], type: "error"}));
                    setTimeout(() => dispatch(notificationActions.setNotification({show: false, message: "", type: "success"})), 5000)
                })
        }
    }

    return (
        <div className={"login-content"}>
            <Slide className={"login-modal-content"}>
                <>
                    <div className={"login-aside-modal"}>
                        <img src={"/static/icons/logo.png"} alt={"login modal logo"} className={"login-modal-logo"}/>
                        <h5>Simple Post</h5>
                        {!showResetPassword && <>
                            <h4 className={"auth-modal-title"}>{language[lang].auth.login.signIn}</h4>
                            <form style={{marginTop: "20px", width: "90%"}} onSubmit={handlers.login}>
                                <div className={"form-group"}>
                                    <label className={"form-label"}>{language[lang].auth.login.username}</label>
                                    <input type={"text"} className={"form-control"} onChange={(e) => {
                                        setLoginForm((prev) => ({...prev, user: e.target.value}));
                                        setErrors((prev) => ({...prev, user: {show: false, message: ""}}))
                                    }}
                                        placeholder={language[lang].auth.login.userPlaceholder}/>
                                    {errors.user.show && <span className={"form-error-message"}>{errors.user.message}</span>}
                                </div>
                                <div className={"form-group"} style={{marginTop: "20px"}}>
                                    <label className={"form-label"}>{language[lang].auth.login.password}</label>
                                    <input type={"password"} className={"form-control"} onChange={(e) => {
                                        setLoginForm((prev) => ({...prev, password: e.target.value}));
                                        setErrors((prev) => ({...prev, password: {show: false, message: ""}}))
                                    }}
                                        placeholder={language[lang].auth.login.passwordPlaceholder}/>
                                    {errors.password.show && <span className={"form-error-message"}>{errors.password.message}</span>}
                                </div>
                                <div className={"login-subfield-actions"}>
                                    <button onClick={() => setShowResetPassword(true)} type="button">{language[lang].auth.login.resetPassword}</button>
                                </div>
                                <Button type={"submit"} variant={"outlined"} style={{marginTop: "20px", width: "100%"}}>{language[lang].auth.login.login}</Button>
                                <div className={"go-to-sign-up-modal"}>
                                    <p>{language[lang].auth.login.noAccount}</p>
                                    <Button variant={"contained"} style={{marginTop: "12px", width: "100%"}} onClick={() => {
                                        setShowLoginForm(false);
                                        setShowSignup(true)
                                    }}>{language[lang].auth.login.signUp}</Button>
                                </div>
                            </form>
                        </>}
                        {showResetPassword && <>
                            {resetPasswordSteps === 1 && <form className="reset-password-form">
                                <div className="form-group" style={{marginTop: "30px"}}>
                                    <label className="form-label">მომხ. სახელი/ტელ. ნომერი</label>
                                    <input required type="text" onChange={(e) => {
                                        setResetPasswordForm(e.target.value);
                                        setError((prev) => ({...prev, show: false}))
                                    }} className="form-control" placeholder="Enter your phone or email" />
                                    {error.show && <span className="invalid-feedback" style={{display: "block"}}>{error.errors.requireResetPasswordText}</span>}
                                </div>
                                <div className="reset-password-buttons">
                                    <Button onClick={() => setShowResetPassword(false)}>Return</Button>
                                    <Button onClick={handlers.requirePasswordReset} style={{marginLeft: "12px"}} variant={"outlined"}>Next</Button>
                                </div>
                            </form>}
                            {resetPasswordSteps === 2 && <form className="reset-password-form">
                                <div className="form-group" style={{marginTop: "30px"}}>
                                    <label className="form-label">ერთჯერადი კოდი</label>
                                    <input required type="text" onChange={(e) => {
                                        setResetForm((prev) => ({...prev, code: e.target.value}));
                                    }} className="form-control" placeholder="Enter one time code" />
                                </div>
                                <div className="form-group" style={{marginTop: "30px"}}>
                                    <label className="form-label">პაროლი</label>
                                    <input required type="password" onChange={(e) => {
                                        setResetForm((prev) => ({...prev, password: e.target.value}));
                                    }} className="form-control" placeholder="Enter password" />
                                </div>
                                <div className="form-group" style={{marginTop: "30px"}}>
                                    <label className="form-label">გაიმეორეთ პაროლი</label>
                                    <input required type="password" onChange={(e) => {
                                        setResetForm((prev) => ({...prev, repeat_password: e.target.value}));
                                    }} className="form-control" placeholder="Enter password again" />
                                </div>
                                {resetPasswordSteps === 1 && <div className="reset-password-buttons">
                                    <Button onClick={() => setShowResetPassword(false)}>Return</Button>
                                    <Button onClick={handlers.requirePasswordReset} style={{marginLeft: "12px"}} variant={"outlined"}>Next</Button>
                                </div>}
                                {resetPasswordSteps === 2 && <div className="reset-password-buttons">
                                    <Button onClick={() => {
                                        setResetPasswordStep(1);
                                        setResetPasswordForm("");
                                    }}>Return</Button>
                                    <Button onClick={handlers.resetPassword} style={{marginLeft: "12px"}} variant={"outlined"}>Save</Button>
                                </div>}
                            </form>}
                        </>}
                    </div>
                    <div className={"login-backdrop"} onClick={() => setShowLoginForm(false)}></div>
                </>
            </Slide>
        </div>
    )
}

export default Login