import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import axios from "axios";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";

const AddNewParcel = ({
  show,
  setShow,
  action,
  data,
  setActive,
  loadParcels,
}) => {
  const [parcelForm, setParcelForm] = useState({
    tracking: "",
    weight: "",
    country: "china",
    user_id: "",
    status: "",
    receiver: "",
    flight_id: "",
    is_danger: false,
    comment: "",
    problem_type: "",
    problem_status: "",
    has_problem: false,
  });
  const [loading, setLoading] = useState(false);
  const [flights, setFlights] = useState([]);
  const [parcelWithProblem, setParcelWithProblem] = useState(false);
  const problemTypes = [
    { label: "მოსაძებნია კლიენტის მოთხოვნით", value: "need_research" },
    { label: "გასარკვევია მესაკუთრე უცხოეთში", value: "need_research" },
    { label: "ასახულია სხვის ოთახზე", value: "need_research" },
    { label: "გატანილია სხვა მომხმარებლის მიერ", value: "need_research" },
    {
      label: "მომხმარებელს არ ეკუთვნის (მოგვმართა მომხმარებელმა)",
      value: "need_research",
    },
    { label: "გასარკვევია წონის საკითხი", value: "need_research" },
    { label: "გაბრუნდა სელერთან", value: "need_research" },
    { label: "ჩამოვიდა აუსახავი", value: "need_research" },
    { label: "ჩამოსულია თრექინგის გარეშე", value: "need_research" },
    {
      label: "ჩამოვიდა აუსახავი და გასარკვევია მესაკუთრე",
      value: "need_research",
    },
    { label: "ტერმინალზე დაკლებული", value: "need_research" },
    { label: "ასანაზღაურებელი", value: "need_research" },
  ];

  useEffect(() => {
    if (action === "edit") {
      setParcelForm({
        tracking: data.tracking,
        weight: data.weight,
        country: data.country,
        user_id: data.user_id,
        status: data.status,
        receiver: data.receiver,
        flight_id: data.flight_id,
        is_danger: data.is_danger,
        comment: data.comment,
        problem_type: data.problem_type,
        problem_status: data.problem_status,
        has_problem: data.has_problem,
      });
      setParcelWithProblem(data.has_problem)
    } else {
      setParcelForm({
        tracking: "",
        weight: "",
        country: "china",
        user_id: "",
        status: "",
        receiver: "",
        flight_id: "",
        is_danger: false,
        comment: "",
        problem_type: "",
        problem_status: "",
        has_problem: false,
      });
    }
  }, [show]);

  useEffect(() => {
    axios("/api/flights?status=active&page=1&limit=50&keyword=")
      .then((res) => {
        setFlights([...res.data.data]);
      })
      .catch(() => {});
  }, []);

  const handlers = {
    checkTracking() {
      if(parcelForm.tracking.length > 5){
        axios(
          `/api/admin/parcels?limit=1&page=1&keyword=${parcelForm.tracking}&onlyProblem=0&status=all&declaration=all&statusColor=all`
        )
          .then((res) => {
            const parcels = res.data.data[0];
            console.log(res.data)
            if (typeof parcels !== "undefined" && parcels.predeclared === true) {
              setParcelForm((prev) => ({
                ...prev,
                user_id: parcels.user_id,
                status: "warehouse",
                receiver: parcels.receiver,
                country: parcels.country,
              }));
            }
          })
          .catch((err) => {});
      }
    },
    getUserById(e) {
      if (e.keyCode === 13) {
        e.preventDefault();
        axios(
          `/api/admin/users?limit=1&page=1&keyword=${parcelForm.user_id}&position=all`
        )
          .then((res) => {
            const users = res.data.data[0];
            setParcelForm((prev) => ({
              ...prev,
              user_id: users.user_id,
              receiver: `${users.name_en} ${users.surname_en}`,
            }));
          })
          .catch((err) => {});
      }
    },
    addParcel(e) {
      e.preventDefault();
      setLoading(true);
      axios
        .post(`/api/parcels`, parcelForm)
        .then(() => {
          loadParcels();
          setShow(false);
          setLoading(false);
        })
        .catch((err) => {
          const message = err.response.data.message["en"];
          setLoading(false);
          alert(message)
        });
    },
    editParcel(e) {
      e.preventDefault();
      setLoading(true);
      axios
        .put(`/api/parcels/${data.tracking}`, parcelForm)
        .then(() => {
          loadParcels();
          setShow(false);
          setLoading(false);
        })
        .catch(() => {});
    },
    linkFile(e) {
      const formdata = new FormData();
      formdata.append("file", e.target.files[0]);

      axios
        .post(`/api/parcels/${data.tracking}/link-file`, formdata)
        .then((res) => {
          setActive((prev) => ({ ...prev, linked_files: res.data.url }));
        })
        .catch({});
    },
    removeLinkedFile() {
      axios
        .delete(`/api/parcels/${data.tracking}/linked-file`)
        .then(() => {
          setActive((prev) => ({ ...prev, linked_files: "" }));
        })
        .catch({});
    },
  };

  return (
    <Modal show={show} onHide={() => setShow(false)} centered size={"lg"}>
      <Modal.Header>
        <h4>
          {action === "add" ? "Add new parcel" : `Edit parcel ${data.tracking}`}
        </h4>
      </Modal.Header>
      <Modal.Body>
        <form
          onSubmit={action === "add" ? handlers.addParcel : handlers.editParcel}
        >
          <div className={"row"}>
            <div>
              <input
                style={{ cursor: "pointer" }}
                id="parcel-with-problem"
                type="checkbox"
                onChange={(e) => {
                  setParcelWithProblem(e.target.checked);
                  setParcelForm((prev) => ({...prev, has_problem: e.target.checked}));
                  if(e.target.checked) {
                    setParcelForm((prev) => ({...prev, problem_status: "unknown"}))
                  }else{
                    setParcelForm((prev) => ({...prev, problem_status: ""}))
                  }
                }}
                defaultChecked={data.has_problem && action !== "add"}
              />
              <label
                style={{ marginLeft: "5px", cursor: "pointer" }}
                htmlFor="parcel-with-problem"
              >
                Parcel with problem
              </label>
            </div>
            <div className={"col-md-6"}>
              <div className={"form-group mt-4"}>
                <label className={"form-label"}>Tracking</label>
                <input
                  required
                  type={"text"}
                  onChange={(e) =>
                    setParcelForm((prev) => ({
                      ...prev,
                      tracking: e.target.value,
                    }))
                  }
                  onBlur={handlers.checkTracking}
                  value={parcelForm.tracking}
                  className={"form-control"}
                  placeholder={"Enter tracking number"}
                />
              </div>
              <div className={"form-group mt-4"}>
                <label className={"form-label"}>User ID</label>
                <input
                  required
                  type={"text"}
                  onKeyDown={handlers.getUserById}
                  onChange={(e) =>
                    setParcelForm((prev) => ({
                      ...prev,
                      user_id: e.target.value,
                    }))
                  }
                  value={parcelForm.user_id}
                  className={"form-control"}
                  placeholder={"Insert user ID end press Enter"}
                />
              </div>
              <div className={"form-group mt-4"}>
                <label className={"form-label"}>Receiver</label>
                <input
                  required
                  type={"text"}
                  onChange={(e) =>
                    setParcelForm((prev) => ({
                      ...prev,
                      receiver: e.target.value,
                    }))
                  }
                  value={parcelForm.receiver}
                  className={"form-control"}
                  placeholder={"Insert parcel receiver"}
                />
              </div>
              <div className={"form-group mt-4"}>
                <label className={"form-label"}>Weight</label>
                <input
                  required
                  type={"text"}
                  onChange={(e) =>
                    setParcelForm((prev) => ({
                      ...prev,
                      weight: e.target.value,
                    }))
                  }
                  value={parcelForm.weight}
                  className={"form-control"}
                  placeholder={"Insert parcel weight"}
                />
              </div>
              {action === "edit" && (
                <div className={"form-group mt-4"}>
                  <label className={"form-label"}>Linked file</label>
                  <div className="link-parcel-file-container">
                    <input
                      type={"file"}
                      onChange={(e) => {
                        handlers.linkFile(e);
                      }}
                      className={"form-control"}
                      style={{
                        width:
                          data.linked_files !== ""
                            ? "calc(100% - 100px)"
                            : "100%",
                      }}
                    />
                    {data.linked_files !== "" && (
                      <>
                        <a
                          href={data.linked_files}
                          target="_blank"
                          className={"remove-linked-file"}
                          style={{
                            background: "#1976d2",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <DownloadIcon style={{ color: "white" }} />
                        </a>
                        <button
                          onClick={handlers.removeLinkedFile}
                          type="button"
                          className={"remove-linked-file"}
                        >
                          <DeleteIcon style={{ color: "white" }} />
                        </button>
                      </>
                    )}
                  </div>
                </div>
              )}
              {parcelWithProblem && (
                <div className={"form-group mt-4"}>
                  <label className={"form-label"}>Country</label>
                  <select
                    value={parcelForm.country}
                    className={"form-control"}
                    onChange={(e) =>
                      setParcelForm((prev) => ({
                        ...prev,
                        country: e.target.value,
                      }))
                    }
                  >
                    <option value={""}>Select country</option>
                    <option value={"China"}>China</option>
                  </select>
                </div>
              )}
            </div>
            <div className={"col-md-6"}>
              {parcelWithProblem ? (
                <>
                  <div className={"form-group mt-4"}>
                    <label className={"form-label"}>Problem Type</label>
                    <select className={"form-control"} value={parcelForm.problem_type} onChange={(e) => setParcelForm((prev) => ({...prev, problem_type: e.target.value}))}>
                      <option value={""}>Select Problem Type</option>
                      {problemTypes.map((p) => (
                        <option value={p.value}>{p.label}</option>
                      ))}
                    </select>
                  </div>
                  <div className={"form-group mt-4"}>
                    <label className={"form-label"}>Problem Status</label>
                    <select className={"form-control"} value={parcelForm.problem_status} onChange={(e) => setParcelForm((prev) => ({...prev, problem_status: e.target.value}))}>
                      <option value={""}>Select Problem Status</option>
                      <option value={"unknown"}>გასარკვევი</option>
                      <option value={"known"}>გარკვეული</option>
                      <option value={"waiting_for_user"}>
                        ველოდებით მომხმარებელს
                      </option>
                      <option value={"done"}>დასრულებული</option>
                    </select>
                  </div>
                </>
              ) : (
                <div className={"form-group mt-4"}>
                  <label className={"form-label"}>Country</label>
                  <select
                    value={parcelForm.country}
                    className={"form-control"}
                    onChange={(e) =>
                      setParcelForm((prev) => ({
                        ...prev,
                        country: e.target.value,
                      }))
                    }
                  >
                    <option value={""}>Select country</option>
                    <option value={"China"}>China</option>
                  </select>
                </div>
              )}
              <div className={"form-group mt-4"}>
                <label className={"form-label"}>Flights</label>
                <select
                  value={parcelForm.flight_id}
                  className={"form-control"}
                  onChange={(e) => {
                    const selected = flights.filter(
                      (f) => f.flight_id === e.target.value
                    );
                    setParcelForm((prev) => ({
                      ...prev,
                      flight_id: e.target.value,
                      status: selected[0].status,
                    }));
                  }}
                >
                  <option value={""}>Select Flight</option>
                  {flights.map((f) => (
                    <option value={f.flight_id}>{f.flight_id}</option>
                  ))}
                </select>
              </div>
              <div className={"form-group mt-4"}>
                <label className={"form-label"}>Comment</label>
                <textarea
                  onChange={(e) =>
                    setParcelForm((prev) => ({
                      ...prev,
                      comment: e.target.value,
                    }))
                  }
                  value={parcelForm.comment}
                  className={"form-control"}
                  placeholder={"Enter comment text"}
                  style={{ height: "124px" }}
                />
              </div>
            </div>
          </div>
          <div className={"modal-footer"} style={{ marginTop: "30px" }}>
            {!loading ? <Button type={"submit"} variant={"contained"}>
              Submit
            </Button> : <Button disabled type={"submit"} variant={"contained"}>
              ...Loading
            </Button>}
            <Button
              onClick={() => setShow(false)}
              variant={"outlined"}
              style={{ marginLeft: "20px" }}
            >
              Cancel
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddNewParcel;
