import "./MyPage.css"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import axios from "axios";
import {useEffect, useState} from "react";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import MenuIcon from '@mui/icons-material/Menu';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import { notificationActions } from "../../store/notification";
import { CircularProgress } from "@mui/material";

const Aside = ({selectedParcels, userType, selectedDelivery, payedSelectedDelivery, isMyPage, setIsMyPage, setData, hasPayedParcel, currentContent, setCurrentContentTitle, lang, language}) => {
    const [user, setUser] = useState({});
    const [unreadMessages, setUnreadMessages] = useState(0);
    const conversation_id = useSelector((state) => state.user.conversation_id);
    const dispatch = useDispatch();
    const [loadingPayment, setLoadingPayment] = useState(false);

    
    useEffect(() => {
        axios('/api/users')
            .then((res) => {
                setUser({...res.data.data})
                setData({...res.data.data});
            })
            .catch((err) => {

            })
        axios(`/api/messages/unread/count?conversation_id=${conversation_id}`)
            .then((res) => {
                setUnreadMessages(res.data.total)
            })
            .catch(() => {

            })
    }, []);

    const handlers = {
        paySelectedParcels(){
            setLoadingPayment(true);
            axios.post('/api/payments/bog/pay-selected', {tracking: selectedParcels, windowLocation: window.location.href, lang: lang})
                .then((res) => {
                    setLoadingPayment(false);
                    window.location.href = res.data.data.url
                })
                .catch((err) => {
                    setLoadingPayment(false);
                    dispatch(notificationActions.setNotification({show: true, message: err.response.data.message[lang], type: "error"}));
                    setTimeout(() => {
                        dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                    }, 4000)
                })
        },
        payAllParcels(){
            setLoadingPayment(true);
            axios.post('/api/payments/pay-all-debt', {windowLocation: window.location.href, lang: lang})
                .then((res) => {
                    setLoadingPayment(false);
                    window.location.href = res.data.data.url
                })
                .catch((err) => {
                    setLoadingPayment(false);
                    dispatch(notificationActions.setNotification({show: true, message: err.response.data.message[lang], type: "error"}));
                    setTimeout(() => {
                        dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                    }, 4000)
                })
        },
        payDelivery(){
            setLoadingPayment(true);
            axios.post('/api/payments/pay-delivery', {windowLocation: window.location.href, lang: lang, delivery: selectedDelivery})
                .then((res) => {
                    setLoadingPayment(false);
                    window.location.href = res.data.data.url
                })
                .catch((err) => {
                    setLoadingPayment(false);
                    dispatch(notificationActions.setNotification({show: true, message: err.response.data.message[lang], type: "error"}));
                    setTimeout(() => {
                        dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                    }, 4000)
                })
        },
        payDeliveryAndParcel(){
            setLoadingPayment(true);
            axios.post('/api/payments/pay-delivery-parcel', {windowLocation: window.location.href, lang: lang})
                .then((res) => {
                    setLoadingPayment(false);
                    window.location.href = res.data.data.url
                })
                .catch((err) => {
                    setLoadingPayment(false);
                    dispatch(notificationActions.setNotification({show: true, message: err.response.data.message[lang], type: "error"}));
                    setTimeout(() => {
                        dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                    }, 4000)
                })
        }
    }

    return (
        <div className={"my-page-aside-model-content"}>
            <div className={"my-page-avatar"}>
                {typeof user.profile_image !== "string" ? <div className={"my-page-initials"}>
                    {
                        userType === "legal" ? <p>{typeof user.company_name_en !== "undefined" && `${user.company_name_en.slice(0, 1)}`}</p> : <p>{typeof user.name_en !== "undefined" && `${user.name_en.slice(0, 1)}.${user.surname_en.slice(0, 1)}`}</p>
                    }
                </div> : <div className={"my-page-aside-profile-image"} style={{backgroundImage: `url('${user.profile_image}')`}}></div>}

                <div className={"my-page-user-info"}>
                    <p className={"user-fullname"}>{userType === "legal" ? `${user.company_name_geo}` : `${user.name_en} ${user.surname_en}`}</p>
                    <p className={"my-page-user-id"}>{user.user_id}</p>
                </div>
                {isMyPage && <ArrowBackIcon onClick={() => setIsMyPage(false)}
                                style={{position: "absolute", zIndex: "111", cursor: "pointer", marginLeft: "210px"}}/>}
            </div>
            <div className={"my-page-tabs"}>
                <p className={"my-page-aside-section-title"}>{language[lang].myPage.aside.title}</p>
                <ul className={"my-page-tabs-list"}>
                    <li onClick={() => setCurrentContentTitle("My Parcels")}><Link onClick={() => setIsMyPage(false)} to={"/my-page/my-parcels"} className={"my-page-aside-link"}>
                        <img src={"/static/icons/parcels.png"}
                             alt={"parcels"}
                             className={"my-page-tab-list-icons"}/>
                        <p>{language[lang].myPage.aside.parcels}</p>
                    </Link></li>
                    <li onClick={() => setCurrentContentTitle("Preliminary Declared")}>
                        <Link onClick={() => setIsMyPage(false)} to={"/my-page/preliminary-declared"} className={"my-page-aside-link"}>
                            <img
                                src={"/static/icons/predeclared.png"} alt={"parcels"}
                                className={"my-page-tab-list-icons"}/>
                            <p>{language[lang].myPage.aside.preDeclared}</p>
                        </Link>
                    </li>
                    <li onClick={() => setCurrentContentTitle("Archived Parcels")}>
                        <Link onClick={() => setIsMyPage(false)} to={"/my-page/archived-parcels"} className={"my-page-aside-link"}>
                            <img
                                src={"/static/icons/archive-parcels.svg"} alt={"parcels"}
                                className={"my-page-tab-list-icons"}/>
                            <p>{language[lang].myPage.aside.archived}</p>
                        </Link>
                    </li>
                    <li onClick={() => setCurrentContentTitle("Addresses")}>
                        <Link onClick={() => setIsMyPage(false)} to={"/my-page/addresses"} className={"my-page-aside-link"}>
                            <GpsFixedIcon style={{fontSize: "30px"}}
                                          className={"my-page-tab-list-icons"}/>
                            <p>{language[lang].myPage.aside.addresses}</p>
                        </Link>
                    </li>
                    <li>
                        <Link onClick={() => setIsMyPage(false)} to={"/my-page/payments-history"} className={"my-page-aside-link"}>
                            <CreditCardIcon style={{fontSize: "30px"}} className={"my-page-tab-list-icons"}/>
                            <p>{language[lang].myPage.aside.payments}</p>
                        </Link>
                    </li>
                    <li onClick={() => setCurrentContentTitle("Delivery")}>
                        <Link onClick={() => setIsMyPage(false)} to={"/my-page/delivery"} className={"my-page-aside-link"}>
                            <LocalShippingIcon style={{fontSize: "30px"}} className={"my-page-tab-list-icons"}/>
                            <p>{language[lang].myPage.aside.delivery}</p>
                        </Link>
                    </li>
                    <li>
                        <Link onClick={() => {
                            setIsMyPage(false);
                            setUnreadMessages(0);
                        }} to={"/my-page/inbox"} className={"my-page-aside-link"}>
                            <MailOutlineIcon style={{fontSize: "30px"}} className={"my-page-tab-list-icons"}/>
                            <p>{language[lang].myPage.aside.inbox}</p>
                            <span className={"unread-messages-count"}>{unreadMessages}</span>
                        </Link>
                    </li>
                    <li onClick={() => setCurrentContentTitle("Profile")}>
                        <Link onClick={() => setIsMyPage(false)} to={"/my-page/profile"} className={"my-page-aside-link"}>
                            <PersonOutlineIcon style={{fontSize: "30px"}}
                                               className={"my-page-tab-list-icons"}/>
                            <p>{language[lang].myPage.aside.profile}</p>
                        </Link>
                    </li>
                </ul>
            </div>
            <div className={"my-page-balance-section"}>
                <div className={"my-page-balance-types"}>
                    <p className={"my-page-aside-section-title"}>{language[lang].myPage.aside.myDebt}</p>
                    <div className={"balance-type-box"}>
                        {/*<img src={"/static/icons/debt.png"} className={"balance-icons"} alt={"balance-icons"}/>*/}
                        <AttachMoneyIcon style={{width: "30px"}} className={"balance-icons"}/>
                        <p>{language[lang].myPage.aside.debt} {typeof user.debt !== "undefined" ? user.debt.toFixed(2) : ""} ₾</p>
                    </div>
                    <div className={"balance-type-box"}>
                        {/*<img src={"/static/icons/debt.png"} className={"balance-icons"} alt={"balance-icons"}/>*/}
                        <AttachMoneyIcon style={{width: "30px"}} className={"balance-icons"}/>
                        <p>{language[lang].myPage.aside.deliveryDebt} {typeof user.delivery_debt !== "undefined" ? user.delivery_debt.toFixed(2) : ""} ₾</p>
                    </div>
                    {currentContent === "My Parcels" && <div className={"payment-types-box"}>
                        <button onClick={handlers.paySelectedParcels} disabled={selectedParcels.length === 0 || hasPayedParcel.length !== 0 || loadingPayment == true}
                                title={selectedParcels.length === 0 ? "Please select parcels to process" : (hasPayedParcel.length !== 0 ? "You have selected parcel with status 'payed'" : "")}
                                className={`pay-selected-button ${(selectedParcels.length === 0 || hasPayedParcel.length !== 0) ? "disabled-button" : ""}`}>{loadingPayment ? "Loading..." : "Pay Selected" }
                        </button>
                        <button onClick={handlers.payAllParcels} disabled={!user.debt > 0 || loadingPayment == true} title={!user.debt > 0 ? "No debt found" : ""}
                                className={`pay-all-button ${!user.debt > 0 ? "disabled-button" : ""}`}>{loadingPayment ? "Loading..." : "Pay All" }
                        </button>
                    </div>}
                    {currentContent === "Delivery" && <div className={"payment-types-box"}>
                        <button onClick={handlers.payDelivery} disabled={selectedDelivery.length === 0 || payedSelectedDelivery.length !== 0}
                                title={selectedDelivery.length === 0 ? "Please select parcels to process" : (payedSelectedDelivery.length !== 0 ? "You have selected parcel with status 'payed'" : "")}
                                className={`pay-selected-button ${(selectedDelivery.length === 0 || payedSelectedDelivery.length !== 0) ? "disabled-button" : ""}`}>Pay
                            Delivery
                        </button>
                        <button onClick={handlers.payDeliveryAndParcel} disabled={!user.debt > 0} title={!user.debt > 0 ? "No debt found" : ""}
                                className={`pay-all-button ${!user.debt > 0 ? "disabled-button" : ""}`}>Pay All
                        </button>
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default Aside