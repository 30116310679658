import {Modal} from "react-bootstrap";
import {useEffect, useState} from "react";
import {Button} from "@mui/material";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import { notificationActions } from "../../../store/notification";

const BlockParcel = ({show, hide, active, reload}) => {
    const [parcelForm, setParcelForm] = useState({comment: "", status: ""});
    const dispatch = useDispatch();
    const lang = useSelector((state) => state.user.language)

    const saveChanges = (e) => {
        e.preventDefault();
        axios.put(`/api/parcels/${active.tracking}/blocking-process`, parcelForm)
            .then(() => {
                hide(false);
                setParcelForm({comment: "", status: ""});
                reload();
            })
            .catch((err) => {
                dispatch(notificationActions.setNotification({show: true, message: err.response.data.message[lang], type: "error"}));
                setTimeout(() => {
                    dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                }, 4000)
            })
    }

    useEffect(() => {
        if(show){
            setParcelForm({comment: active.parcel_blocking_reason, status: active.block_status})
        }else{
            setParcelForm({comment: "", status: ""})
        }
    }, [show]);

    return (
        <Modal show={show} onHide={() => hide(false)} centered={true} size={"md"}>
            <Modal.Header><h4>Block Parcel</h4></Modal.Header>
            <Modal.Body>
                <form onSubmit={saveChanges}>
                    <div className={"form-group mt-2"}>
                        <label className={"form-label"}>Receiver</label>
                        <textarea
                            required
                            onChange={(e) => setParcelForm((prev) => ({
                                ...prev,
                                comment: e.target.value
                            }))}
                            value={parcelForm.comment}
                            className={"form-control"}
                            style={{height: "100px"}}
                            placeholder={"ჩაწერეთ გზავნილის დაბლოკვის მიზეზი"}
                        />
                    </div>
                    <fieldset id="parcel_status" onChange={(e) => setParcelForm((prev) => ({...prev, status: e.target.value}))} className={"radio-buttons-group"}>
                        <input className={"form-switch"} defaultChecked={active.block_status === "active"} id={"active-parcel"} type="radio" value="active"
                               name="parcel_status"/>
                        <label htmlFor={"active-parcel"}>გააქტიურება</label>
                        <input type="radio" id={"stop-parcel"} value="stopped" defaultChecked={active.block_status === "stopped"} name="parcel_status"/>
                        <label htmlFor={"stop-parcel"}>გაჩერება</label>
                        <input type="radio" id={"restrict-parcel"} value="prohibition" defaultChecked={active.block_status === "prohibition"} name="parcel_status"/>
                        <label htmlFor={"restrict-parcel"}>გაცემის აკრძალვა</label>
                    </fieldset>
                    <div className={"modal-footer"} style={{marginTop: "30px"}}>
                        <Button type={"submit"} variant={"contained"}>შენახვა</Button>
                        <Button onClick={() => hide(false)} variant={"outlined"}
                                style={{marginLeft: "20px"}}>დახურვა</Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default BlockParcel