import {Modal} from "react-bootstrap";
import {useEffect, useState} from "react";
import axios from "axios";
import {Button} from "@mui/material";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { notificationActions } from "../../../store/notification";
import {useDispatch, useSelector} from "react-redux";

const EditDelivery = ({show, setShow, action, data, loadDelivery}) => {
    const [deliveryForm, setDeliveryForm] = useState({
        address: "", price: "", status: "", is_payed: false
    });
    const dispatch = useDispatch();
    const lang = useSelector((state) => state.user.language);

    useEffect(() => {
        setDeliveryForm({
            address: data.address, price: data.price === null ? "" : data.price, status: data.status, is_payed: data.is_payed
        })
    }, [show]);

    const handlers = {
        editDelivery(e){
            e.preventDefault();
            axios.put(`/api/admin/delivery/${data.id}`, deliveryForm)
                .then(() => {
                    loadDelivery();
                    setShow(false);
                })
                .catch((err) => {
                    dispatch(notificationActions.setNotification({show: true, message: err.response.data.message[lang], type: "error"}));
                    setTimeout(() => {
                        dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                    }, 4000)
                })
        }
    }

    return(
        <Modal show={show} onHide={() => setShow(false)} centered size={"lg"}>
            <Modal.Header><h4>Edit delivery</h4></Modal.Header>
            <Modal.Body>
                <form onSubmit={handlers.editDelivery}>
                    <div className={"row"}>
                        <div className={"col-md-6"}>
                            <div className={"form-group mt-4"}>
                                <label className={"form-label"}>Address</label>
                                <input
                                    required
                                    typeof={"text"}
                                    onChange={(e) => setDeliveryForm((prev) => ({
                                        ...prev,
                                        address: e.target.value
                                    }))}
                                    value={deliveryForm.address}
                                    className={"form-control"}
                                    placeholder={"Enter delivery address"}
                                />
                            </div>
                            <div className={"form-group mt-4"}>
                                <label className={"form-label"}>Price</label>
                                <input
                                    required
                                    typeof={"text"}
                                    onChange={(e) => setDeliveryForm((prev) => ({
                                        ...prev,
                                        price: e.target.value
                                    }))}
                                    value={deliveryForm.price}
                                    className={"form-control"}
                                    placeholder={"Enter delivery price"}
                                />
                            </div>
                        </div>
                        <div className={"col-md-6"}>
                            <div className={"form-group mt-4"}>
                                <label className={"form-label"}>Status</label>
                                <select value={deliveryForm.status}
                                        className={"form-control"}
                                        onChange={(e) => {
                                            setDeliveryForm((prev) => ({
                                                ...prev,
                                                status: e.target.value
                                            }));
                                        }}>
                                    <option value={"pending"}>Pending</option>
                                    <option value={"on way"}>On way</option>
                                    <option value={"delivered"}>Delivered</option>
                                </select>
                            </div>
                            <div className={"form-group mt-4"} style={{flexDirection: "column", display: "flex"}}>
                                <label className={"form-label"}>Is payed</label>
                                <input
                                    hidden
                                    id={"is-payed-checkbox"}
                                    type={"checkbox"}
                                    value={deliveryForm.is_payed}
                                    onChange={(e) => setDeliveryForm((prev) => ({
                                        ...prev,
                                        is_payed: e.target.checked
                                    }))}
                                    defaultChecked={deliveryForm.is_payed}
                                    className={"form-check"}
                                />
                                <label htmlFor={"is-payed-checkbox"} style={{cursor: "pointer", width: "fit-content"}}>
                                    {!deliveryForm.is_payed && <><CheckBoxOutlineBlankIcon style={{color: "red", fontSize: "28px"}}/> <span>Not Payed</span></>}
                                    {deliveryForm.is_payed && <><CheckBoxIcon style={{color: "green", fontSize: "28px"}}/><span>Payed</span></>}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={"modal-footer"} style={{marginTop: "30px"}}>
                        <Button type={"submit"} variant={"contained"}>Submit</Button>
                        <Button onClick={() => setShow(false)} variant={"outlined"}
                                style={{marginLeft: "20px"}}>Cancel</Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default EditDelivery