import {useState} from "react";

const ConversationCard = ({data, setActive, selectedChat, setSelectedChat}) => {

    return (
        <div onClick={() => {
            setActive({...data});
            setSelectedChat(data.id);
        }} className={`conversation-card ${data.id === selectedChat ? 'active-chat-conversation' : ""}`}>
            <div className={"messenger-profile-image"} style={{backgroundImage: `url('${data.profile_image}')`}}>{
                data.profile_image == null ? <p>{data.name_geo.slice(0, 1)}.{data.surname_geo.slice(0, 1)}.</p> : ""
            }</div>
            <div className={"messenger-profile-user-details"}>
                <div className={"conversation-text-divider"}>
                    <div>
                        <p>{data.name_geo}</p>
                        <p>{data.surname_geo}</p>
                    </div>
                    <p>{data.user_id}</p>
                </div>
                <p style={{color: "black", marginTop: "5px"}}>{data.last_message}...</p>
            </div>
            {data.unread > 0 && <div className={"admin-conversations-unread-counter"}>{data.unread}</div>}
        </div>
    )
}

export default ConversationCard