import {useEffect, useState} from "react";
import axios from "axios";
import EditIcon from '@mui/icons-material/Edit';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import {Button} from "@mui/material";
import Alert from "@mui/material/Alert";
import {Modal} from "react-bootstrap";
import ChangePassword from './ChangePassword';
import SendIcon from "@mui/icons-material/Send";
import {useDispatch} from "react-redux"
import { notificationActions } from "../../store/notification";

const Profile = ({lang, language, setCurrentContentTitle}) => {
    const dispatch = useDispatch();
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const [data, setData] = useState({});
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileName, setFileName] = useState("");
    const [userForm, setUserForm] = useState({
        username: "",
        phone: "",
        email: "",
        city: "",
        address: "",
        name_geo: "",
        name_en: "",
        surname_geo: "",
        surname_en: ""
    });
    const [cities, setCities] = useState([]);
    const [showVerifyPhone, setShowVerifyPhone] = useState(false);
    const [showVerifyEmail, setShowVerifyEmail] = useState(false);
    const [notification, setNotification] = useState({show: false, type: "", message: ""});
    const [verify, setVerify] = useState({email: "", phone: ""});
    const [errors, setErrors] = useState({
        email: false,
        username: false,
        phone: false,
        confirm_email: true,
        confirm_phone: true
    });

    const getUser = () => {
        axios('/api/users')
            .then((res) => {
                const user = res.data.data;
                setData({...user});
                setUserForm({
                    username: user.username,
                    email: user.email,
                    phone: user.phone,
                    city: user.city,
                    address: user.address,
                    name_geo: user.name_geo,
                    name_en: user.name_en,
                    surname_geo: user.surname_geo,
                    surname_en: user.surname_en
                })
            })
            .catch((err) => {
                dispatch(notificationActions.setNotification({show: true, message: err.response.data.message[lang], type: "error"}));
                setTimeout(() => {
                    dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                }, 4000)
            })
    }

    useEffect(getUser, []);
    useEffect(() => setCurrentContentTitle("Profile"), []);
    useEffect(() => {
        const urlencoded = new URLSearchParams();
        urlencoded.append("country", "Georgia");
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://countriesnow.space/api/v0.1/countries/cities',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: urlencoded
        };

        axios(config)
            .then((response) => {
                const data = response.data.data;
                setCities([...data]);
            })
            .catch((err) => {
                dispatch(notificationActions.setNotification({show: true, message: err.response.data.message[lang], type: "error"}));
                setTimeout(() => {
                    dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                }, 4000)
            })
    }, []);

    const handlers = {
        uploadProfileImage() {
            const formData = new FormData();
            formData.append("image", selectedFile);
            axios.put('/api/users/files/avatar-image', formData)
                .then((res) => {
                    const imageUrl = res.data.image;
                    setData((prev) => ({...prev, profile_image: imageUrl}));
                    setFileName("");
                    setNotification({show: true, type: "success", message: "Profile image changed"});
                    setTimeout(() => setNotification({show: false, type: "", message: ""}), 6000);
                })
                .catch((err) => {
                    const message = err.response.data.message;
                    setNotification({show: true, type: "error", message: "Something went wrong"});
                    setTimeout(() => setNotification({show: false, type: "", message: ""}), 6000);
                })
        },
        selectProfileImage(e) {
            const image = e.target.files[0];
            setFileName(image.name);
            setSelectedFile(image);
        },
        validate(key, value) {
            axios(`/api/signup/validate?key=${key}&value=${value}`)
                .then((res) => {
                    if (!res.data.is_valid && value !== data[key]) {
                        setErrors((prev) => ({...prev, [key]: true}));
                        setNotification({show: true, type: "error", message: `${key} is not valid`});
                        setTimeout(() => setNotification({show: false, type: "", message: ""}), 6000);
                    } else {
                        setErrors((prev) => ({...prev, [key]: false}))
                    }
                })
                .catch((err) => {
                    setErrors((prev) => ({...prev, [key]: true}));
                    const message = err.response.data.message;
                    setNotification({show: true, type: "error", message: "Something went wrong"});
                    setTimeout(() => setNotification({show: false, type: "", message: ""}), 6000);
                })
        },
        sendCodeToEmail() {
            axios.post('/api/email/verify', {email: userForm.email})
                .then((res) => {
                    setNotification({show: true, type: "success", message: "Verification code sent to your email"});
                    setTimeout(() => setNotification({show: false, type: "", message: ""}), 6000);
                    setShowVerifyEmail(true);
                })
                .catch((err) => {
                    setNotification({show: true, type: "error", message: err.response.data.status});
                    setTimeout(() => setNotification({show: false, type: "", message: ""}), 6000);
                })
        },
        sendCodeToPhone() {
            axios.post('/api/phone/verify', {phone: userForm.phone})
                .then((res) => {
                    setNotification({show: true, type: "success", message: "Verification code sent to your phone"});
                    setTimeout(() => setNotification({show: false, type: "", message: ""}), 6000);
                    setShowVerifyPhone(true);
                })
                .catch((err) => {
                    setNotification({show: true, type: "error", message: err.response.data.status});
                    setTimeout(() => setNotification({show: false, type: "", message: ""}), 6000);
                })
        },
        confirmEmail() {
            axios.post('/api/email/confirm', {oneTimeCode: verify.email})
                .then((res) => {
                    setNotification({show: true, type: "success", message: "Email address verified"});
                    setTimeout(() => setNotification({show: false, type: "", message: ""}), 6000);
                    setErrors((prev) => ({...prev, confirm_email: false}));
                })
                .catch((err) => {
                    setNotification({show: true, type: "error", message: "Wrong verification code"});
                    setTimeout(() => setNotification({show: false, type: "", message: ""}), 6000);
                    setErrors((prev) => ({...prev, confirm_email: true}));
                })
        },
        confirmPhone() {
            axios.post('/api/phone/confirm', {oneTimeCode: verify.email})
                .then((res) => {
                    setNotification({show: true, type: "success", message: "Phone verified"});
                    setTimeout(() => setNotification({show: false, type: "", message: ""}), 6000);
                    setErrors((prev) => ({...prev, confirm_phone: false}));
                })
                .catch((err) => {
                    setNotification({show: true, type: "error", message: "Wrong verification code"});
                    setTimeout(() => setNotification({show: false, type: "", message: ""}), 6000);
                    setErrors((prev) => ({...prev, confirm_phone: true}));
                })
        },
        editUser(e){
            e.preventDefault();
            if(errors.username || errors.email || errors.phone || (errors.confirm_email && userForm.email !== data.email) || (errors.confirm_phone && userForm.phone !== data.phone)){
                setNotification({show: true, type: "error", message: "Please validate all form fields"});
                setTimeout(() => setNotification({show: false, type: "", message: ""}), 6000);
            }else{
                axios.put(`/api/users/${data.user_id}`, userForm)
                    .then(() => {
                        getUser();
                        setNotification({show: true, type: "success", message: "User data updated successfully"});
                        setTimeout(() => setNotification({show: false, type: "", message: ""}), 6000);
                    })
                    .catch((err) => {
                        const message = err.response.data.message;
                        setNotification({show: true, type: "error", message: message});
                        setTimeout(() => setNotification({show: false, type: "", message: ""}), 6000);
                    })
            }
        },
        revert(){
            getUser()
        }
    }

    return (
        <>
            <div className={"my-page-profile"}>
                <div className={"profile-page-header"}>
                    <div className={"profile-image-container"}>
                        <div>
                            <div className={"profile-image-box"}
                                 style={{backgroundImage: `url('${data.profile_image}')`}}>
                                {typeof data.profile_image != "string" && (
                                    <ImageSearchIcon style={{fontSize: "125px", color: "gray"}}/>
                                )}
                            </div>
                            <div className={"upload-profile-image-section"}>
                                <input id={"upload-profile-image"} type={"file"} onChange={handlers.selectProfileImage}
                                       accept="image/*" hidden/>
                                <label style={{cursor: "pointer"}} htmlFor={"upload-profile-image"}
                                       className={"select-profile-image"}>{language[lang].myPage.myParcels.selectImg}</label>
                                <button disabled={fileName === ""}
                                        className={`upload-image-button ${fileName === "" ? "disabled-button" : ""}`}
                                        onClick={handlers.uploadProfileImage}>{language[lang].myPage.myParcels.uploadImg}
                                </button>
                            </div>
                        </div>
                        <div className={"profile-details-section"}>
                            <p className={"profile-page-info-name"}>{data.name_geo} {data.surname_geo}</p>
                            <p className={"profile-page-info-address"}>{data.city} - {data.address}</p>
                            <p className={"profile-page-info-others"} style={{marginTop: "20px"}}>{data.email}</p>
                            <p className={"profile-page-info-others"}>{data.phone}</p>
                            <p className={"profile-page-info-others"}>{data.number_of_id}</p>
                        </div>
                    </div>
                    <Button variant={"outlined"} onClick={() => setShowChangePasswordModal(true)}>{language[lang].myPage.myParcels.changePass}</Button>
                </div>

                <form onSubmit={handlers.editUser}>
                    <div className={"row"}>
                        <div className={"col-md-4"}>
                            <div className={"form-group"} style={{marginTop: "40px"}}>
                                <label className={"form-label"}>{language[lang].myPage.myParcels.username}*</label>
                                <input required type={"text"} value={userForm.username} className={"form-control"}
                                       onChange={(e) => setUserForm((prev) => ({
                                           ...prev,
                                           username: e.target.value
                                       }))}
                                       placeholder={language[lang].myPage.myParcels.usernamePlaceholder} onBlur={() => {
                                    handlers.validate("username", userForm.username);
                                }}/>
                                {errors.username && <p className={"form-error-message"} style={{position: "absolute"}}>{language[lang].myPage.myParcels.errors.usernameAvailable}</p>}
                            </div>
                            <div className={"form-group"} style={{marginTop: "40px"}}>
                                <label className={"form-label"}>{language[lang].myPage.myParcels.email}*</label>
                                <div style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                                    <input required type={"text"} value={userForm.email} className={"form-control"}
                                           style={{paddingRight: "90px"}}
                                           onChange={(e) => setUserForm((prev) => ({
                                               ...prev,
                                               email: e.target.value
                                           }))}
                                           placeholder={language[lang].myPage.myParcels.emailPlaceHolder} onBlur={() => {
                                        handlers.validate("email", userForm.email);
                                    }}/>
                                    <button className="verify-button" onClick={handlers.sendCodeToEmail}><SendIcon style={{color: "white"}}/>
                                    </button>
                                </div>
                                {errors.email && <p className={"form-error-message"} style={{position: "absolute"}}>{language[lang].myPage.myParcels.errors.emailAvailable}</p>}
                            </div>

                        </div>
                        <div className={"col-md-4"}>
                            <div className={"form-group"} style={{marginTop: "40px"}}>
                                <label className={"form-label"}>{language[lang].myPage.myParcels.phone}*</label>
                                <div style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                                    <input required type={"text"} value={userForm.phone} className={"form-control"}
                                           style={{paddingRight: "90px"}}
                                           onChange={(e) => setUserForm((prev) => ({
                                               ...prev,
                                               phone: e.target.value
                                           }))}
                                           placeholder={language[lang].myPage.myParcels.phonePlaceHolder} onBlur={() => {
                                        handlers.validate("phone", userForm.phone);
                                    }}/>
                                    <button className="verify-button" onClick={handlers.sendCodeToPhone}><SendIcon style={{color: "white"}}/>
                                    </button>
                                </div>
                                {errors.phone &&
                                    <p className={"form-error-message"} style={{position: "absolute"}}>Username not
                                        available!</p>}
                            </div>
                            <div className={"form-group"} style={{marginTop: "40px"}}>
                                <label className={"form-label"}>{language[lang].myPage.myParcels.city}*</label>
                                <select className={"form-control"} value={userForm.city} onChange={(e) => {
                                    setUserForm((prev) => ({...prev, city: e.target.value}));
                                }}>
                                    {
                                        cities.map((c, k) => <option key={k} value={c}>{c}</option>)
                                    }
                                </select>
                            </div>
                        </div>
                        <div className={"col-md-4"}>
                            <div className={"form-group"} style={{marginTop: "40px"}}>
                                <label className={"form-label"}>{language[lang].myPage.myParcels.address}*</label>
                                <input required type={"text"} value={userForm.address} className={"form-control"}
                                       onChange={(e) => setUserForm((prev) => ({
                                           ...prev,
                                           address: e.target.value
                                       }))}
                                       placeholder={language[lang].myPage.myParcels.addressPlaceholder}/>
                            </div>
                        </div>
                    </div>
                    <div className={"profile-action-buttons"}>
                        <Button variant={"outlined"} onClick={handlers.revert}>{language[lang].myPage.myParcels.revert}</Button>
                        <Button variant={"contained"} type={"submit"}>{language[lang].myPage.myParcels.save}</Button>
                    </div>
                </form>
                <Modal show={showVerifyEmail} onHide={() => setShowVerifyEmail(false)}>
                    <Modal.Header><h3>Verify Email</h3></Modal.Header>
                    <Modal.Body>
                        <input placeholder={"Enter verification code"} type={"text"} className={"form-control"}
                               onChange={(e) => setVerify((prev) => ({...prev, email: e.target.value}))}/>
                    </Modal.Body>
                    <Modal.Footer>
                        <div style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                            <Button variant={"outlined"} onClick={() => setShowVerifyEmail(false)}
                                    style={{marginRight: "10px"}}>Cancel</Button>
                            <Button variant={"contained"} onClick={handlers.confirmEmail}>Submit</Button>
                        </div>
                    </Modal.Footer>
                </Modal>
                <Modal show={showVerifyPhone} onHide={() => setShowVerifyPhone(false)}>
                    <Modal.Header><h3>Verify Phone</h3></Modal.Header>
                    <Modal.Body>
                        <input placeholder={"Enter verification code"} type={"text"} className={"form-control"}
                               onChange={(e) => setVerify((prev) => ({...prev, phone: e.target.value}))}/>
                    </Modal.Body>
                    <Modal.Footer>
                        <div style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                            <Button variant={"outlined"} onClick={() => setShowVerifyPhone(false)}
                                    style={{marginRight: "10px"}}>Cancel</Button>
                            <Button variant={"contained"} onClick={handlers.confirmPhone}>Submit</Button>
                        </div>
                    </Modal.Footer>
                </Modal>
                <ChangePassword lang={lang} language={language} show={showChangePasswordModal} hide={setShowChangePasswordModal} setNotification={setNotification} />
            </div>
            {notification.show && <Alert severity={notification.type} style={{
                zIndex: "1111111111",
                minWidth: "250px",
                position: "fixed",
                bottom: "20px",
                left: "20px"
            }}>{notification.message}</Alert>}
        </>
    )
}

export default Profile