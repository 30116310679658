import "./Aside.css"
import {Link} from "react-router-dom";
import {Button} from "@mui/material";
import axios from "axios";
import {useDispatch} from "react-redux";
import { notificationActions } from "../../../../store/notification";

const Aside = () => {
    const dispatch = useDispatch();
    const logout = () => {
        axios.post('/api/logout')
            .then(() => {
                window.location.href = "/admin-dashboard";
            })
            .catch(() => {
                dispatch(notificationActions.setNotification({show: true, message: "Something went wrong", type: "error"}));
                setTimeout(() => {
                    dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                }, 4000)
            })
    }

    return(
        <div className={"admin-aside-content"}>
            <div className={"admin-aside-logo"}><img src={"/static/icons/logo.png"} alt={"admin-logo"} /></div>
            <div className={"admin-dashboard-navigation"}>
                <ul className={"admin-dashboard-navigation-list"}>
                    <li><Link to={"/admin-dashboard/statistics"}>Statistics</Link></li>
                    <li><Link to={"/admin-dashboard/admins"}>Admins</Link></li>
                    <li><Link to={"/admin-dashboard/users"}>Users</Link></li>
                    <li><Link to={"/admin-dashboard/payments"}>Payments</Link></li>
                    <li><Link to={"/admin-dashboard/delivery"}>Delivery</Link></li>
                    <li><Link to={"/admin-dashboard/flights"}>Flights</Link></li>
                    <li><Link to={"/admin-dashboard/parcels"}>Parcels</Link></li>
                    <li><Link to={"/admin-dashboard/conversation"}>Messages</Link></li>
                </ul>
            </div>
            <Button style={{marginTop: "25px"}} onClick={logout} variant={"outlined"}>Log out</Button>
        </div>
    )
}

export default Aside