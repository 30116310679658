import {Link, NavLink} from "react-router-dom";
import "./Header.css"
import {useState} from "react";
import {Slide} from 'react-awesome-reveal'
import {Button} from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import Login from "./Login";
import SignUp from "./SignUp";
import {useDispatch, useSelector} from "react-redux";
import LoggedInAvatar from "./LoggedInAvatar";
import OutsideClickHandler from "react-outside-click-handler";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {userAction} from "../store/user";
import language from "../store/language";
import Alert from "@mui/material/Alert";
import Navigation from "./Navigation";

const Header = ({isMyPage, isLoggedIn}) => {
    const [showLoginForm, setShowLoginForm] = useState(false);
    const [showSignupForm, setShowSignupForm] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const lang = useSelector((state) => state.user.language);
    const dispatch = useDispatch();
    const [notification, setNotification] = useState({show: false, type: "", message: ""});
    const [showSection, setShowSection] = useState("");
    const [hovered, setHovered] = useState("");

    return(
        <>
            <Slide direction={"down"} className={isMyPage ? "my-page-header-container" : ""}>
                <header className={`header-container ${isMyPage ? "header-on-my-page" : ""}`}>
                    <Link to={"/"}>
                        <img src={"/static/icons/logo.png"} className={"header-logo"} alt={"website-logo"}/>
                    </Link>
                    <div className={"header-content-container"}>
                        <div className={"navbar"}>
                            <Navigation isLoggedIn={isLoggedIn}/>
                            <div className={"select-language-container"}>
                                <div className={"language-selector"}
                                     onClick={() => dispatch(userAction.changeLanguage(lang === "en" ? "geo" : "en"))}>
                                    <img src={"/static/icons/earth.svg"} alt={"language"}/>
                                    <span>{lang === "en" ? lang.toUpperCase() : "ქარ"}</span>
                                </div>
                            </div>
                            {isLoggedIn !== true && <Button style={{marginLeft: "15px"}} className="button-dark-blue" startIcon={<PersonIcon/>}
                                     variant={"contained"} onClick={() => {
                                setShowLoginForm(true)
                            }}>{language[lang].mainPage.header.login}</Button>}
                            {
                                isLoggedIn && <LoggedInAvatar/>
                            }
                        </div>
                    </div>
                </header>
            </Slide>
            {showLoginForm && <Login setShowSignup={setShowSignupForm} setShowLoginForm={setShowLoginForm}/>}
            {showSignupForm && <SignUp setNotification={setNotification} showSignIn={setShowLoginForm} setShowSignup={setShowSignupForm}/>}
            {notification.show && <Alert severity={notification.type} style={{
                zIndex: "1111111111",
                minWidth: "250px",
                position: "fixed",
                bottom: "20px",
                left: "20px"
            }}>{notification.message}</Alert>}
        </>
    )
}

export default Header