import {useEffect, useState} from "react";
import ConversationCard from "./ConversationCard";
import axios from "axios";
import Messages from "../../../components/MyPage/Messages";
import {useSelector} from "react-redux";

const MessagesAdmin = () => {
    const [messages, setMessages] = useState([]);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const lang = useSelector((state) => state.user.language);
    const [active, setActive] = useState({});
    const [selectedChat, setSelectedChat] = useState(null);

    useEffect(() => {
        axios(`/api/conversations?limit=${limit}&page=${page}`)
            .then((res) => {
                setMessages(res.data.data)
            })
            .catch(() => {

            })
    },[]);

    return(
        <div className={"messaging-admin-container"}>
            <div className={"admin-conversations-list"}>
                <h5 style={{paddingLeft: "10px", paddingRight: "10px", marginBottom: "20px"}}>Conversations</h5>
                <div className={"conversation-scroll-view"}>
                    {
                        messages.map((m) => {
                            return(
                                <ConversationCard selectedChat={selectedChat} setSelectedChat={setSelectedChat} setActive={setActive} key={m.id} data={m} />
                            )
                        })
                    }
                </div>
            </div>
            <div className={"admin-messenger-container"}>
                {typeof active.id !== "undefined" && <Messages conversation_id={active.conversation_id} lang={lang}/>}
            </div>
        </div>
    )
}

export default MessagesAdmin