import {useSpringCarousel} from 'react-spring-carousel'
import Flight from "./Flight";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {useEffect, useState} from "react";
import axios from "axios";
import core from "../../core";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import language from "../../store/language";

const Flights = () => {
    const lang = useSelector((state) => state.user.language);
    const windowWidth = window.innerWidth;
    const [slidesToShow, setSlidesToShow] = useState(windowWidth > 1024 ? 3.5 : (windowWidth > 770 ? 2.5 : ((windowWidth < 640 && windowWidth > 580) ? 3 : (windowWidth > 400 ? 2 : 1.5))));
    const [data, setData] = useState([
        {
            id: 'item-1',
            renderItem: <div></div>,
        },
        {
            id: 'item-1',
            renderItem: <div></div>,
        },
        {
            id: 'item-1',
            renderItem: <div></div>,
        },
        {
            id: 'item-1',
            renderItem: <div></div>,
        },
        {
            id: 'item-1',
            renderItem: <div></div>,
        },
        {
            id: 'item-1',
            renderItem: <div></div>,
        }])
    useEffect(() => {
        axios(`/api/flights?page=1&limit=30&keyword=&status=active`)
            .then((res) => {
                if(res.data.data.length < slidesToShow){
                    if(res.data.data.length !== 0){
                        setSlidesToShow(res.data.data.length);
                    }else{
                        setSlidesToShow(1);
                    }
                }
                const flights = res.data.data.map((f) => {
                    return {
                        id: 'item-1',
                        renderItem: <Flight data={{
                            flight_id: f.flight_id,
                            estimate_arrival_date: core.formatDate(f.estimate_arrival_date),
                            arrival_date: core.formatDate(f.arrival_date),
                            country: f.country,
                            status: f.status
                        }} width={windowWidth}/>,
                    }
                })
                if(flights.length !== 0) setData(flights);
            })
            .catch((err) => {

            })
    }, []);

    const {carouselFragment, slideToPrevItem, slideToNextItem} = useSpringCarousel({
        draggingSlideTreshold: 35,
        itemsPerSlide: slidesToShow,
        withLoop: true,
        items: data,
    })

    return (
        <>
            <div className={"flights-slider-container"}>
                <div className={"flights-slider-head"}>
                    <div>
                        <h3 style={{marginBottom: "10px"}}>{language[lang].mainPage.flights.title}</h3>
                        <Link to={"/flights"}>{language[lang].mainPage.flights.viewAll}</Link>
                    </div>
                    <div className={"flights-slider-buttons"}>
                    <button onClick={slideToPrevItem} style={{marginRight: "10px"}}><ArrowForwardIosIcon
                            style={{color: "white", transform: "rotate(180deg)"}}/></button>
                        <button onClick={slideToNextItem} className={"slider-switcher-button"}><ArrowForwardIosIcon
                            style={{color: "white"}}/></button>
                    </div>
                </div>
                {carouselFragment}

            </div>
        </>
    )
}

export default Flights