import "./Auth.css"
import {useEffect, useState} from "react";
import axios from "axios";
import language from "../data/language";
import {useSelector} from "react-redux";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { notificationActions } from "../../../store/notification";

const Auth = () => {
    const dispatch = useDispatch();
    const [signInForm, setSignInForm] = useState({
        user: "", password: ""
    });
    const lang = useSelector((state) => state.user.language);
    const [errors, setErrors] = useState({user: {show: false, message:""},password: {show: false, message:""}});

    useEffect(() => {
        axios.post('/api/logout')
            .then(() => {

            })
            .catch(() => {
                dispatch(notificationActions.setNotification({show: true, message: "Something went wrong", type: "error"}));
                setTimeout(() => {
                    dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                }, 4000)
            })
    }, []);

    const handlers = {
        signIn(e){
            e.preventDefault();
            axios.post(`/api/admin/auth`, signInForm)
                .then(() => {
                    window.location.href = "/admin-dashboard"
                })
                .catch((err) => {
                    const message = err.response.data.message;
                    const error_type = err.response.data.error_type;
                    if(error_type === "notification") {
                        
                    }else{
                        setErrors((prev) => ({...prev, [error_type]: {show: true, message: message}}));
                    }
                })
        }
    }

    return(
        <div className={"auth-content-container"}>
            <div className={"auth-form-box"}>
                <img className={"auth-box-logo"} src={`/static/icons/logo.png`} alt="logo" />
                <h3 className={"sign-in-title"}>Sign In</h3>
                <form onSubmit={handlers.signIn}>
                    <div className={"form-group"} style={{marginTop: "20px"}}>
                        <label
                            className={"form-label"}>Username / email*</label>
                        <input required type={"text"} value={signInForm.user} className={"form-control"}
                               onChange={(e) => {
                                   setSignInForm((prev) => ({
                                       ...prev,
                                       user: e.target.value
                                   }));
                                   setErrors((prev) => ({...prev, user: {show: false, message: ""}}))
                               }}/>
                        {errors.user.show &&
                            <p className={"form-error-message"}>{errors.user.message.en}</p>}
                    </div>
                    <div className={"form-group"} style={{marginTop: "20px"}}>
                        <label
                            className={"form-label"}>Password*</label>
                        <input required type={"password"} value={signInForm.password} className={"form-control"}
                               onChange={(e) => {
                                   setSignInForm((prev) => ({
                                       ...prev,
                                       password: e.target.value
                                   }));
                                   setErrors((prev) => ({...prev, password: {show: false, message: ""}}))
                               }}/>
                        {errors.password.show &&
                            <p className={"form-error-message"}>{errors.password.message.en}</p>}
                    </div>
                    <Button variant={"outlined"} type={"submit"} style={{width: "100%", marginTop: "30px"}}>Log In</Button>
                </form>
            </div>
        </div>
    )
}

export default Auth