import language from "../store/language";
import {useSelector} from "react-redux";
import {useEffect} from "react";
import {Link} from "react-router-dom";

const Faq = ({setIsMyPage, windowSize}) => {
    const lang = useSelector((state) => state.user.language);
    useEffect(() => {
        setIsMyPage(false)
    }, [])
    return(
        <div className={"information-pages-container customs"}>
            {windowSize < 880 && (
                <>
                    <div className="horizontal-scroll-menu">
                        <Link to="/info/services/online-parcels">Online Parcels</Link>
                        <Link to="/info/services/delivery-services">Delivery Service</Link>
                        <Link to="/info/useful-information/how-to-buy">How To Buy</Link>
                        <Link to="/info/useful-information/customs-processes">Customs Procedure</Link>
                        <Link to="/info/useful-information/danger-items">Danger Items</Link>
                        <Link to="/info/useful-information/trustees">Trustees</Link>
                        <Link to="/info/useful-information/faq">FAQ</Link>
                    </div>
                </>
            )}
            <div className={"useful-information-banner"} style={{backgroundImage: `url('/static/icons/banner-gradient.jpg')`}}><h5>{language[lang].usefulInformation.faq.title}</h5></div>
            <p className={"information-page-subtitles"}>{language[lang].usefulInformation.faq.content[0]}</p>
            <ul className={"ul-level-one-numeric"}>
                <li>{language[lang].usefulInformation.faq.content[1]}</li>
                <li>{language[lang].usefulInformation.faq.content[2]}</li>
            </ul>
            <p className={"information-page-subtitles"}>{language[lang].usefulInformation.faq.content[3]}</p>
            <p className={"information-page-text"}>{language[lang].usefulInformation.faq.content[4]}</p>
            <p className={"information-page-subtitles"}>{language[lang].usefulInformation.faq.content[5]}</p>
            <p className={"information-page-text"}>{language[lang].usefulInformation.faq.content[6]}</p>
            <p className={"information-page-subtitles"}>{language[lang].usefulInformation.faq.content[7]}</p>
            <p className={"information-page-text"}>{language[lang].usefulInformation.faq.content[8]}</p>
            <ul className={"ul-level-one-numeric"}>
                <li>{language[lang].usefulInformation.faq.content[9]}</li>
                <li>{language[lang].usefulInformation.faq.content[10]}</li>
                <li>{language[lang].usefulInformation.faq.content[11]}</li>
                <li>{language[lang].usefulInformation.faq.content[12]}</li>
            </ul>
            <p className={"information-page-subtitles"}>{language[lang].usefulInformation.faq.content[13]}</p>
            <p className={"information-page-text"}>{language[lang].usefulInformation.faq.content[14]}</p>
            <ul className={"ul-level-one-numeric"}>
                <li>{language[lang].usefulInformation.faq.content[15]}</li>
                <li>{language[lang].usefulInformation.faq.content[16]}</li>
                <li>{language[lang].usefulInformation.faq.content[17]}</li>
            </ul>
            <p className={"information-page-text"}>{language[lang].usefulInformation.faq.content[18]}</p>
            <p className={"information-page-subtitles"}>{language[lang].usefulInformation.faq.content[19]}</p>
            <p>{language[lang].usefulInformation.faq.content[20]}</p>
            <p className={"information-page-text"}>{language[lang].usefulInformation.faq.content[21]}</p>
            <p className={"information-page-subtitles"}>{language[lang].usefulInformation.faq.content[22]}</p>
            <p className={"information-page-text"}>{language[lang].usefulInformation.faq.content[23]}</p>
            <p className={"information-page-subtitles"}>{language[lang].usefulInformation.faq.content[24]}</p>
            <p className={"information-page-text"}>{language[lang].usefulInformation.faq.content[25]}</p>
            <p className={"information-page-text"}>{language[lang].usefulInformation.faq.content[26]}</p>
            <p className={"information-page-text"}>{language[lang].usefulInformation.faq.content[27]}</p>
            <p className={"information-page-text"}>{language[lang].usefulInformation.faq.content[28]}</p>
        </div>
    )
}

export default Faq