import {useEffect, useState} from "react";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import {Modal, Table} from "react-bootstrap";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import OutsideClickHandler from "react-outside-click-handler";
import {Button, Pagination, Stack} from "@mui/material";
import CreateNewAdmin from "../Components/CreateNewAdmin";
import DialogBox from "../../../components/DialogBox";
import {useSelector, useDispatch} from "react-redux";
import language from "../../../store/language";
import { notificationActions } from "../../../store/notification";

const Admins = () => {
    const dispatch = useDispatch();
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [keyword, setKeyword] = useState("");
    const [position, setPosition] = useState("all");
    const [data, setData] = useState([]);
    const [searchFieldValue, setSearchFieldValue] = useState("");
    const [active, setActive] = useState({});
    const [total, setTotal] = useState(0);
    const [optionsDropdown, setOptionsDropdown] = useState({show: false, id: 0});
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showCreateAdminModal, setShowCreateAdminModal] = useState(false);
    const [action, setAction] = useState("add");
    const lang = useSelector((state) => state.user.language);

    const loadAdmins = () => {
        axios(`/api/admins?limit=${limit}&page=${page}&keyword=${keyword}&position=${position}`)
            .then((res) => {
                const admins = res.data.data;
                setData([...admins]);
                setTotal(res.data.total);
            })
            .catch((err) => {
                dispatch(notificationActions.setNotification({show: true, message: err.response.data.message[lang], type: "error"}));
                setTimeout(() => {
                    dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                }, 4000)
            })
    }

    const handlers = {
        removeAdmin(){
            axios.delete(`/api/admin/admins/${active.id}`)
                .then(() => {
                    loadAdmins();
                    setShowDeleteDialog(false);
                })
                .catch((err) => {
                    dispatch(notificationActions.setNotification({show: true, message: err.response.data.message[lang], type: "error"}));
                    setTimeout(() => {
                        dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                    }, 4000)
                })
        }
    }

    useEffect(loadAdmins, [limit, page, keyword, position]);

    return (
        <>
            <h3 style={{marginBottom: "35px"}}>Admins</h3>
            <div style={{width: "100%"}}>
                <div className={"data-table-header"}>
                    <div className={"search-field-container"}>
                        <input type={"text"}
                               onKeyUp={(e) => {
                                   if (e.keyCode === 13) {
                                       setKeyword(searchFieldValue);
                                   }
                               }}
                               onChange={(e) => setSearchFieldValue(e.target.value)}
                               placeholder={"Enter keyword"}
                               className={"search-field"}/>
                        <button><SearchIcon onClick={() => {
                            setKeyword(searchFieldValue);
                            setAction("add");
                        }} style={{color: "white"}}/>
                        </button>
                    </div>
                    <div style={{display: "flex"}}>
                        <select style={{maxWidth: "130px"}} className={"form-control"} value={position}
                                onChange={(e) => setPosition(e.target.value)}>
                            <option value={""}>Select position</option>
                            <option value={"ოპერატორი"}>ოპერატორი</option>
                            <option value={"ჩინეთის საწყობი"}>ჩინეთის საწყობი</option>
                            <option value={"საწყობის თანამშრომელი"}>საწყობის თანამშრომელი</option>
                            <option value={"დეკლარანტი"}>დეკლარანტი</option>
                            <option value={"ბუღალტერი"}>ბუღალტერი</option>
                            <option value={"კურიერი"}>კურიერი</option>
                        </select>
                        <Button onClick={() => {
                            setShowCreateAdminModal(true);
                            setAction("add");
                        }} variant={"contained"} style={{marginLeft: "20px"}}>დამატება</Button>
                    </div>
                </div>
                <Table responsive style={{width: "100%"}} className={"data-table"}>
                    <thead>
                    <tr>
                        <th>სახელი</th>
                        <th>გვარი</th>
                        <th>შიდა ნომერი</th>
                        <th>პოზიცია</th>
                        <th>ელ.ფოსტა</th>
                        <th>ტელეფონი</th>
                        <th>მომხ. სახელი</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        data.map((p, i) => {
                            return (
                                <tr className={`${i % 2 === 1 ? 'odd-row' : 'even-row'} table-rows`}
                                    key={p.id}>
                                    <td style={{paddingLeft: "5px"}}>{p.name}</td>
                                    <td>{p.surname}</td>
                                    <td>{p.company_number}</td>
                                    <td>{p.position}</td>
                                    <td>{p.email}</td>
                                    <td>{p.phone}</td>
                                    <td>{p.username}</td>
                                    <td>
                                        <div className={"table-actions-section"}>
                                            <button className={"table-actions-button"} onClick={() => {
                                                setOptionsDropdown({show: true, id: p.id});
                                            }}><MoreVertIcon/></button>
                                            {(optionsDropdown.show && optionsDropdown.id === p.id) &&
                                                <div className={"options-dropdown-box"}>
                                                    <OutsideClickHandler onOutsideClick={() => {
                                                        setOptionsDropdown({show: false, id: 0})
                                                    }}>
                                                        <ul className={"table-options-list"}>
                                                            <li onClick={() => {
                                                                setActive({...p});
                                                                setAction("edit");
                                                                setOptionsDropdown({show: false, id: 0});
                                                                setShowCreateAdminModal(true)
                                                            }}>რედაქტირება
                                                            </li>
                                                            <li onClick={() => {
                                                                setShowDeleteDialog(false);
                                                                setActive({...p})
                                                            }}>წაშლა
                                                            </li>
                                                        </ul>
                                                    </OutsideClickHandler>
                                                </div>}

                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                    }
                    {
                        data.length === 0 && (
                            <tr>
                                <td colSpan={9} style={{padding: "12px 0"}}>
                                    <p style={{width: "100%", textAlign: "center"}}>{language[lang].myPage.myParcels.recordsNotFound}</p>
                                </td>
                            </tr>
                        )
                    }
                    </tbody>
                </Table>
                <div className={"pagination-container"}>
                    <p>Total: {total}</p>
                    <Stack spacing={2}>
                        <Pagination count={Math.ceil(total / limit)} size="large" page={page} onChange={(e, v) => setPage(v)}/>
                    </Stack>
                </div>
            </div>
            <CreateNewAdmin action={action} active={active} loadAdmins={loadAdmins} show={showCreateAdminModal}
                            setShow={setShowCreateAdminModal}/>
            <DialogBox open={showDeleteDialog} setOpen={setShowDeleteDialog} agree={handlers.removeAdmin}
                       cancelText={"No"} agreeText={"Yes"} title={"Delete admin"}
                       description={"Do you want to process"}/>
        </>
    )
}

export default Admins