import MoreVertIcon from "@mui/icons-material/MoreVert";
import OutsideClickHandler from "react-outside-click-handler";
import {Button, Pagination, Stack} from "@mui/material";
import {useEffect, useState} from "react";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import DialogBox from "../DialogBox";
import {Modal, Table} from "react-bootstrap";
import {useSelector, useDispatch} from "react-redux";
import { notificationActions } from "../../store/notification";

const Delivery = ({lang, language, setCurrentContentTitle, setPayedSelectedDelivery, setSelectedDelivery, selectedDelivery, payedSelectedDelivery}) => {
    const dispatch = useDispatch();
    const [keyword, setKeyword] = useState("");
    const [optionsDropdown, setOptionsDropdown] = useState({show: false, id: 0});
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const [searchFieldValue, setSearchFieldValue] = useState("");
    const [status, setStatus] = useState("");
    const [active, setActive] = useState({});
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showDeliveryModal, setShowDeliveryModal] = useState(false);
    const defaultAddress =  useSelector((state) => state.user.address);
    const [useDefaultAddress, setUseDefaultAddress] = useState(false);
    const [address, setAddress] = useState("");
    const loadDelivery = () => {
        axios(`/api/delivery?page=${page}&limit=${limit}&keyword=${keyword}&status=${status}`)
            .then((res) => {
                setData(res.data.data);
                setTotal(res.data.total);
            })
            .catch((err) => {
                dispatch(notificationActions.setNotification({show: true, message: err.response.data.message[lang], type: "error"}));
                setTimeout(() => {
                    dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                }, 4000)
            })
    }

    useEffect(loadDelivery, [page, limit, keyword, status]);
    useEffect(() => setCurrentContentTitle("Delivery"), []);

    const handlers = {
        remove(){
            axios.delete(`/api/delivery/${active.id}`)
                .then((res) => {
                    setShowDeleteDialog(false);
                    loadDelivery();
                    dispatch(notificationActions.setNotification({show: true, message: res.data.message[lang], type: "error"}));
                    setTimeout(() => {
                        dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                    }, 4000)
                })
                .catch((err) => {
                    dispatch(notificationActions.setNotification({show: true, message: err.response.data.message[lang], type: "error"}));
                    setTimeout(() => {
                        dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                    }, 4000)
                })
        },
        edit(){
            axios.put(`/api/delivery/${active.id}`, {address: address})
                .then((res) => {
                    setShowDeliveryModal(false);
                    loadDelivery();
                    dispatch(notificationActions.setNotification({show: true, message: res.data.message[lang], type: "error"}));
                    setTimeout(() => {
                        dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                    }, 4000)
                })
                .catch((err) => {
                    dispatch(notificationActions.setNotification({show: true, message: err.response.data.message[lang], type: "error"}));
                    setTimeout(() => {
                        dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                    }, 4000)
                })
        }
    }

    return <>
        <div style={{width: "100%"}}>
            <div className={"data-table-header"}>
                <div className={"search-field-container"}>
                    <input type={"text"}
                           onKeyUp={(e) => {
                               if (e.keyCode === 13) {
                                   setKeyword(searchFieldValue);
                               }
                           }}
                           onChange={(e) => setSearchFieldValue(e.target.value)} placeholder={"Enter Tracking number"}
                           className={"search-field"}/>
                    <button><SearchIcon onClick={() => setKeyword(searchFieldValue)} style={{color: "white"}}/></button>
                </div>
                <select style={{maxWidth: "130px"}} className={"form-control"} value={status}
                        onChange={(e) => setStatus(e.target.value)}>
                    <option value={""}>{language[lang].myPage.myParcels.deliveryStatus[0]}</option>
                    <option value={"Pending"}>{language[lang].myPage.myParcels.deliveryStatus[1]}</option>
                    <option value={"On way"}>{language[lang].myPage.myParcels.deliveryStatus[2]}</option>
                    <option value={"Delivered"}>{language[lang].myPage.myParcels.deliveryStatus[3]}</option>
                </select>
            </div>
            <Table responsive style={{width: "100%"}} className={"data-table"}>
                <thead>
                <tr>
                    <th></th>
                    <th>{language[lang].myPage.myParcels.tracking}</th>
                    <th>{language[lang].myPage.myParcels.address}</th>
                    <th>{language[lang].myPage.myParcels.price}</th>
                    <th>{language[lang].myPage.myParcels.paymentMethod}</th>
                    <th>{language[lang].myPage.myParcels.paymentDate}</th>
                    <th>{language[lang].myPage.myParcels.status}</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {
                    data.map((p, i) => {
                        return (
                            <tr className={`${i % 2 === 1 ? 'odd-row' : 'even-row'} table-rows`}
                                key={p.id}>
                                <td style={{paddingLeft: "5px"}}><input defaultChecked={() => selectedDelivery.includes(p.id) || payedSelectedDelivery.includes(p.id)} type={"checkbox"} onChange={(e) => {
                                    const checked = e.target.checked;
                                    if(checked){
                                        if(p.is_payed == true || p.price == null || p.price == "null"){
                                            setPayedSelectedDelivery((prev) => ([...prev, p.id]));
                                        }else{
                                            setSelectedDelivery((prev) => ([...prev, p.id]))
                                        }
                                    }else{
                                        if(p.is_payed == true || p.price == null || p.price == "null"){
                                            const unselected = payedSelectedDelivery.filter((d) => d != p.id);
                                            setPayedSelectedDelivery((prev) => ([...unselected]));
                                        }else{
                                            const unselected = selectedDelivery.filter((d) => d != p.id);
                                            setSelectedDelivery((prev) => ([...unselected]))
                                        }
                                    }
                                }}/></td>
                                <td title={p.trackings.replace("[", "").replace("]", "").replaceAll('"', "")}>{(p.trackings.replace("[", "").replace("]", "").replaceAll('"', "")).slice(0, 25)}{(p.trackings.replace("[", "").replace("]", "").replaceAll('"', "")).length > 25 ? "..." : ""}</td>
                                <td title={p.address}>{p.address.slice(0, 25)}{p.address.length > 25 ? "..." : ""}</td>
                                <td>{p.price == null ? language[lang].myPage.myParcels.deliveryStatus[1] : `${p.price}₾`}</td>
                                <td>{p.payment_method}</td>
                                <td>{p.payment_date}</td>
                                <td>
                                    {p.status === "Pending" && language[lang].myPage.myParcels.deliveryStatus[1]}
                                    {p.status === "On way" && language[lang].myPage.myParcels.deliveryStatus[2]}
                                    {p.status === "Delivered" && language[lang].myPage.myParcels.deliveryStatus[3]}
                                </td>
                                <td>
                                    <div className={"table-actions-section"}>
                                        <button className={"table-actions-button"} onClick={() => {
                                            setOptionsDropdown({show: true, id: p.id});
                                        }}><MoreVertIcon/></button>
                                        {(optionsDropdown.show && optionsDropdown.id === p.id) &&
                                            <div className={"options-dropdown-box"}>
                                                <OutsideClickHandler onOutsideClick={() => {
                                                    setOptionsDropdown({show: false, id: 0})
                                                }}>
                                                    <ul className={"table-options-list"}>
                                                        <li onClick={() => {
                                                            setShowDeliveryModal(true);
                                                            setOptionsDropdown({show: false, id: 0});
                                                            setActive({...p});
                                                            setAddress(p.address);
                                                        }}>Edit</li>
                                                        {p.status === "Pending" && <li onClick={() => {
                                                            setShowDeleteDialog(true);
                                                            setActive({...p});
                                                            setOptionsDropdown({show: false, id: 0});
                                                        }}>Delete</li>}
                                                    </ul>
                                                </OutsideClickHandler>
                                            </div>}

                                    </div>
                                </td>
                            </tr>
                        )
                    })
                }
                {
                    data.length === 0 && (
                        <tr>
                            <td colSpan={9} style={{padding: "12px 0"}}>
                                <p style={{width: "100%", textAlign: "center"}}>{language[lang].myPage.myParcels.recordsNotFound}</p>
                            </td>
                        </tr>
                    )
                }
                </tbody>
            </Table>
            <div className={"pagination-container"}>
                <p>Total: {total}</p>
                <Stack spacing={2}>
                    <Pagination count={Math.ceil(total / limit)} size="large" page={page} onChange={(e, v) => setPage(v)}/>
                </Stack>
            </div>
        </div>
        <DialogBox agree={handlers.remove} title={"Delete Delivery order"} description={"Do you want to delete?"} agreeText={"Yes"} setOpen={setShowDeleteDialog} open={showDeleteDialog} cancelText={"No"}/>
        <Modal show={showDeliveryModal} onHide={() => setShowDeliveryModal(false)}>
            <Modal.Header><h4>{language[lang].myPage.myParcels.addDelivery}</h4></Modal.Header>
            <Modal.Body>
                <div className={"form-group"} style={{marginTop: "20px"}}>
                    <label className={"form-label"}>{language[lang].myPage.myParcels.address}</label>
                    <input type={"text"} className={"form-control"} disabled={useDefaultAddress}
                           onChange={(e) => setAddress(e.target.value)}
                           placeholder={language[lang].myPage.myParcels.addressPlaceholder} value={address}/>
                </div>
                <div style={{marginTop: "20px", cursor: "pointer"}}>
                    <input type={"checkbox"} id={"use-default-address"} onChange={(e) => {
                        setUseDefaultAddress(e.target.checked)
                        if(e.target.checked) {
                            setAddress(defaultAddress)
                        }else {
                            setAddress("");
                        }
                    }}/>
                    <label style={{marginLeft: "10px"}} htmlFor={"use-default-address"}>{defaultAddress}</label>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handlers.edit} style={{marginRight: "20px"}} variant={"contained"}>{language[lang].myPage.myParcels.save}</Button>
                <Button onClick={() => setShowDeliveryModal(false)} variant={"outlined"}>{language[lang].myPage.myParcels.cancel}</Button>
            </Modal.Footer>
        </Modal>
    </>
}

export default Delivery