import {Modal} from "react-bootstrap";
import {Button} from "@mui/material";
import {useState} from "react";
import axios from "axios";

const ChangePassword = ({lang, language, show, hide, setNotification}) => {
    // setNotification({show: true, type: "success", message: "Verification code sent to your email"});
    // setTimeout(() => setNotification({show: false, type: "", message: ""}), 6000);

    const [passwordForm, setPasswordForm] = useState({password: "", new_password: "", repeat_password: ""});
    const [errors, setErrors] = useState({
        wrong_old_password: false, short_password: false, passwords_match: false
    })

    const changePassword = () => {
        if(passwordForm.new_password.length < 7){
            setErrors((prev) => ({...prev, short_password: true}));
        }else if(passwordForm.new_password !== passwordForm.repeat_password){
            setErrors((prev) => ({...prev, passwords_match: true}))
        }else{
            axios.put(`/api/password/change`, passwordForm)
                .then((res) => {
                    setNotification({show: true, type: "success", message: "Password changed"});
                    setTimeout(() => setNotification({show: false, type: "", message: ""}), 6000);
                    hide(false);
                })
                .catch((err) => {
                    setErrors((prev) => ({...prev, wrong_old_password: true}))
                })
        }
    }

    return(
        <Modal show={show} onHide={hide} >
            <Modal.Header><h4>{language[lang].myPage.myParcels.changePass}</h4></Modal.Header>
            <Modal.Body>
                <form onSubmit={changePassword}>
                    <div className={"form-group"} style={{marginTop: "20px"}}>
                        <label className={"form-label"}>{language[lang].myPage.myParcels.oldPassword}*</label>
                        <input required type={"text"} value={passwordForm.password} className={"form-control"}
                               onChange={(e) => {
                                   setPasswordForm((prev) => ({
                                       ...prev,
                                       password: e.target.value
                                   }));
                                   setErrors((prev) => ({...prev, wrong_old_password: false}))
                               }}
                               placeholder={language[lang].myPage.myParcels.oldPassword}/>
                        {errors.wrong_old_password && <p className={"form-error-message"}>Wrong old password!</p>}
                    </div>
                    <div className={"form-group"} style={{marginTop: "20px"}}>
                        <label className={"form-label"}>{language[lang].myPage.myParcels.newPassword}*</label>
                        <input required type={"text"} value={passwordForm.new_password} className={"form-control"}
                               onChange={(e) => {
                                   setPasswordForm((prev) => ({
                                       ...prev,
                                       new_password: e.target.value
                                   }));
                                   setErrors((prev) => ({...prev, short_password: false}))
                               }}
                               placeholder={language[lang].myPage.myParcels.newPasswordPlaceholder}/>
                        {errors.short_password && <p className={"form-error-message"}>Password is too short!</p>}
                    </div>
                    <div className={"form-group"} style={{marginTop: "20px"}}>
                        <label className={"form-label"}>{language[lang].myPage.myParcels.repeatPassword}*</label>
                        <input required type={"text"} value={passwordForm.repeat_password} className={"form-control"}
                               onChange={(e) => {
                                   setPasswordForm((prev) => ({
                                       ...prev,
                                       repeat_password: e.target.value
                                   }));
                                   setErrors((prev) => ({...prev, passwords_match: false}));
                               }}
                               placeholder={language[lang].myPage.myParcels.repeatPasswordPlaceholder}/>
                        {errors.passwords_match && <p className={"form-error-message"}>Passwords does not match!</p>}
                    </div>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "flex-end", marginTop: "20px"}}>
                        <Button variant={"outlined"} onClick={() => hide(false)}
                                style={{marginRight: "10px"}}>{language[lang].myPage.myParcels.cancel}</Button>
                        <Button variant={"contained"} onClick={changePassword}>{language[lang].myPage.myParcels.save}</Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default ChangePassword