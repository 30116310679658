import MoreVertIcon from "@mui/icons-material/MoreVert";
import OutsideClickHandler from "react-outside-click-handler";
import SearchIcon from "@mui/icons-material/Search";
import {Pagination, Stack} from "@mui/material";
import {useEffect, useState} from "react";
import Declaration from "./Declaration";
import axios from "axios";
import ViewParcel from "./ViewParcel";
import DialogBox from "../DialogBox";
import {Table} from "react-bootstrap";
import {useDispatch} from "react-redux";
import { notificationActions } from "../../store/notification";

const PreliminaryDeclared = ({lang, language, setCurrentContentTitle}) => {
    const dispatch = useDispatch();
    const [keyword, setKeyword] = useState("");
    const [optionsDropdown, setOptionsDropdown] = useState({show: false, id: 0});
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const [searchFieldValue, setSearchFieldValue] = useState("");
    const [activeParcel, setActiveParcel] = useState({});
    const [showViewParcelModal, setShowViewParcelModal] = useState(false);
    const [notification, setNotification] = useState({show: false, type: "", message: ""});
    const [showAddNewModal, setShowAddNewModal] = useState(false);
    const [action, setAction] = useState("");
    const [showDeleteParcelModal, setShowDeleteParcelModal] = useState(false);
    useEffect(() => setCurrentContentTitle("Preliminary Declared"), []);

    const getParcels = () => {
        axios(`/api/parcels?status=predeclared&keyword=${keyword}&page=${page}&limit=${limit}`)
            .then((res) => {
                setData(res.data.data);
                setTotal(res.data.total);
            })
            .catch((err) => {
                dispatch(notificationActions.setNotification({show: true, message: err.response.data.message[lang], type: "error"}));
                setTimeout(() => {
                    dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                }, 4000)
            })
    }

    useEffect(getParcels, [keyword, limit, page]);

    const handlers = {
        addNewParcel(data){
            const formData = new FormData();
            formData.append("price", data.price);
            formData.append("currency", data.currency);
            formData.append("website", data.website);
            formData.append("description", data.description);
            formData.append("tracking", data.tracking);
            formData.append("another_taker", data.another_taker);
            formData.append("file", data.file);
            formData.append("insurance", data.insurance);
            formData.append("trusteer_fullname", data.trusteer_fullname);
            formData.append("trusteer_address", data.trusteer_address);
            formData.append("trusteer_phone", data.trusteer_phone);
            formData.append("trusteer_id_number", data.trusteer_id_number);

            axios.post('/api/parcels/declaration/preliminary', formData)
                .then((res) => {
                    setShowAddNewModal(false);
                    getParcels();
                })
                .catch((err) => {
                    dispatch(notificationActions.setNotification({show: true, message: err.response.data.message[lang], type: "error"}));
                    setTimeout(() => {
                        dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                    }, 4000)
                })
        },
        editDeclaration(data){
            const formData = new FormData();
            formData.append("price", data.price);
            formData.append("currency", data.currency);
            formData.append("website", data.website);
            formData.append("description", data.description);
            formData.append("tracking", data.tracking);
            formData.append("another_taker", data.another_taker);
            formData.append("file", data.file);
            formData.append("type", "predeclaration");
            formData.append("name", data.name);
            formData.append("surname", data.surname);
            formData.append("address", data.address);
            formData.append("phone", data.phone);
            formData.append("insurance", data.insurance);
            formData.append("id_number", data.id_number);

            axios.put(`/api/parcels/${activeParcel.tracking}/pre-declaration`, formData)
                .then((res) => {
                    setShowAddNewModal(false);
                    getParcels();
                })
                .catch((err) => {
                    dispatch(notificationActions.setNotification({show: true, message: err.response.data.message[lang], type: "error"}));
                    setTimeout(() => {
                        dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                    }, 4000)
                })
        },
        removeParcel(){
            axios.delete(`/api/parcels/${activeParcel.tracking}`)
                .then((res) => {
                    setShowDeleteParcelModal(false);
                    getParcels();
                })
                .catch((err) => {
                    dispatch(notificationActions.setNotification({show: true, message: err.response.data.message[lang], type: "error"}));
                    setTimeout(() => {
                        dispatch(notificationActions.setNotification({show: false, message: "", type: ""}))
                    }, 4000)
                })
        }
    }

    return(
        <>
            <div className={"parcels-data-table"}>
                <div className={"data-table-header"}>
                    <div className={"search-field-container"}>
                        <input type={"text"}
                               onKeyUp={(e) => {
                                   if (e.keyCode === 13) {
                                       setKeyword(searchFieldValue);
                                   }
                               }}
                               onChange={(e) => setSearchFieldValue(e.target.value)}
                               placeholder={language[lang].myPage.myParcels.search} className={"search-field"}/>
                        <button><SearchIcon onClick={() => setKeyword(searchFieldValue)} style={{color: "white"}}/>
                        </button>
                    </div>
                    <button className={`delivery-button`} onClick={() => {
                        setShowAddNewModal(true);
                        setAction("add");
                    }}>{language[lang].myPage.myParcels.addNew}</button>
                </div>
                <Table responsive className={"data-table"}>
                    <thead>
                    <tr>
                        <th>{language[lang].myPage.myParcels.tracking}</th>
                        <th>{language[lang].myPage.myParcels.receiver}</th>
                        <th>{language[lang].myPage.myParcels.description}</th>
                        <th>{language[lang].myPage.myParcels.price}</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        data.map((p, i) => {
                            return (
                                <tr className={`${i % 2 === 1 ? 'odd-row' : 'even-row'}`}
                                    key={p.tracking}>
                                    <td>{p.tracking}</td>
                                    <td>{p.receiver}</td>
                                    <td>{p.description}</td>
                                    <td>{p.price}</td>
                                    <td>
                                        <div className={"table-actions-section"}>
                                            <button className={"table-actions-button"} onClick={() => {
                                                setOptionsDropdown({show: true, id: p.tracking});
                                            }}><MoreVertIcon/></button>
                                            {(optionsDropdown.show && optionsDropdown.id === p.tracking) &&
                                                <div className={"options-dropdown-box"}>
                                                    <OutsideClickHandler onOutsideClick={() => {
                                                        setOptionsDropdown({show: false, id: 0})
                                                    }}>
                                                        <ul className={"table-options-list"}>
                                                            <li onClick={() => {
                                                                setActiveParcel({...p});
                                                                setShowViewParcelModal(true);
                                                                setOptionsDropdown({show: false, id: 0})
                                                            }}>{language[lang].myPage.myParcels.view}
                                                            </li>
                                                            <li onClick={() => {
                                                                setAction("edit");
                                                                setActiveParcel({...p});
                                                                setShowAddNewModal(true);
                                                                setOptionsDropdown({show: false, id: 0})
                                                            }}>{language[lang].myPage.myParcels.edit}
                                                            </li>
                                                            <li onClick={() => {
                                                                setActiveParcel({...p});
                                                                setShowDeleteParcelModal(true);
                                                                setOptionsDropdown({show: false, id: 0})
                                                            }}>{language[lang].myPage.myParcels.delete}
                                                            </li>
                                                        </ul>
                                                    </OutsideClickHandler>
                                                </div>}

                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                    }
                    {
                        data.length === 0 && (
                            <tr>
                                <td colSpan={9} style={{padding: "12px 0"}}>
                                    <p style={{width: "100%", textAlign: "center"}}>{language[lang].myPage.myParcels.recordsNotFound}</p>
                                </td>
                            </tr>
                        )
                    }
                    </tbody>
                </Table>
                <div className={"pagination-container"}>
                    <p>{language[lang].flights.total}: {total}</p>
                    <Stack spacing={2}>
                        <Pagination count={Math.ceil(total / limit)} size="large" page={page} onChange={(e, v) => setPage(v)}/>
                    </Stack>
                </div>
            </div>
            <Declaration language={language} lang={lang} data={activeParcel} show={showAddNewModal} hide={setShowAddNewModal} action={action} submit={action === "add" ? handlers.addNewParcel : handlers.editDeclaration}/>
            <ViewParcel role="user" language={language} lang={lang} data={activeParcel} show={showViewParcelModal} setShow={setShowViewParcelModal}/>
            <DialogBox agree={handlers.removeParcel} title={language[lang].myPage.myParcels.deleteParcel} description={language[lang].myPage.myParcels.deleteAccept} agreeText={language[lang].myPage.myParcels.yes} setOpen={setShowDeleteParcelModal} open={showDeleteParcelModal} cancelText={language[lang].myPage.myParcels.no}/>
        </>
    )
}

export default PreliminaryDeclared