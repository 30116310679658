import {useEffect, useState} from "react";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import OutsideClickHandler from "react-outside-click-handler";
import {Pagination, Stack} from "@mui/material";
import ViewParcel from "./ViewParcel";
import language from "../../store/language";
import DescriptionIcon from '@mui/icons-material/Description';
import {Table} from "react-bootstrap";

const Payments = ({lang, language, setCurrentContentTitle}) => {
    const [keyword, setKeyword] = useState("");
    const [optionsDropdown, setOptionsDropdown] = useState({show: false, id: 0});
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const [searchFieldValue, setSearchFieldValue] = useState("");
    const [showViewModal, setShowViewModal] = useState(false);
    const [active, setActive] = useState({});

    useEffect(() => setCurrentContentTitle("Payments"), []);

    useEffect(() => {
        axios(`/api/payments?keyword=${keyword}&page=${page}&limit=${limit}`)
            .then((res) => {
                setData(res.data.data);
                setTotal(res.data.total);
            })
            .catch((err) => {

            })
    }, [keyword, limit, page]);

    return <div className={"my-parcels-container"}>
        <div className={"parcels-data-table"}>
            <div className={"data-table-header"}>
                <div className={"search-field-container"}>
                    <input type={"text"}
                           onKeyUp={(e) => {
                               if (e.keyCode === 13) {
                                   setKeyword(searchFieldValue);
                               }
                           }}
                           value={searchFieldValue}
                           onChange={(e) => setSearchFieldValue(e.target.value)} placeholder={language[lang].myPage.myParcels.search}
                           className={"search-field"}/>
                    <button><SearchIcon onClick={() => setKeyword(searchFieldValue)} style={{color: "white"}}/></button>
                </div>
            </div>
            <Table responsive className={"data-table"}>
                <thead>
                <tr>
                    <th>{language[lang].myPage.myParcels.status}</th>
                    <th>{language[lang].myPage.myParcels.transactionId}</th>
                    <th>{language[lang].myPage.myParcels.paymentDate}</th>
                    <th>{language[lang].myPage.myParcels.amount}</th>
                    <th>{language[lang].myPage.myParcels.paymentMethod}</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {
                    data.map((p, i) => {
                        return (
                            <tr className={`${i % 2 === 1 ? 'odd-row' : 'even-row'} table-rows`}
                                key={p.id}>
                                <td>{p.transaction_status}</td>
                                <td title={p.transaction_id}>{p.transaction_id.slice(0, 15)}</td>
                                <td>{p.transaction_date}</td>
                                <td>{p.amount}</td>
                                <td>{p.payment_method}</td>
                                <td>
                                    <DescriptionIcon title="Download Invoice"/>
                                </td>
                            </tr>
                        )
                    })
                }
                {
                    data.length === 0 && (
                        <tr>
                            <td colSpan={9} style={{padding: "12px 0"}}>
                                <p style={{width: "100%", textAlign: "center"}}>{language[lang].myPage.myParcels.recordsNotFound}</p>
                            </td>
                        </tr>
                    )
                }
                </tbody>
            </Table>
            <div className={"pagination-container"}>
                <p>Total: {total}</p>
                <Stack spacing={2}>
                    <Pagination count={Math.ceil(total / limit)} size="large" page={page} onChange={(e, v) => setPage(v)}/>
                </Stack>
            </div>
        </div>
        <ViewParcel lang={lang} role="user" language={language} data={active} show={showViewModal} setShow={setShowViewModal}/>
    </div>
}

export default Payments