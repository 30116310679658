import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import PersonIcon from '@mui/icons-material/Person';
import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports';
import InfoIcon from '@mui/icons-material/Info';
import CallIcon from '@mui/icons-material/Call';
import HomeIcon from '@mui/icons-material/Home';

function MobileNavigation({language, lang, setCurrentPage, isLoggedIn}) {
    const [value, setValue] = React.useState(0);
    const windowSize = window.innerWidth;

    return (
        <div style={{width: "100%", position: "fixed", bottom: "0", zIndex: "1111"}}>
            <Box sx={{ width: windowSize }}>
                <BottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                        setCurrentPage(newValue)
                        setValue(newValue);
                    }}
                >
                    <BottomNavigationAction value={"/"} label={language[lang].mainPage.header.home} icon={<HomeIcon />} />
                    {isLoggedIn && <BottomNavigationAction value={"/my-page/my-parcels"}
                                             label={language[lang].mainPage.header.myPage === "ჩემი გვერდი" ? "კაბინეტი" : language[lang].mainPage.header.myPage}
                                             icon={<PersonIcon/>}/>}
                    <BottomNavigationAction value={"/flights"} label={language[lang].mainPage.header.flights} icon={<ConnectingAirportsIcon />} />
                    <BottomNavigationAction value={"/info/services/online-parcels"} label={language[lang].mainPage.header.info} icon={<InfoIcon />} />
                    <BottomNavigationAction value={"/contact-us"} label={language[lang].mainPage.header.contactUs} icon={<CallIcon />} />
                </BottomNavigation>
            </Box>
        </div>
    );
}

export default MobileNavigation