import './MainPage.css'
import {Zoom} from "react-awesome-reveal";
import {useSelector} from "react-redux";
import language from "../../store/language";

const Banner = () => {
    const lang = useSelector((state) => state.user.language);

    return (
        <div className={"banner-container"}>
            <Zoom>
                <div className={"banner-info-text"}>
                    <h1 className={"banner-text-header"}>{language[lang].mainPage.banner.title}</h1>
                    <div className={"banner-prices"}>
                        <div className='china-air-price'>{language[lang].mainPage.banner.prices[0]}</div>
                        <div className='china-terrestrial-price'>{language[lang].mainPage.banner.prices[1]}</div>
                    </div>
                </div>
            </Zoom>
            <Zoom>
                <video autoPlay className={"banner-icon"} muted loop>
                    <source src='https://simplepost.ge/static/icons/global_delivery.mp4' type='video/mp4'/>
                </video>
            </Zoom>
        </div>
    )
}

export default Banner