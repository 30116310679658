import language from "../store/language";
import {useSelector} from "react-redux";
import {useEffect} from "react";
import {Link} from "react-router-dom";

const CustomsProcedure = ({setIsMyPage, windowSize}) => {
    const lang = useSelector((state) => state.user.language);
    useEffect(() => {
        setIsMyPage(false)
    }, [])
    return(
        <div className={"information-pages-container customs"}>
            {windowSize < 880 && (
                <>
                    <div className="horizontal-scroll-menu">
                        <Link to="/info/services/online-parcels">Online Parcels</Link>
                        <Link to="/info/services/delivery-services">Delivery Service</Link>
                        <Link to="/info/useful-information/how-to-buy">How To Buy</Link>
                        <Link to="/info/useful-information/customs-processes">Customs Procedure</Link>
                        <Link to="/info/useful-information/danger-items">Danger Items</Link>
                        <Link to="/info/useful-information/trustees">Trustees</Link>
                        <Link to="/info/useful-information/faq">FAQ</Link>
                    </div>
                </>
            )}
            <div className={"useful-information-banner"}
                 style={{backgroundImage: `url('/static/icons/banner-gradient.jpg')`}}>
                <h5>{language[lang].usefulInformation.customsProcedure.title}</h5></div>
            <ul className={"ul-level-one"}>
                <li>{language[lang].usefulInformation.customsProcedure.content[0]}</li>
                <li>{language[lang].usefulInformation.customsProcedure.content[1]}</li>
                <li>{language[lang].usefulInformation.customsProcedure.content[2]}</li>
                <li>{language[lang].usefulInformation.customsProcedure.content[3]}</li>
            </ul>
            <p className={"information-page-text"}>{language[lang].usefulInformation.customsProcedure.content[4]}</p>
            <p className={"information-page-text"}>{language[lang].usefulInformation.customsProcedure.content[5]}</p>
            <p className={"information-page-text"}>{language[lang].usefulInformation.customsProcedure.content[6]}</p>
            <p className={"information-page-text"}>{language[lang].usefulInformation.customsProcedure.content[7]}</p>
            <p className={"information-page-subtitles"}>{language[lang].usefulInformation.customsProcedure.content[8]}</p>
            <ul className={"ul-level-one"}>
                <li>{language[lang].usefulInformation.customsProcedure.content[9]}</li>
                <li>{language[lang].usefulInformation.customsProcedure.content[10]}</li>
            </ul>
            <p className={"information-page-text"}>{language[lang].usefulInformation.customsProcedure.content[11]}</p>
            <p className={"information-page-text"}>{language[lang].usefulInformation.customsProcedure.content[12]}</p>
            <p className={"information-page-text"}>{language[lang].usefulInformation.customsProcedure.content[13]}</p>
            <p className={"information-page-subtitles"}>{language[lang].usefulInformation.customsProcedure.content[14]}</p>
            <ul className={"ul-level-one"}>
                <li>{language[lang].usefulInformation.customsProcedure.content[15]}</li>
                <li>{language[lang].usefulInformation.customsProcedure.content[16]}</li>
            </ul>
            <p className={"information-page-subtitles"}>{language[lang].usefulInformation.customsProcedure.content[17]}</p>
            <ul className={"ul-level-one"}>
                <li>{language[lang].usefulInformation.customsProcedure.content[18]}</li>
            </ul>
            <p className={"information-page-subtitles"}>{language[lang].usefulInformation.customsProcedure.content[19]}</p>
            <ul className={"ul-level-one"}>
                <li>{language[lang].usefulInformation.customsProcedure.content[20]}</li>
                <li>{language[lang].usefulInformation.customsProcedure.content[21]}</li>
            </ul>
            <p className={"information-page-subtitles"}>{language[lang].usefulInformation.customsProcedure.content[22]}</p>
            <p className={"information-page-text"}>{language[lang].usefulInformation.customsProcedure.content[23]}</p>
            <p className={"information-page-subtitles"}>{language[lang].usefulInformation.customsProcedure.content[24]}</p>
            <ul className={"ul-level-one"}>
                <li>{language[lang].usefulInformation.customsProcedure.content[25]}</li>
                <li>{language[lang].usefulInformation.customsProcedure.content[26]}</li>
            </ul>
            <p className={"information-page-subtitles"}>{language[lang].usefulInformation.customsProcedure.content[27]}</p>
            <p className={"information-page-text"}>{language[lang].usefulInformation.customsProcedure.content[28]}</p>
            <p className={"information-page-subtitles"}>{language[lang].usefulInformation.customsProcedure.content[29]}</p>
            <p className={"information-page-text"}>{language[lang].usefulInformation.customsProcedure.content[30]}</p>
        </div>
    )
}

export default CustomsProcedure